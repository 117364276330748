import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardTitle, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import moment from 'moment';
import remove from 'lodash/remove';
import clone from 'lodash/clone';
import filter from 'lodash/filter';
import IntlMessages from '../../helpers/IntlMessages';
import Pagination from '../Pagination';

import AccountItem from './item';
import AccountDetail from './detail';
import ExportAccounts from '../ExportAccounts';
import AccountsLoading from './loading';
import { accountsStatus } from '../../constants/accounts';

import { setAccountsStatus } from '../../redux/accounts/actions';

export default function ListCards(props) {
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [detail, setDetail] = useState(null);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [exportModal, setExportModal] = useState(false);

  const { accounts, loadingAccounts, accountStatus } = useSelector(state => state.accounts);
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    let filteringAccounts = filter(accounts, function (o) {
      if (searchText !== "") {
        if ((o.amount / 100).toString().includes(searchText.replace(',', '.'))) return true;
        if (moment(o.activatesAt).format('DD/MM/YYYY').includes(searchText)) return true;
        if (moment(o.cancelsAt).format('DD/MM/YYYY').includes(searchText)) return true;
        if (o.id.toUpperCase() === searchText.toUpperCase()) return true;

        let foundInCustom = false;
        Object.keys(o.custom_fields || {}).forEach((key) => {
          try {
            if (o.custom_fields[key] !== null) {
              if (o.custom_fields[key].toString().toUpperCase().includes(searchText.toUpperCase())) foundInCustom = true;
              if (key.toString().toUpperCase().includes(searchText.toUpperCase())) foundInCustom = true;
            }
          } catch (err) {
            console.error([err, o]);
          }
        });
        return foundInCustom;
      }

      return true;
    });

    setFilteredAccounts(filteringAccounts);
  }, [accounts, searchText, accountStatus]);

  // Status Filter
  const toggleStatus = useCallback((moveStatus, setToFalse) => {
    let newStatus = clone(accountStatus);

    if (accountStatus.includes(moveStatus) || setToFalse) {
      newStatus = remove(accountStatus, (o) => {
        return o !== moveStatus;
      });
    } else {
      newStatus.push(moveStatus);
    }

    if (newStatus.length === 0) return;
    console.log(newStatus);

    dispatch(setAccountsStatus(newStatus));
  }, [accountStatus, dispatch]);

  useEffect(() => {
    if (user.organizationId === "6hRbOhRaA7QmLRFYnhUX"
      || user.organizationId === "lp01evAVzpzFMkmeYDbz"
    ) {
      toggleStatus('OK', true);
    }
  }, [user]);


  // Pagination
  const onChangePage = (currentPage) => {
    window.scrollTo({ top: 50, behavior: 'smooth' });
    setCurrentPage(currentPage);
  };

  // Search
  const changeSearch = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  }

  // Details
  const openDetails = (account) => {
    setDetail(account.id);
  }

  const closeDetails = () => {
    setDetail(null);
  }

  // Export
  const openExport = () => {
    setExportModal(true);
  }

  const closeExport = () => {
    setExportModal(false);
  }

  // Render
  return (
    <Card className="mt-4">
      <CardTitle className="p-4 mb-2">
        <div className="float-left float-none-xs">
          <div className="d-inline-block">
            <h5 className="d-inline">
              <IntlMessages id="list-cards.title" />
            </h5>
            <span className="text-muted text-small d-block">
              <IntlMessages id="list-cards.subtitle" />
            </span>
          </div>
        </div>
        <div className="export-button btn-group float-right float-none-xs mt-2 ml-2">
          <Button size="xs" outline color="primary" onClick={openExport}>
            <i className="iconsminds-download-1"></i>
          </Button>

          {
            exportModal &&
            <ExportAccounts closeModal={closeExport} />
          }
        </div>
        <div className="status-filter-selection btn-group float-right float-none-xs mt-2 ml-2">
          <UncontrolledDropdown>
            <DropdownToggle caret color="primary" className="btn-xs" outline>
              <IntlMessages id="list-cards.status-filter" />
            </DropdownToggle>
            <DropdownMenu right>
              {Object.keys(accountsStatus).map((status) => {
                return (
                  <DropdownItem
                    key={`status-${status}`}
                    onClick={() => {
                      toggleStatus(status);
                    }}
                  >
                    {accountStatus.includes(status) ? (
                      <i className="simple-icon-check" />
                    ) : (
                      <div className="empty-check" />
                    )}
                    <IntlMessages id={`account.status.${status}`} />
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="search-sm float-right mt-2">
          <input type="text" name="keyword" id="search" value={searchText} onChange={changeSearch} placeholder={'Buscar...'} />
        </div>
      </CardTitle>
      <CardBody className="pl-4 pr-4 pb-5 pt-0">
        {loadingAccounts ? (
          <div>
            <AccountsLoading />
            <AccountsLoading />
            <AccountsLoading />
            <AccountsLoading />
            <AccountsLoading />
          </div>
        ) : accounts.length === 0 ? (
          <div className="list-cards-empty">
            <i className="simple-icon-magnifier" />
            <IntlMessages id="list-cards.no-cards" />
          </div>
        ) : (
          <div>
            {
              filteredAccounts
                .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                .map((account) => {
                  return (
                    <AccountItem account={account} openDetails={openDetails} key={`account-${account.id}`} />
                  );
                })
            }

            <Pagination
              currentPage={currentPage}
              totalPage={
                Math.ceil(filteredAccounts.length / itemsPerPage)
              }
              onChangePage={onChangePage}
            />
          </div>
        )}
      </CardBody>

      {
        detail !== null &&
        <AccountDetail accountId={detail} closeDetails={closeDetails} />
      }
    </Card>
  );
}
