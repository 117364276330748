import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

class LoadingReset extends Component {
  render() {
    return <div className="loading p-relative" />;
  }
}

export default injectIntl(LoadingReset);
