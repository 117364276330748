import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';

import { injectIntl } from 'react-intl';

import NewCardForm from './form';
import NewCardInfo from './info';

import { connect } from 'react-redux';

class NewCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      card: {},
      status: 'new',
    };
  }

  // Actions
  onCardCreated = (card) => {
    this.setState({
      card,
      status: 'created',
    });
  };

  onCardNew = () => {
    this.setState({
      card: {},
      status: 'new',
    });
  };

  // Render
  render() {
    const { card, status } = this.state;
    const { className } = this.props;

    return (
      <Card className={className}>
        <CardBody>
          {status === 'new' && (
            <NewCardForm onCardCreated={this.onCardCreated} />
          )}
          {status === 'created' && (
            <NewCardInfo card={card} onCardNew={this.onCardNew} />
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { user } = auth;
  const { environment } = settings;
  return { user, environment };
};

export default connect(mapStateToProps)(injectIntl(NewCard));
