import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Card, Alert, Button, Label, Input } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';

import appFunctions from '../../services/Functions';

class ApiKeyModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      loading: true,
      apiKeys: [],
      newApiKey: null,
    };
  }

  componentDidMount() {
    this.loadApiKeys();
  }

  // Get API Key Info
  loadApiKeys = async () => {
    const { messages } = this.props.intl;

    try {
      const apiKeys = await appFunctions.listApiKeys();

      this.setState({
        loading: false,
        apiKeys: apiKeys.data
      })
    } catch (err) {
      this.setState({
        error: `${messages['user.GeneralException']} ${err.message}`,
      });
    }
  }

  // Create API Key
  onCreateApiKey = async ({ name, environment }, setSubmitting, resetForm) => {
    const { messages } = this.props.intl;

    try {
      const response = await appFunctions.createApiKey({ name, environment });

      this.setState({ newApiKey: response });

      this.loadApiKeys();
      setSubmitting(false);
      resetForm();
    } catch (err) {
      if (err.response?.data?.message) {
        this.setState({
          error: `${err.response?.data?.message}: ${err.response?.data?.params ? err.response?.data?.params : ''}`,
        });
      } else {
        this.setState({
          error: `${messages['user.GeneralException']}`,
        });
      }
    }
  }

  // Delete API Key
  onDeleteApiKey = async (apikeyId) => {
    const { messages } = this.props.intl;

    try {
      await appFunctions.deleteApiKey(apikeyId);

      this.loadApiKeys();
    } catch (err) {
      this.setState({
        error: `${messages['user.GeneralException']}`,
      });
    }
  }

  // Render
  render() {
    const { messages } = this.props.intl;
    const { close, user } = this.props;
    const { apiKeys, error, loading, newApiKey } = this.state;

    return (
      <Modal isOpen={true} size="lg" toggle={close}>
        <ModalHeader toggle={close} />
        <ModalBody>
        <h3><IntlMessages id="apikey.list" /></h3>
          {
            error &&
            <Alert color="danger" className="mt-2 mb-2">
              {error}
            </Alert>
          }

          {
            loading &&
            <div className="loading" />
          }

          {
            apiKeys.map((apiKeyItem) => {
              return (
                <Card key={`user-${apiKeyItem.uid}`} className="mb-3">
                  <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                    <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                      <p className="list-item-heading w-30 mb-1 truncate">{apiKeyItem.name}</p>
                      <p className="mb-1 text-muted text-small w-40 w-sm-100">{apiKeyItem.client_id}</p>
                      <p className="mb-1 text-muted text-small w-15 w-sm-100">{apiKeyItem.environment}</p>
                      {
                        user.uid !== apiKeyItem.uid &&
                        <div className="w-5 w-sm-100">
                          <Button onClick={() => { this.onDeleteApiKey(apiKeyItem.uid) }} color="danger" size="xs">Revogar</Button>
                        </div>
                      }
                    </div>
                  </div>
                </Card>
              )
            })
          }
        </ModalBody>
        <ModalBody>
          <Formik
            initialValues={{ name: '', environment: '' }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(messages['forms.validation.name.required']),
              environment: Yup.string().required(messages['forms.validation.environment.required']),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              this.onCreateApiKey(values, setSubmitting, resetForm)
            }} >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <h3><IntlMessages id="apikey.create" /></h3>

                <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                  <div className="mb-1 mr-1 w-40">
                    <Label className="form-group has-top-label mb-4">
                      <Field type="text" name="name" className="form-control" />
                      <IntlMessages id="apikey.name" />
                      <ErrorMessage name="name" component="div" className="invalid-feedback d-block" />
                    </Label>
                  </div>

                  <div className="mb-1 mr-1 w-30">
                    <Label className="form-group has-top-label mb-4">
                      <Input type="select" value={values.environment} name="environment" onChange={(e) => { setFieldValue('environment', e.target.value); }}>
                        <option value=""></option>
                        <option value="test">Teste</option>
                        <option value="live">Live</option>
                      </Input>
                      <IntlMessages id="apikey.environment" />
                      <ErrorMessage name="environment" component="div" className="invalid-feedback d-block" />
                    </Label>
                  </div>
                </div>

                <div className="d-flex justify-content-end align-items-center">
                  <Button type="submit" color="primary" outline size="lg" disabled={isSubmitting}>
                    <IntlMessages id="forms.action.create" />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>

        {
          newApiKey !== null &&
          <Alert color="success" className="mt-2 mb-2">
            <p>Nova API criada com sucesso. Guarde essas informações de forma segura, pois não serão mais exibidas:</p>
            <p><strong>Client ID:</strong> { newApiKey.client_id }</p>
            <p><strong>Client Secret:</strong> { newApiKey.client_secret }</p>
          </Alert>
        }
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { user } = auth;
  const { environment } = settings;
  return { user, environment };
};

export default connect(mapStateToProps)(injectIntl(ApiKeyModal));
