import { combineReducers } from 'redux';
import auth from './auth/reducer';
import settings from './settings/reducer';
import accounts from './accounts/reducer';

const reducers = combineReducers({
  auth,
  settings,
  accounts,
});

export default reducers;
