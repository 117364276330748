import axios from 'axios';

import firebaseApp from './Firebase';
import User from './User';

const appFunctions = {};

/**
 * Constroi uma instância do axios
 *
 * @return {axios} Instância do axios com configurações para chamar a SWAP
 * @throws Failed to build axios for SWAP
 */
const buildAxios = async (environment) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Frame-Options': 'deny',
      'X-Content-Type-Options': 'nosniff',
    };

    if (firebaseApp.isUserLoggedIn()) {
      const authToken = await User.getToken();
      headers['Authorization'] = `Bearer ${authToken}`;
    }

    if (environment) {
      headers['x-environment'] = environment;
    }

    return axios.create({
      baseURL: 'https://api.banking.portao3.com.br',
      headers,
    });
  } catch (err) {
    throw new Error('Failed to build axios for B3');
  }
};

/**
 * Methods: User
 */

appFunctions.activateAccount = async function ({ code, password }) {
  try {
    const axiosB3 = await buildAxios();

    const response = await axiosB3.post(`/activate-account`, {
      code,
      password,
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Retorna o usuário atual
 *
 * @returns {object} Informações do usuário atual
 */
appFunctions.getUser = async function () {
  try {
    const axiosB3 = await buildAxios();

    const response = await axiosB3.get(`/user`);

    return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Retorna os usuários da organização
 *
 * @returns {object} Informações do usuário atual
 */
appFunctions.listUsers = async function () {
  try {
    const axiosB3 = await buildAxios();

    const response = await axiosB3.get(`/users`);

    return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Cria um usuário
 *
 * @param {CreateUserObject|String} name Nome do usuário
 * @param {CreateUserObject|String} email Email do usuário
 * @param {CreateUserObject|String} cpf CPF do usuário
 * @returns {object} Informações do usuário atual
 */
appFunctions.createUser = async function ({ name, email, cpf }) {
  try {
    const axiosB3 = await buildAxios();

    const response = await axiosB3.post(`/users`, { name, email, cpf });

    return response.data;
  } catch (err) {
    console.error(err);

    throw new Error(err);
  }
};

/**
 * Deleta um usuário
 *
 * @param {string} userId ID do usuário
 * @returns {object} Informações do usuário atual
 */
appFunctions.deleteUser = async function (userId) {
  try {
    const axiosB3 = await buildAxios();

    const response = await axiosB3.delete(`/users/${userId}`);

    return response.data;
  } catch (err) {
    console.error(err);

    throw new Error(err);
  }
};

/**
 * Atualiza as permissões de um usuário
 *
 * @param {string} userId ID do usuário
 * @param {object} permissions Permissões
 * @returns {object} Informações do usuário atual
 */
appFunctions.updateUserPermissions = async function (userId, permissions) {
  try {
    const axiosB3 = await buildAxios();

    const response = await axiosB3.put(`/users/${userId}/permissions`, permissions);

    return response.data;
  } catch (err) {
    console.error(err);

    throw new Error(err);
  }
};

/**
 * Methods: Organizations
 */

/**
 * Retorna a organização atual
 *
 * @returns {object} Informações da organização atual
 */
appFunctions.getOrganization = async function () {
  try {
    const axiosB3 = await buildAxios();

    const response = await axiosB3.get(`/organization`);

    return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Busca o saldo atual da empresa
 *
 * @param {string} environment Ambiente que será criado o cartão
 * @returns {object} Saldo disponível
 */
appFunctions.getBalance = async function ({ environment }) {
  try {
    const axiosB3 = await buildAxios(environment);

    const response = await axiosB3.get(`/organization/balance`);

    return response.data;
  } catch (err) {
    return false;
  }
};

/**
 * Methods: Cards
 */

/**
 * Cria um novo cartão
 *
 * @param {string} environment Ambiente que será criado o cartão
 * @param {integer} amount Valor do cartão
 * @param {string|YYYY-MM-DD} activatesAt Data de ativação do cartão
 * @param {string|YYYY-MM-DD} cancelsAt Data de expiração do cartão
 * @returns {object} Informações do novo cartão criado
 */
appFunctions.createCard = async function ({
  environment,
  amount,
  type,
  activatesAt,
  cancelsAt,
  custom_fields,
}) {
  try {
    const axiosB3 = await buildAxios(environment);

    const response = await axiosB3.post(`/cards`, {
      currency: '986',
      maxPercentageApproval: 1,
      minPercentageApproval: 0,
      amount,
      type,
      activatesAt,
      cancelsAt,
      custom_fields,
    });

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

/**
 * Retorna um cartão
 *
 * @param {string} environment Ambiente que será criado o cartão
 * @param {strinf} accountId ID da account
 * @returns {object} Informações do cartão
 */
appFunctions.retrieveCard = async function ({ environment, accountId }) {
  try {
    const axiosB3 = await buildAxios(environment);

    const response = await axiosB3.get(`/cards/${accountId}`);

    return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Edita um cartão
 *
 * @param {string} environment Ambiente que será criado o cartão
 * @param {strinf} accountId ID da account
 * @param {integer} amount Valor do cartão
 * @param {string|YYYY-MM-DD} activatesAt Data de ativação do cartão
 * @param {string|YYYY-MM-DD} cancelsAt Data de expiração do cartão
 * @returns {object} Informações do cartão editado
 */
appFunctions.editCard = async function ({
  environment,
  accountId,
  amount,
  type,
  activatesAt,
  cancelsAt,
  custom_fields,
}) {
  try {
    const axiosB3 = await buildAxios(environment);

    const response = await axiosB3.put(`/cards/${accountId}`, {
      currency: '986',
      maxPercentageApproval: 1,
      minPercentageApproval: 0,
      amount,
      type,
      activatesAt,
      cancelsAt,
      custom_fields,
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Adiciona saldo no cartão
 *
 * @param {string} environment Ambiente que será criado o cartão
 * @param {strinf} accountId ID da account
 * @param {integer} amount Valor do cartão
 * @param {string|YYYY-MM-DD} activatesAt Data de ativação do cartão
 * @param {string|YYYY-MM-DD} cancelsAt Data de expiração do cartão
 * @returns {object} Informações do cartão editado
 */
appFunctions.addFunds = async function ({ environment, accountId, amount }) {
  try {
    const axiosB3 = await buildAxios(environment);

    const response = await axiosB3.post(`/cards/${accountId}/balance`, {
      amount: parseInt(amount),
      reactivate: true,
    });

    return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Deleta um cartão
 *
 * @param {string} environment Ambiente que será criado o cartão
 * @param {strinf} accountId ID da account
 * @returns {boolean} Informações sobre o sucesso da transação
 */
appFunctions.deleteCard = async function ({ environment, accountId }) {
  try {
    const axiosB3 = await buildAxios(environment);

    await axiosB3.delete(`/cards/${accountId}`);

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Methods: Notifications
 */

/**
 * Marca todas as notificações como lidas
 *
 * @returns {boolean} Caso a solicitação foi realizada com sucesso.
 */
appFunctions.readNotifications = async function () {
  try {
    const axiosB3 = await buildAxios();

    await axiosB3.put(`/notifications/read`);

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Methods: Api Keys
 */

/**
 * Retorna as API Keys da organização
 *
 * @returns {object} Informações do usuário atual
 */
appFunctions.listApiKeys = async function () {
  try {
    const axiosB3 = await buildAxios();

    const response = await axiosB3.get(`/apikeys`);

    return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Cria uma API Key
 *
 * @param {CreateUserObject|String} name Nome do usuário
 * @param {CreateUserObject|String} environment Ambiente
 * @returns {object} Informações do usuário atual
 */
appFunctions.createApiKey = async function ({ name, environment }) {
  try {
    const axiosB3 = await buildAxios();

    const response = await axiosB3.post(`/apikeys`, { name, environment });

    return response.data;
  } catch (err) {
    throw err;
  }
};

/**
 * Deleta uma API Key
 *
 * @param {string} apikeyId ID do usuário
 * @returns {object} Informações do usuário atual
 */
appFunctions.deleteApiKey = async function (apikeyId) {
  try {
    const axiosB3 = await buildAxios();

    const response = await axiosB3.delete(`/apikeys/${apikeyId}`);

    return response.data;
  } catch (err) {
    console.error(err);

    throw new Error(err);
  }
};

/**
 * Methods: Transactions
 */

/**
 * Retorna as transações da Organização
 *
 * @returns {object} Informações de filtro de transações
 */
appFunctions.listOrganizationTransactions = async function ({
  environment,
  startDate,
  endDate,
  response_codes,
}) {
  const axiosB3 = await buildAxios(environment);

  const response = await axiosB3.get(`/statement`, {
    params: {
      startDate,
      endDate,
      response_codes: response_codes.join(','),
      page_size: 999999999,
      page: 1,
    },
  });

  return response.data;
};

/**
 * Retorna PDF das transações da Organização
 *
 * @returns {object} Informações de filtro de transações
 */
appFunctions.printOrganizationTransactions = async function ({
  environment,
  startDate,
  endDate,
  response_codes,
}) {
  const axiosB3 = await buildAxios(environment);

  const response = await axiosB3.get(`/statement/print`, {
    params: {
      startDate,
      endDate,
      response_codes: response_codes.join(','),
      page_size: 999999999,
      page: 1,
    },
    responseType: 'arraybuffer',
  });

  return response.data;
};

/**
 * Retorna OFX das transações da Organização
 *
 * @returns {object} Informações de filtro de transações
 */
appFunctions.ofxOrganizationTransactions = async function ({
  environment,
  startDate,
  endDate,
  response_codes,
}) {
  const axiosB3 = await buildAxios(environment);

  const response = await axiosB3.get(`/statement/ofx`, {
    params: {
      startDate,
      endDate,
      response_codes: response_codes.join(','),
      page_size: 999999999,
      page: 1,
    },
    responseType: 'arraybuffer',
  });

  return response.data;
};

export default appFunctions;
