import React, { Component } from 'react';
import { Badge, Button } from 'reactstrap';
import moment from 'moment';

import IntlMessages from '../../helpers/IntlMessages';

import { accountsStatus } from '../../constants/accounts';
import { stringToMoney } from '../../utils/money';

export default class AccountItem extends Component {
  // Render
  render() {
    const { account, openDetails } = this.props;

    return (
      <div className="account-item">
        <Badge
          color={
            account.status === accountsStatus.OK ||
            account.status === accountsStatus.NEW
              ? 'primary'
              : account.status === accountsStatus.ACTIVE
              ? 'primary'
              : account.status === accountsStatus.INSUFFICIENT_FUNDS
              ? 'danger'
              : account.status === accountsStatus.USED ||
                account.status === accountsStatus.CANCELLED ||
                account.status === accountsStatus.UNUSED
              ? 'success'
              : 'primary'
          }
          pill
          className="position-absolute badge-top-left"
        >
          <IntlMessages id={`account.status.${account.status}`} />
        </Badge>
        <div className="account-item-content">
          <div className="account-item-desc amount">
            <IntlMessages id="account.amount" />
            {stringToMoney(account.amount / 100, 2)}
          </div>

          <div className="account-item-desc">
            <IntlMessages id="account.valid-dates" />
            {moment(account.activatesAt, 'YYYY-MM-DD').format(
              'DD/MM/YY'
            )} - {moment(account.cancelsAt, 'YYYY-MM-DD').format('DD/MM/YY')}
            {(account.status === accountsStatus.NEW ||
              account.status === accountsStatus.OK) && (
              <small>Este cartão ficará ativo na data programada.</small>
            )}
            {account.status === accountsStatus.INSUFFICIENT_FUNDS && (
              <small>Não há fundos disponíveis para ativar esse cartão.</small>
            )}
          </div>

          {Object.keys(account.custom_fields || {}).length > 0 ? (
            <table className="account-item-custom">
              <thead>
                <tr>
                  <th>Chave</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(account.custom_fields).map((chave, index) => {
                  return (
                    <tr key={`account-${account.id}-fields-${index}`}>
                      <td>{chave}</td>
                      <td>{account.custom_fields[chave]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="space-filler-table" />
          )}

          <Button color="empty" onClick={() => { openDetails(account) }}>
            <i className="simple-icon-options-vertical" />
          </Button>
        </div>
      </div>
    );
  }
}
