import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Card } from 'reactstrap';
import { NavLink, Redirect } from 'react-router-dom';
import { Colxx } from '../../components/common/CustomBootstrap';
import qs from 'query-string';

import User from '../../services/User';

import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';

import LoadingReset from '../../components/auth/LoadingReset';
import ErrorReset from '../../components/auth/ErrorReset';
import CodeReset from '../../components/auth/CodeReset';
import CodeNew from '../../components/auth/CodeNew';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      operation: '',
      step: 'loading',
      email: '',
    };
  }

  componentDidMount() {
    this.checkCode();
  }

  // Redirect after login
  componentDidUpdate() {
    if (this.props.user?.uid) {
      this.setState({ redirect: '/' });
    }
  }

  // Handle Steps
  checkCode = async () => {
    try {
      const params = qs.parse(this.props.location.search);
      const code = await User.checkResetCode(params?.oobCode);

      this.setState({
        step: code.operation === 'VERIFY_EMAIL' ? 'new' : 'reset',
        email: code.data.email,
        code: params?.oobCode,
      });
    } catch (err) {
      const { messages } = this.props.intl;

      if (
        err.code === 'auth/expired-action-code' ||
        err.code === 'auth/invalid-action-code'
      ) {
        this.setState({
          step: 'error_reset',
          error: messages['user.ExpiredCodeException'],
        });
      } else if (err.code === 'auth/user-disabled') {
        this.setState({
          step: 'error_reset',
          error: messages['user.UserDisabledException'],
        });
      } else if (err.code === 'auth/user-not-found') {
        this.setState({
          step: 'error_reset',
          error: messages['user.UserNotFoundException'],
        });
      } else {
        // Generic error
        this.setState({
          step: 'error_reset',
          error: `${messages['user.GeneralException']} ${err.message}`,
        });
      }
    }
  };

  changeStep = (step, email) => {
    this.setState({
      email,
      step,
    });
  };

  resetSuccess = async (pwd) => {
    await User.login(this.state.email, pwd);
  };

  // Render
  render() {
    const { redirect, code, step, error } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side ">
              <NavLink to={`/`} className="black">
                <span className="logo-single" />
              </NavLink>

              <h2 className="text-white">
                <IntlMessages id="user.simple" />.<br />
                <IntlMessages id="user.inteligent" />.<br />
                <IntlMessages id="user.direct" />.
              </h2>
            </div>
            <div className="form-side">
              {step === 'loading' && <LoadingReset />}
              {step === 'error_reset' && <ErrorReset error={error} />}
              {step === 'reset' && (
                <CodeReset
                  changeStep={this.changeStep}
                  resetSuccess={this.resetSuccess}
                  code={code}
                />
              )}
              {step === 'new' && (
                <CodeNew
                  changeStep={this.changeStep}
                  resetSuccess={this.resetSuccess}
                  code={code}
                />
              )}
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

export default connect(mapStateToProps)(injectIntl(ForgotPassword));
