import { SET_AUTH, SET_USER, SET_ORGANIZATION, LOGOUT_USER } from "../actions";

export const setAuth = (auth) => ({
    type: SET_AUTH,
    payload: auth,
});

export const setUser = (user) => ({
    type: SET_USER,
    payload: user,
});

export const setOrganization = (organization) => ({
    type: SET_ORGANIZATION,
    payload: organization,
});

export const logoutUser = () => ({
    type: LOGOUT_USER,
});
