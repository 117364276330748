import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Label, Button, Alert, CardTitle } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { NavLink } from 'react-router-dom';

import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';

import User from '../../services/User';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  // Redirect after login
  componentDidUpdate() {
    if (this.props.user?.uid) {
      this.props.loginUser();
    }
  }

  // Cognito Handlers
  onUserLogin = async (values, setSubmitting) => {
    const { email, password } = values;

    try {
      await User.login(email, password);
    } catch (err) {
      const { messages } = this.props.intl;

      setSubmitting(false);

      if (err.code === 'auth/wrong-password') {
        this.setState({
          error: messages['user.NotAuthorizedException'],
        });
      } else if (err.code === 'auth/user-not-found') {
        this.setState({
          error: messages['user.UserNotFoundException'],
        });
      } else if (err.code === 'auth/user-disabled') {
        this.setState({
          error: messages['user.UserDisabledException'],
        });
      } else {
        this.setState({
          error: `${messages['user.GeneralException']} ${err.message}`,
        });
      }
    }
  };

  // Render
  render() {
    const { messages } = this.props.intl;
    const { error } = this.state;

    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(messages['forms.validation.email.valid'])
            .required(messages['forms.validation.email.required']),
          password: Yup.string().required(
            messages['forms.validation.password.required']
          ),
        })}
        onSubmit={(values, { setSubmitting }, e) => {
          this.onUserLogin(values, setSubmitting);
        }}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off" method="post">
            <CardTitle className="mb-4">
              <IntlMessages id="user.login" />
            </CardTitle>

            {error && (
              <Alert color="danger" className="mt-2 mb-2">
                {error}
              </Alert>
            )}

            <Label className="form-group has-top-label mb-4">
              <Field
                type="email"
                name="email"
                className="form-control"
                autoComplete="username"
              />
              <IntlMessages id="user.username" />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback d-block"
              />
            </Label>
            <Label className="form-group has-top-label mb-4">
              <Field
                type="password"
                name="password"
                className="form-control"
                autoComplete="off"
              />
              <IntlMessages id="user.password" />
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback d-block"
              />
            </Label>
            <div className="d-flex justify-content-between align-items-center">
              <NavLink to={`/user/forgot-password`}>
                <IntlMessages id="user.forgot-password-question" />
              </NavLink>
              <Button
                type="submit"
                color="primary"
                outline
                size="lg"
                disabled={isSubmitting}
              >
                <IntlMessages id="forms.action.login" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

export default connect(mapStateToProps)(injectIntl(LoginForm));
