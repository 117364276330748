import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import { changeEnvironment } from '../../redux/settings/actions';

import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';

class EnvironmentSwitch extends Component {
  render() {
    const { environment, fixed_environment } = this.props;

    return (
      <div className="env-content">
        <div
          className={classnames({
            'env-name': true,
            active: environment === 'test',
          })}
        >
          Teste
        </div>
        <Switch
          className="custom-switch custom-switch-primary custom-switch-small"
          checked={environment === 'live'}
          disabled={fixed_environment}
          onChange={(isChecked) => {
            this.props.changeEnvironment(isChecked ? 'live' : 'test');
          }}
        />
        <div
          className={classnames({
            'env-name': true,
            active: environment === 'live',
          })}
        >
          Live
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { environment, fixed_environment } = settings;
  return { environment, fixed_environment };
};

export default connect(mapStateToProps, { changeEnvironment })(
  injectIntl(EnvironmentSwitch)
);
