import React, { Component } from 'react';
import { CardTitle, Label, Button, Alert } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import User from '../../services/User';

import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';

class RequestReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  // Cognito Handlers
  onUserReset = async (values, setSubmitting) => {
    try {
      const { changeStep } = this.props;
      const { email } = values;

      await User.requestResetPassword(email);

      changeStep('success', email);
    } catch (err) {
      const { messages } = this.props.intl;

      this.setState({
        error: messages['user.UserNotFoundException'],
      });

      setSubmitting(false);
    }
  };

  // Render
  render() {
    const { messages } = this.props.intl;
    const { error } = this.state;

    return (
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(messages['forms.validation.email.valid'])
            .required(messages['forms.validation.email.required']),
        })}
        onSubmit={(values, { setSubmitting }) => {
          this.onUserReset(values, setSubmitting);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <NavLink to="/user/login" className="d-block mb-4">
              <i className="iconsminds-arrow-out-left" />
              <IntlMessages id="user.login.page" />
            </NavLink>

            <CardTitle className="mb-4">
              <IntlMessages id="user.forgot-password" />
            </CardTitle>

            {error && (
              <Alert color="danger" className="mt-2 mb-2">
                {error}
              </Alert>
            )}

            <Label className="form-group has-top-label mb-4">
              <Field type="email" name="email" className="form-control" />
              <IntlMessages id="user.username" />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback d-block"
              />
            </Label>

            <div className="d-flex justify-content-between align-items-center">
              <Button
                type="submit"
                color="primary"
                outline
                size="lg"
                disabled={isSubmitting}
              >
                <IntlMessages id="forms.action.reset" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default injectIntl(RequestReset);
