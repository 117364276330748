import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import UserLayout from "../../layout/UserLayout"

import fundsIn from "./funds-in"

const Webhook = ({ match }) => {
    return (
        <UserLayout>
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`/error`} />
                <Route path={`${match.url}/successful-funds-in`} component={fundsIn} />
                <Redirect to="/error" />
            </Switch>
        </UserLayout>
    );
};

export default Webhook;
