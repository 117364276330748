import React from "react";
import { Form, Formik } from "formik";
import NumberFormat from "react-number-format";
import { Button, InputGroup, Label } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import appFunctions from "../../services/Functions";

export default function AddBalance({ card, onAddBalance, environment, show }) {
  
  // Functions
  const addAmountToCard = async ({ addAmount }, setSubmitting) => {
    try {
      setSubmitting(true);

      const result = await appFunctions.addFunds({
        environment,
        accountId: card.id,
        amount: (addAmount * 100).toFixed(0),
      });

      onAddBalance({
        ...card,
        ...result,
        balance: {
          amount: result.balance,
        },
      });

      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
    }
  };

  // Render
  if(!show) return null;
  
  return (
    <Formik
      initialValues={{
        addAmount: 0,
      }}
      onSubmit={(values, { setSubmitting }) => {
        addAmountToCard(values, setSubmitting);
      }}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
        <Form className="creditcard-form" onSubmit={handleSubmit}>
          <InputGroup>
            <Label className="form-group has-top-label">
              <NumberFormat
                prefix={"R$ "}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale="2"
                className="form-control"
                defaultValue={values.addAmount || ""}
                onValueChange={(v) =>
                  setFieldValue("addAmount", v.floatValue || "")
                }
              />
              <IntlMessages id="card.artviagens.addAmount" />
            </Label>
            <Button
              type="submit"
              color="primary"
              outline
              size="lg"
              disabled={isSubmitting}
            >
              <IntlMessages id="forms.action.activateAndAddAmount" />
            </Button>
          </InputGroup>
        </Form>
      )}
    </Formik>
  );
}
