import firebase from 'firebase/app';
import config from '../constants/Firebase';

import 'firebase/firestore';
import 'firebase/auth';

const firebaseApp = firebase.initializeApp(config);

/**
 * Checks if user is authenticated on Firebase
 *
 * @returns {boolean} If user is authenticated
 */
firebaseApp.isUserLoggedIn = function () {
  return firebaseApp.auth().currentUser !== null;
};

/**
 * Returns the current user information on Firebase Authentication
 *
 * @returns {object} Current User information
 */
firebaseApp.getCurrentUser = function () {
  return firebaseApp.auth().currentUser;
};

/**
 * Instantiantes Firestores usage
 *
 * @returns {FirestoreInstance} Instance to make database requestes
 */
firebaseApp.db = function () {
  return firebaseApp.firestore();
};

/**
 * Modelo que prepara busca de accounts de uma organização
 *
 * @param {object} organizationId O ID da organização
 * @param {string} environment Ambiente de utilização
 * @param {array} status Array de ENUNs de status a retornar
 * @returns {FirebaseReference} Reference pronta para chamar get() ou onSnapshot()
 */
firebaseApp.listAccounts = function ({ organizationId, environment, status }) {
  return firebaseApp
    .db()
    .collection('organizations')
    .doc(organizationId)
    .collection('accounts')
    .where('status', 'in', status)
    .where('env', '==', environment);
};

/**
 * Modelo que prepara busca de accounts de uma organização
 *
 * @param {object} organizationId O ID da organização
 * @param {string} environment Ambiente de utilização
 * @param {array} status Array de ENUNs de status a retornar
 * @returns {FirebaseReference} Reference pronta para chamar get() ou onSnapshot()
 */
firebaseApp.getAccounts = function ({ organizationId, accountId }) {
  return firebaseApp
    .db()
    .collection('organizations')
    .doc(organizationId)
    .collection('accounts')
    .doc(accountId);
};

/**
 * Busca geral
 *
 * @param {Array} references Referências para buscar
 * @returns {FirebaseReference} Reference pronta para chamar get() ou onSnapshot()
 */
firebaseApp.getAccountsFromId = function ({ organizationId, accountId }) {
  return firebaseApp
    .db()
    .collection('organizations')
    .doc(organizationId)
    .collection('accounts')
    .doc(accountId);
};

/**
 * Busca geral
 *
 * @param {Array} references Referências para buscar
 * @param {string} references.organizationId ID da organização
 * @param {string} references.BookingId Booking id
 * @param {string=} references.bookingIdName Nome da propriedade Booking id (reclamar com a 123Milhas)
 * @returns {FirebaseReference} Reference pronta para chamar get() ou onSnapshot()
 */
firebaseApp.getAccountsFromBookingId = function ({ organizationId, BookingId, bookingIdName = 'BookingId' }) {
  return firebaseApp
    .db()
    .collection('organizations')
    .doc(organizationId)
    .collection('accounts')
    .where(`custom_fields.${bookingIdName}`, '==', BookingId);
};

/**
 * Busca geral
 *
 * @param {Array} references Referências para buscar
 * @returns {FirebaseReference} Reference pronta para chamar get() ou onSnapshot()
 */
 firebaseApp.getAccountsFromIds = function ({ organizationId, accountIds }) {
  return firebaseApp
    .db()
    .collection('organizations')
    .doc(organizationId)
    .collection('accounts')
    .where(firebase.firestore.FieldPath.documentId(), 'in', accountIds);
};

/**
 * Busca geral
 *
 * @param {Array} references Referências para buscar
 * @returns {FirebaseReference} Reference pronta para chamar get() ou onSnapshot()
 */
 firebaseApp.getAccountsFromCPF = function ({ organizationId, cpf }) {
  return firebaseApp
    .db()
    .collection('organizations')
    .doc(organizationId)
    .collection('accounts')
    .where('custom_fields.cpf', '==', cpf);
};

/**
 * Modelo que prepara busca de accounts de uma organização
 *
 * @param {object} organizationId O ID da organização
 * @param {string} environment Ambiente de utilização
 * @returns {FirebaseReference} Reference pronta para chamar get() ou onSnapshot()
 */
firebaseApp.listLiveCardTransactions = function ({ organizationId, environment, startDate, endDate }) {
  return firebaseApp
    .db()
    .collectionGroup('transactions')
    .where('organization_id', '==', organizationId)
    .where('env', '==', environment)
    .where('msg_type', 'in', [1100, 1400])
    .orderBy('date_time', 'desc')
    .limit(20)
};

/**
 * Modelo que prepara busca de accounts de uma organização
 *
 * @param {object} organizationId O ID da organização
 * @param {string} environment Ambiente de utilização
 * @returns {FirebaseReference} Reference pronta para chamar get() ou onSnapshot()
 */
firebaseApp.listCardTransactions = function ({ organizationId, environment, startDate, endDate }) {
  return firebaseApp
    .db()
    .collectionGroup('transactions')
    .where('organization_id', '==', organizationId)
    .where('env', '==', environment)
    .where('msg_type', 'in', [1100, 1400])
    .where('date_time', '>=', startDate)
    .where('date_time', '<', endDate)
};

/**
 * Modelo que prepara busca de transactions de uma account
 *
 * @param {object} organizationId O ID da organização
 * @param {string} accountId O ID da account
 * @returns {FirebaseReference} Reference pronta para chamar get() ou onSnapshot()
 */
firebaseApp.listAccountTransactions = function ({ organizationId, accountId }) {
  return firebaseApp
    .db()
    .collection('organizations')
    .doc(organizationId)
    .collection('accounts')
    .doc(accountId)
    .collection('transactions')
    .orderBy('date_time', 'desc');
};

/**
 * Modelo que prepara busca de notifications de uma organização
 *
 * @param {object} organizationId O ID da organização
 * @returns {FirebaseReference} Reference pronta para chamar get() ou onSnapshot()
 */
firebaseApp.listNotifications = function ({ organizationId }) {
  return firebaseApp
    .db()
    .collection('organizations')
    .doc(organizationId)
    .collection('notifications');
};

/**
 * Modelo que busca as configurações
 *
 * @param {object} organizationId O ID da organização
 * @returns {FirebaseReference} Reference pronta para chamar get() ou onSnapshot()
 */
firebaseApp.getConfig = function ({ organizationId }) {
  return firebaseApp
    .db()
    .collection('organizations')
    .doc(organizationId)
    .collection('config');
};

export default firebaseApp;
