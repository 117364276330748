import {
  SET_ACCOUNTS,
  SET_ACCOUNTS_STATUS,
  SET_TRANSACTIONS,
  CLEAR_ACCOUNTS,
  CLEAR_TRANSACTIONS
} from "../actions";

const INIT_STATE = {
  accounts: [],
  accountStatus: ['NEW', 'OK', 'ACTIVE'],
  loadingAccounts: true,
  transactions: [],
  loadingTransactions: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ACCOUNTS:
      return { ...state, accounts: action.payload, loadingAccounts: false };

    case SET_ACCOUNTS_STATUS:
      return { ...state, accountStatus: action.payload, loadingAccounts: true };

    case SET_TRANSACTIONS:
      return { ...state, transactions: action.payload, loadingTransactions: false };

    case CLEAR_ACCOUNTS:
      return { ...state, accounts: [], loadingAccounts: true };

    case CLEAR_TRANSACTIONS:
      return { ...state, transactions: [], loadingTransactions: true };

    default:
      return { ...state };
  }
};
