import appLocaleData from 'react-intl/locale-data/pt';
import ptMessages from '../locales/pt_BR';

const PtLang = {
  messages: {
    ...ptMessages,
  },
  locale: 'pt-BR',
  data: appLocaleData,
};

export default PtLang;
