import React, { Component } from "react";
import ContentLoader from "react-content-loader";

import { injectIntl } from "react-intl";

class BalanceAccountsLoading extends Component {
    render() {
        return (
          <ContentLoader 
            speed={2}
            width={220}
            height={51}
            viewBox="0 0 220 31"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="0" y="25" rx="3" ry="3" width="80" height="6" /> 
            <rect x="0" y="0" rx="3" ry="3" width="200" height="20" />
          </ContentLoader>
        );
    }
}

export default injectIntl(BalanceAccountsLoading);
