import React, { Component } from 'react';

import TopNav from '../containers/navs/Topnav';

export default class AppLayout extends Component {
  render() {
    return (
      <div id="app-container" className="admin-menu">
        <TopNav history={this.props.history} />
        <main>
          <div className="container-fluid">{this.props.children}</div>
        </main>
      </div>
    );
  }
}
