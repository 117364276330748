import React, { useState } from 'react';
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';

import UsersModal from '../users/modal';
import ApiKeyModal from '../apikeys/modal';
// import ConfigModal from '../config/modal';

export default function ConfigDropdown () {
  const [userModal, setUserModal] = useState(false);
  const [apiModal, setApiModal] = useState(false);
  // const [configModal, setConfigModal] = useState(false);

  return (
    <>
      <UncontrolledDropdown className="dropdown-menu-right">
        <DropdownToggle
          className="header-icon notificationButton"
          color="empty"
        >
          <i className="simple-icon-settings" />
        </DropdownToggle>
        <DropdownMenu className="mt-3" right>
          <DropdownItem onClick={() => setUserModal(true)}>Usuários</DropdownItem>
          <DropdownItem onClick={() => setApiModal(true)}>API Keys</DropdownItem>
          {/* <DropdownItem onClick={() => setConfigModal(true)}>Configurações</DropdownItem> */}
          {/* <DropdownItem>Parâmetros Customizáveis</DropdownItem> */}
          {/* <DropdownItem>Modelo de Cartão</DropdownItem> */}
        </DropdownMenu>
      </UncontrolledDropdown>
      {userModal && <UsersModal close={() => { setUserModal(false) }} />}
      {apiModal && <ApiKeyModal close={() => { setApiModal(false) }} />}
      {/* {configModal && <ConfigModal close={() => { setConfigModal(false) }} />} */}
    </>
  );
};

