import React from 'react';
import { Card, CardImg, CardBody, CardText, CardTitle, CardSubtitle, Badge } from 'reactstrap';
import moment from 'moment';

import TransactionIcon from './icon';
import TransactionStatus from './status';
import { stringToMoney } from '../../utils/money';
import { transactionTypes } from '../../constants/accounts';

export default function TransactionItem ({ transaction }) {

  if (transaction.msg_type === 1100) {
    if (transaction.response_code === 0 && transaction.amount > 0) {
      return (
        <Card className="m-1 mb-2 transaction-item success">
          <div className="position-relative mb-4">
            <CardImg top src={`https://maps.googleapis.com/maps/api/staticmap?center=${transaction.merchant_city}&zoom=13&size=500x100&maptype=roadmap&map_id=dc2aa7f8d234514b&key=AIzaSyBazmXD1J6e2MJrG7a9KNXi59O4Kx9loU0`} alt={transaction.merchant_city} />
            <TransactionIcon transaction={transaction} />
            <TransactionStatus transaction={transaction} />
          </div>
          <CardBody>
            <CardTitle className="text-center">{transaction.merchant_name}</CardTitle>
            <CardSubtitle className="text-center">{transactionTypes[transaction.txn_type]}</CardSubtitle>
            <h3 className="float-right mr-3 p-0">{stringToMoney(transaction.amount / 100, 2)}</h3>
            <p className="text-muted text-small mb-0 font-weight-light pt-1">{moment(transaction.date_time).format('DD/MM/YYYY HH:mm')}</p>
          </CardBody>
        </Card>
      )
    }

    return (
      <Card className="m-1 mb-2 transaction-item">
        <div className="position-relative mb-4">
          <TransactionStatus transaction={transaction} />
        </div>
        <CardBody>
          <CardTitle>{transaction.merchant_name}</CardTitle>
          <CardSubtitle>{transactionTypes[transaction.txn_type]}</CardSubtitle>
          <h3 className="float-right mr-3 p-0">{stringToMoney(transaction.amount / 100, 2)}</h3>
          <CardText className="text-muted text-small mb-0 font-weight-light pt-1">{moment(transaction.date_time).format('DD/MM/YYYY HH:mm')}</CardText>
        </CardBody>
      </Card>
    )
  }

  if (transaction.msg_type === 1400 || transaction.msg_type === 1420 || transaction.msg_type === 1120) {
    return (
      <Card className="m-1 mb-2 transaction-item success">
        <div className="position-relative mb-4">
          <CardImg top src={`https://maps.googleapis.com/maps/api/staticmap?center=${transaction.merchant_city}&zoom=13&size=500x100&maptype=roadmap&map_id=dc2aa7f8d234514b&key=AIzaSyBazmXD1J6e2MJrG7a9KNXi59O4Kx9loU0`} alt={transaction.merchant_city} />
          <TransactionIcon transaction={transaction} />

          <Badge
            color="success"
            pill
            className="position-absolute badge-top-left">
            Refunded
          </Badge>
        </div>
        <CardBody>
          <CardTitle className="text-center">{transaction.merchant_name}</CardTitle>
          <CardSubtitle className="text-center">{transactionTypes[transaction.txn_type]}</CardSubtitle>
          <h3 className="float-right text-danger mr-3 p-0">- {stringToMoney(transaction.amount / 100, 2)}</h3>
          <CardText className="text-muted text-small mb-0 font-weight-light pt-1">{moment(transaction.date_time).format('DD/MM/YYYY HH:mm')}</CardText>
        </CardBody>
      </Card>
    )
  }

  if (transaction.txn_type === 12 && transaction.response_code === 0) {
    return (
      <div className="m-1 mb-2 transaction-item">
        <h3 className="float-right mr-3">{stringToMoney(transaction.amount / 100, 2)}</h3>
        <h3 className="mb-0 pb-1">Reserva de fundos</h3>
        <p className="text-muted text-small mb-0 font-weight-light">{moment(transaction.date_time).format('DD/MM/YYYY HH:mm')}</p>
      </div>
    )
  }

  if (transaction.txn_type === 13 && transaction.response_code === 0) {
    return (
      <div className="m-1 mb-2 transaction-item">
        <h3 className="float-right text-danger mr-3">- {stringToMoney(transaction.amount / 100, 2)}</h3>
        <h3 className="mb-0 pb-1">Liberação de fundos</h3>
        <p className="text-muted text-small mb-0 font-weight-light">{moment(transaction.date_time).format('DD/MM/YYYY HH:mm')}</p>
      </div>
    )
  }

  return (<div />);
}