import React, { Component } from 'react';
import { Row, Card } from 'reactstrap';
import { NavLink, Redirect } from 'react-router-dom';
import { Colxx } from '../../components/common/CustomBootstrap';

import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';

import LoginForm from '../../components/auth/LoginForm';
// import NewPassword from "../../components/auth/NewPassword"
// import MFASetup from "../../components/auth/MFASetup"
// import MFA from "../../components/auth/MFA"

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 'login',
      user: null,
      redirect: null,
    };
  }

  // Handle Steps
  loginUser = () => {
    this.setState({
      redirect: '/',
    });
  };

  // Render
  render() {
    const { user, step, redirect } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <Row className="h-100">
        <Colxx xxs="12" sm="11" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side">
              <NavLink to={`/`} className="black">
                <span className="logo-single" />
              </NavLink>

              <h2 className="text-white">
                <IntlMessages id="user.simple" />.<br />
                <IntlMessages id="user.inteligent" />.<br />
                <IntlMessages id="user.direct" />.
              </h2>
            </div>
            <div className="form-side">
              {step === 'login' && (
                <LoginForm user={user} loginUser={this.loginUser} />
              )}
              {/* {step === 'new_password' && <NewPassword user={user} loginUser={this.loginUser} />} */}
              {/* {step === 'mfa_setup' && <MFASetup user={user} loginUser={this.loginUser} />} */}
              {/* {step === 'mfa' && <MFA user={user} loginUser={this.loginUser} />} */}
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}

export default injectIntl(Login);
