import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Form, CardTitle, Button } from 'reactstrap';
import { Formik } from 'formik';
import firebaseApp from '../../services/Firebase';
import map from 'lodash/map';

import IntlMessages from '../../helpers/IntlMessages';
import messages from '../../lang/locales/pt_BR';

import CardForm from '../Card/form';
import CardAccountSchema from '../../schemas/account';
import appFunctions from '../../services/Functions';

export default function NewCardForm({ onCardCreated }) {
  const [loading, setLoading] = useState(true);
  const [defaultFields, setDefaultFields] = useState([]);
  const [error, setError] = useState('');
  const { environment } = useSelector(state => state.settings);
  const { user } = useSelector(state => state.auth);

  const getCustomFieldsInfo = useCallback(async () => {
    const fieldsSnap = await firebaseApp
      .getConfig({ organizationId: user.organizationId })
      .doc('custom_params')
      .get();

    setDefaultFields(fieldsSnap.data());
    setLoading(false);
  }, [user.organizationId]);


  useEffect(() => {
    getCustomFieldsInfo();
  }, [getCustomFieldsInfo]);

  // Form Handlers
  const onCreateCard = async (values, setSubmitting) => {
    const { amount, type , activatesAt, cancelsAt, custom_fields } = values;

    try {
      const cardCreation = await appFunctions.createCard({
        environment,
        amount: (amount * 100).toFixed(0),
        type,
        activatesAt,
        cancelsAt,
        custom_fields: custom_fields.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.value }),
          {}
        ),
      });

      setSubmitting(false);

      onCardCreated(cardCreation);
    } catch (err) {
      setSubmitting(false);

      setError(`${messages['user.GeneralException']} ${err.message}`);
    }
  };

  if (loading) {
    return (<div className="loading" />);
  }

  // Render
  return (
    <Formik
      initialValues={{
        amount: '',
        activatesAt: '',
        cancelsAt: '',
        custom_fields: map(defaultFields?.fields, (field) => {
          return {
            key: field.field,
            value: '',
            required: field.required
          }
        }),
        type: 'DEFAULT',
      }}
      validationSchema={CardAccountSchema(messages)}
      onSubmit={(values, { setSubmitting }) => {
        onCreateCard(values, setSubmitting);
      }}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
        <Form className="creditcard-form" onSubmit={handleSubmit}>
          <CardTitle className="mb-4">
            <IntlMessages id="card.create" />
          </CardTitle>

          <CardForm values={values} setFieldValue={setFieldValue} error={error} messages={messages} />

          <div className="d-flex justify-content-between align-items-center">
            <Button
              type="submit"
              color="primary"
              outline
              size="lg"
              disabled={isSubmitting}
            >
              <IntlMessages id="forms.action.create" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
