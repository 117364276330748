import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { injectIntl } from 'react-intl';
import IntlMessages from '../../helpers/IntlMessages';

import { notificationStatus } from '../../constants/notifications';

import firebaseApp from '../../services/Firebase';
import appFunctions from '../../services/Functions';

import { connect } from 'react-redux';

const NotificationItem = ({ type, account, accountId, updated_at }) => {
  return (
    <div className="d-flex flex-row mb-3 pb-3 border-bottom">
      <div>
        <p className="font-weight-medium mb-1">
          <IntlMessages id={`notifications.alert.${type}`} />
        </p>
        {
          accountId &&
          <p className="mb-1 text-muted text-small">Identificador #{accountId}</p>
        }
        <p className="text-muted mb-0 text-small">
          {moment(updated_at, 'YYYY-MM-DD').format('DD/MM/YYYY')}
        </p>
      </div>
    </div>
  );
};

let notificationsOnSnapshotEvent = () => {};
class TopnavNotifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      notifications: [],
    };
  }

  // Lifecycle
  componentDidMount() {
    this.loadNotifications();
  }

  componentWillUnmount() {
    notificationsOnSnapshotEvent();
  }

  // Load Notifications
  loadNotifications = async () => {
    const { user } = this.props;

    const saveState = (notifications) =>
      this.setState({ notifications, loading: false });

    notificationsOnSnapshotEvent();

    notificationsOnSnapshotEvent = firebaseApp
      .listNotifications({
        organizationId: user.organizationId,
      })
      .onSnapshot((notificationsSnap) => {
        const notifications = [];

        notificationsSnap.forEach((notificationSnap) => {
          notifications.push({
            id: notificationSnap.id,
            ...notificationSnap.data(),
          });
        });

        saveState(notifications);
      });
  };

  // Mark Notifications as Read
  markAsRead = async () => {
    const { notifications } = this.state;

    const unreadNotifications = filter(notifications, (o) => {
      return o.status !== notificationStatus.READ;
    });

    if (unreadNotifications.length > 0) {
      await appFunctions.readNotifications();
    }
  };

  // Render
  render() {
    const { notifications } = this.state;

    const count = filter(notifications, (o) => {
      return o.status !== notificationStatus.READ;
    }).length

    return (
      <div className="position-relative d-inline-block">
        <UncontrolledDropdown className="dropdown-menu-right">
          <DropdownToggle
            className="header-icon notificationButton"
            color="empty"
            onClick={this.markAsRead}
          >
            <i className="simple-icon-bell" />
              {
                count > 0 &&
                <span className="count">{count}</span>
              }
          </DropdownToggle>
          <DropdownMenu
            className="position-absolute mt-3 scroll"
            right
            id="notificationDropdown"
          >
            <PerfectScrollbar
              options={{ suppressScrollX: true, wheelPropagation: false }}
            >
              {map(notifications, (notification, index) => {
                return <NotificationItem key={index} {...notification} />;
              })}

              {notifications.length === 0 && (
                <div>Você não possui notificações e avisos.</div>
              )}
            </PerfectScrollbar>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { user } = auth;
  const { environment } = settings;
  return { user, environment };
};

export default connect(mapStateToProps)(injectIntl(TopnavNotifications));
