import React from 'react';

import { merchantCodes } from '../../constants/accounts';

export default function TransactionIcon ({ transaction }) {
  return (
    <div className="transaction-icon">
      <img
        src={merchantCodes[transaction.mcc]?.icon ? `/assets/img/${merchantCodes[transaction.mcc].icon}` : "/assets/img/icons/store.svg"}
        alt={`${transaction.mcc} - ${merchantCodes[transaction.mcc]?.name}`}
        title={`${transaction.mcc} - ${merchantCodes[transaction.mcc]?.name}`} />
    </div>
  );
}