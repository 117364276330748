import React, { Component } from 'react';
import { Row, Card } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Colxx } from '../../components/common/CustomBootstrap';
import qs from 'query-string';

import appWebhooks from '../../services/Webhooks';

import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';

class FundsIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
    };
  }

  // Lifecycle
  componentDidMount() {
    this.processFundsIn();
  }

  // Handle Steps
  processFundsIn = async () => {
    try {
      // Chama API e informa que funds-in aconteceu
      const params = qs.parse(this.props.location.search);
      
      const response = await appWebhooks.finalizeFundsIn(params);

      if (response === false) {
        this.setState({ error: true });
      }

      this.setState({ loading: false });
    } catch (err) {
      this.setState({ error: true });
    }
  };

  // Render
  render() {
    const { loading, error } = this.state;

    return (
      <Row className="h-100">
        <Colxx xxs="12" sm="11" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side">
              <NavLink to={`/`} className="black">
                <span className="logo-single" />
              </NavLink>

              <h2 className="text-white">
                <IntlMessages id="user.simple" />.<br />
                <IntlMessages id="user.inteligent" />.<br />
                <IntlMessages id="user.direct" />.
              </h2>
            </div>
            <div className="form-side">
              { loading === true
                  ?
                    <div className="loading" />
                  :
                error
                  ?
                    <div> Oops. Um erro ocorreu. {error} </div>
                  :
                  <div>
                    <p>Obrigado!</p>
                    <p>Bank 3 <span role="img" aria-label="<3">❤️</span> SWAP</p>
                  </div>
              }
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}

export default injectIntl(FundsIn);
