import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import AppLayout from '../../layout/AppLayout';
import dashboard from './dashboard';
import firebaseApp from '../../services/Firebase';

import {
  setAccounts,
  setTransactions,
  clearAccounts,
  clearTransactions
} from '../../redux/accounts/actions';

import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';

let transactionsOnSnapshotEvent = () => {};
let accountsOnSnapshotEvent = () => {};

class App extends Component {

  componentDidMount() {
    this.loadAccounts();
    this.loadTransactions();
  }

  componentWillUnmount() {
    transactionsOnSnapshotEvent();
    accountsOnSnapshotEvent();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.environment, this.props.environment)) {
      this.props.clearAccounts();
      this.props.clearTransactions();
      this.loadAccounts();
      this.loadTransactions();
    }

    if (!isEqual(prevProps.accountStatus, this.props.accountStatus)
    ) {
      this.props.clearAccounts();
      this.loadAccounts();
    }

    if (!isEqual({startDate: prevProps.startDate, endDate: prevProps.endDate}, { startDate: this.props.startDate, endDate: this.props.endDate })) {
      this.props.clearTransactions();
      this.loadTransactions();
    }
  }

  // Data load
  loadAccounts = () => {
    const { user, environment, accountStatus } = this.props;
    accountsOnSnapshotEvent();

    const saveState = (accounts) => this.props.setAccounts(accounts);

    accountsOnSnapshotEvent = firebaseApp
      .listAccounts({
        organizationId: user.organizationId,
        environment,
        status: accountStatus
      })
      .onSnapshot((accountsSnap) => {
        const accounts = [];

        accountsSnap.forEach((accountSnap) => {
          accounts.push({
            id: accountSnap.id,
            ...accountSnap.data(),
          });
        });

        saveState(accounts);
      });
  }

  loadTransactions = () => {
    const { user, environment } = this.props;
    transactionsOnSnapshotEvent();

    const saveState = (transactions) => this.props.setTransactions(transactions);

    transactionsOnSnapshotEvent = firebaseApp
      .listLiveCardTransactions({
        organizationId: user.organizationId,
        environment,
      })
      .onSnapshot((transactionsSnap) => {
        const transactions = [];

        transactionsSnap.forEach((transactionSnap) => {
          transactions.push({
            id: transactionSnap.id,
            card_id: transactionSnap.ref.parent.parent.id,
            ...transactionSnap.data(),
          });
        });

        saveState(orderBy(transactions, ['date_time'], ['desc']));
      });
  }

  render() {
    const { match } = this.props;

    return (
      <AppLayout>
        <Switch>
          <Route path={`${match.url}/`} component={dashboard} />
          <Redirect to="/error" />
        </Switch>
      </AppLayout>
    );
  }
}

const mapStateToProps = ({ auth, settings, accounts }) => {
  const { user } = auth;
  const { environment, locale } = settings;
  const { startDate, endDate, accountStatus } = accounts;
  return { user, environment, locale, authUser: auth.auth, startDate, endDate, accountStatus };
};

export default connect(mapStateToProps, {
  clearAccounts,
  clearTransactions,
  setAccounts,
  setTransactions
})(App);
