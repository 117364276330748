import {
  CHANGE_LOCALE,
  CHANGE_ENVIRONMENT,
  IS_ENVIRONMENT_FIXED,
} from '../actions';

export const changeLocale = (locale) => {
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};

export const changeEnvironment = (environment) => {
  return {
    type: CHANGE_ENVIRONMENT,
    payload: environment,
  };
};

export const setFixedEnvironment = (isFixed) => {
  return {
    type: IS_ENVIRONMENT_FIXED,
    payload: isFixed,
  };
};
