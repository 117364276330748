import React from "react";

export default function ErrorBoundary() {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                margin: "15% 0",
            }}
        >
            <img src="/assets/img/error.svg" style={{ height: "200px", marginBottom: "3rem" }} alt="Algo deu errado." />
            <h1>Algo deu errado. Nosso time já foi avisado</h1>
            <h2>Atualize a página e tente novamente</h2>
        </div>
    );
}
