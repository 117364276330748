/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_ENVIRONMENT = 'CHANGE_ENVIRONMENT';
export const IS_ENVIRONMENT_FIXED = 'IS_ENVIRONMENT_FIXED';

/* AUTH */
export const SET_AUTH = 'SET_AUTH';
export const SET_USER = 'SET_USER';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const LOGOUT_USER = 'LOGOUT_USER';

/* ACCOUNS */
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_ACCOUNTS_STATUS = 'SET_ACCOUNTS_STATUS';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_TRANSACTIONS_DATES = 'SET_TRANSACTIONS_DATES';
export const CLEAR_ACCOUNTS = 'CLEAR_ACCOUNTS';
export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS';

export * from './settings/actions';
export * from './auth/actions';
export * from './accounts/actions';
