import axios from 'axios';

const appWebhooks = {};

/**
 * Constroi uma instância do axios
 *
 * @return {axios} Instância do axios com configurações para chamar a SWAP
 * @throws Failed to build axios for SWAP
 */
const buildAxios = async (environment) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Authorization': 'Bearer push_FsqIgVNZuFelZwZGQ6MZ',
      'X-Frame-Options': 'deny',
      'X-Content-Type-Options': 'nosniff',
    };

    return axios.create({
      baseURL: 'https://api.banking.portao3.com.br/push',
      headers,
    });
  } catch (err) {
    throw new Error('Failed to build axios for B3 PUSH');
  }
};

/**
 * Methods: Transfers
 */

appWebhooks.finalizeFundsIn = async function ({ organizationId, transferId }) {
  try {
    const axiosB3 = await buildAxios();

    const response = await axiosB3.post(`/transfers/${organizationId}/${transferId}`);

    return response.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export default appWebhooks;
