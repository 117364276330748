import React, { Component, Fragment } from 'react';
import { Row, Card, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { Colxx } from '../../components/common/CustomBootstrap';

import NewCard from '../../components/NewCard';
import ListCards from '../../components/ListCards';
import ListTransactions from '../../components/ListTransactions';
import BalanceOverview from '../../components/BalanceOverview';

import { connect } from 'react-redux';

class Dashboard extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      tab: 'accounts'
    };
  }

  // Lifecycle
  componentDidMount() {
    //
  }

  toggleTab = (tab) => {
    this.setState({ tab });
  }

  // Render
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx md="12" lg="4">
            <NewCard />
          </Colxx>
          <Colxx md="12" lg="8" className="mb-4">
            <Row>
              <Colxx sm="12" className="mb-4">
                <BalanceOverview />
              </Colxx>
            </Row>
            <Row>
              <Colxx sm="12" className="mb-4">
                <Card className="mb-4">
                  <CardHeader>
                    <Nav tabs className="card-header-tabs ">
                      <NavItem>
                        <NavLink
                          to="#"
                          location={{}}
                          className={classnames({
                            active: this.state.tab === "accounts",
                            "nav-link": true
                          })}
                          onClick={() => {
                            this.toggleTab("accounts");
                          }}
                        >
                          Cartões
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          location={{}}
                          className={classnames({
                            active: this.state.tab === "transactions",
                            "nav-link": true
                          })}
                          onClick={() => {
                            this.toggleTab("transactions");
                          }}
                        >
                          Transações
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>

                  <TabContent activeTab={this.state.tab}>
                    <TabPane tabId="accounts">
                      <Row>
                        <Colxx sm="12">
                          <ListCards />
                        </Colxx>
                      </Row>
                    </TabPane>
                    <TabPane tabId="transactions">
                      <Row>
                        <Colxx sm="12">
                          <ListTransactions />
                        </Colxx>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Card>
              </Colxx>
            </Row>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};

export default connect(mapStateToProps)(Dashboard);
