import React, { Component } from "react";
import ContentLoader from "react-content-loader";

import { injectIntl } from "react-intl";

class BalanceChartsLoading extends Component {
    render() {
        return (
          <ContentLoader 
            speed={2}
            width={505}
            height={180}
            viewBox="0 0 505 180"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="0" y="55" rx="3" ry="3" width="55" height="110" /> 
            <rect x="0" y="170" rx="3" ry="3" width="55" height="10" /> 
            <rect x="75" y="170" rx="3" ry="3" width="55" height="10" /> 
            <rect x="150" y="35" rx="3" ry="3" width="55" height="130" /> 
            <rect x="150" y="170" rx="3" ry="3" width="55" height="10" /> 
            <rect x="225" y="45" rx="3" ry="3" width="55" height="120" /> 
            <rect x="225" y="170" rx="3" ry="3" width="55" height="10" /> 
            <rect x="300" y="35" rx="3" ry="3" width="55" height="130" /> 
            <rect x="300" y="170" rx="3" ry="3" width="55" height="10" /> 
            <rect x="376" y="25" rx="3" ry="3" width="55" height="140" /> 
            <rect x="375" y="170" rx="3" ry="3" width="55" height="10" /> 
            <rect x="450" y="15" rx="3" ry="3" width="55" height="150" /> 
            <rect x="450" y="170" rx="3" ry="3" width="55" height="10" /> 
            <rect x="76" y="45" rx="3" ry="3" width="55" height="120" />
          </ContentLoader>
        );
    }
}

export default injectIntl(BalanceChartsLoading);
