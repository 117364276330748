import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AppLayout from '../../layout/AppLayout';
import dashboard from './dashboard';

export default class App extends Component {
  render() {
    const { match } = this.props;

    return (
      <AppLayout>
        <Switch>
          <Route path={`${match.url}/`} component={dashboard} />
          <Redirect to="/error" />
        </Switch>
      </AppLayout>
    );
  }
}
