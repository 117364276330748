import * as Sentry from '@sentry/react';
import keys from 'lodash/keys';

export const identifySentry = async (traits) => {
  Sentry.setUser({ traits });
};

export const clearSentry = async () => {
  Sentry.setUser({});
};

export const captureSentry = (err) => {
  Sentry.captureException(err);
};

export const trackPropertiesSentry = async (eventName, properties) => {
  Sentry.setContext(eventName, properties);
};

export const captureMessage = (message, properties = {}) => {
  keys(properties, (property) => {
    trackPropertiesSentry(property, properties[property]);
  });

  Sentry.captureException(message);
};
