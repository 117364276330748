import axios from 'axios';
import firebaseApp from './Firebase';

function User() {
  if (!(this instanceof User)) {
    return new User();
  }
}

User.hasAccount = async function (email) {
  try {
    const methods = await firebaseApp.auth().fetchSignInMethodsForEmail(email);

    return !!methods.length;
  } catch (error) {
    if (error.code === 'auth/invalid-email') {
      return false;
    }

    console.error(error);
  }
};

User.login = async function (email, password) {
  return firebaseApp.auth().signInWithEmailAndPassword(email, password);
};

User.logOut = function () {
  return firebaseApp.auth().signOut();
};

User.reautenticate = function (credential) {
  return firebaseApp
    .auth()
    .currentUser.reauthenticateAndRetrieveDataWithCredential(credential);
};

User.checkResetCode = async function (code) {
  return await firebaseApp.auth().checkActionCode(code);
};

User.updatePassword = function (newPassword) {
  return firebaseApp.auth().currentUser.updatePassword(newPassword);
};

User.requestResetPassword = async function (email) {
  return await axios.post('https://api.banking.portao3.com.br/forgot-password', {
    email,
  });
};

User.getToken = async function () {
  return await firebaseApp.auth().currentUser.getIdToken();
};

User.resetPassword = async function (code, newPassword) {
  return await firebaseApp.auth().confirmPasswordReset(code, newPassword);
};

User.isAdmin = function (user) {
  return user?.admin;
};

export default User;
