import React, { Component } from 'react';
import {
  Card,
  CardTitle,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import times from 'lodash/times';
import maxBy from 'lodash/maxBy';

import { BarChart } from '../charts';

import appFunctions from '../../services/Functions';
import { stringToMoney } from '../../utils/money';
import { accountsStatus, isAccountActive } from '../../constants/accounts';

import BalanceAccountsLoading from './loadingAccount';
import BalanceChartsLoading from './loadingChart';

import { connect } from 'react-redux';

class BalanceOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      period: 7,
      balanceLoading: true,
      balanceError: false,
      balance: 0,
      blocked: 0,
    };
  }

  // Lifecycle
  componentDidMount() {
    this.loadBalance();
  }

  componentDidUpdate(prevProps) {
    if (
    sumBy(prevProps.accounts, (account) => { return isAccountActive(account) ? account.amount : 0; })
      !== sumBy(this.props.accounts, (account) => { return isAccountActive(account) ? account.amount : 0; })
    ) {
      this.loadBalance();
    }
  }

  // Período
  togglePeriod = (period) => {
    this.setState({
      period,
    });
  };

  // Load Balance
  loadBalance = async () => {
    const { environment } = this.props;

    const balanceResponse = await appFunctions.getBalance({ environment });

    if (balanceResponse === false) {
      this.setState({
        balanceLoading: false,
        balanceError: true
      });
    } else {
      this.setState({
        balanceLoading: false,
        balance: balanceResponse.balance,
        blocked: balanceResponse.activeBalance,
      });
    }
  };

  // Chart Data
  getChartData = () => {
    const { period } = this.state;
    const { accounts } = this.props;

    const chartLabels = [];
    const chartData = [];
    // Find old accounts
    const oldAccounts = sumBy(accounts, (a) => {
      if (a.status === accountsStatus.INSUFFICIENT_FUNDS
        || a.status === accountsStatus.NEW
        || a.status === accountsStatus.OK) {
        if (
          moment().isSameOrAfter(moment(a.activatesAt, 'YYYY-MM-DD'), 'day')
        ) {
          return parseInt(a.amount);
        }
      }

      return 0;
    });

    if (oldAccounts > 0) {
      chartLabels.push('Hoje');
      chartData.push(oldAccounts / 100);
    }

    let maxTime = period;
    if (maxTime === 'all') {
      const lastDay = maxBy(accounts, (a) => moment(a.activatesAt, 'YYYY-MM-DD').unix());

      maxTime = moment(lastDay.activatesAt).diff(moment(), 'days');
      console.log({maxTime});
    }

    times(maxTime, (i) => {
      const days = i + 1;
      const date = moment().add(days, 'days');
      chartLabels.push(date.format('DD/MM'));

      const totalAccounts = sumBy(accounts, (a) => {
        if (date.isSame(moment(a.activatesAt, 'YYYY-MM-DD'), 'day')) {
          if (a.status === accountsStatus.NEW || a.status === accountsStatus.OK) {
            return parseInt(a.amount);
          }
        }

        return 0;
      });

      chartData.push(totalAccounts / 100);
    });

    return {
      chartLabels,
      chartData,
    };
  };

  // Render
  render() {
    const { period, balance, blocked, balanceLoading, balanceError } = this.state;
    const { loadingAccounts } = this.props;

    const chartData = this.getChartData();

    return (
      <Card className="balance-card">
        <CardTitle className="p-4 mb-2">
          <div className="status-filter-selection btn-group float-right float-none-xs mt-2">
            <UncontrolledDropdown>
              <DropdownToggle caret color="primary" className="btn-xs" outline>
                <IntlMessages id={`account.period.${period}`} />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    this.togglePeriod(7);
                  }}
                >
                  <IntlMessages id={`account.period.7`} />
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.togglePeriod(15);
                  }}
                >
                  <IntlMessages id={`account.period.15`} />
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.togglePeriod(30);
                  }}
                >
                  <IntlMessages id={`account.period.30`} />
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.togglePeriod('all');
                  }}
                >
                  <IntlMessages id={`account.period.all`} />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </CardTitle>
        <CardBody className="pl-4 pr-4 pb-5 pt-0">
          <div className="info">
            {
              balanceLoading ?
                <div className="mt-4">
                  <BalanceAccountsLoading />
                </div>
              :
                balanceError ?
                  <div>
                    <h2 className="d-inline">Indisponível no momento</h2>
                    <span className="text-muted text-small d-block">
                      <IntlMessages id="balance-cards.available.title" />
                    </span>
                  </div>
                :
                  <div>
                    <h2 className="d-inline">{stringToMoney(balance / 100, 2)}</h2>
                    <span className="text-muted text-small d-block">
                      <IntlMessages id="balance-cards.available.title" />
                    </span>
                  </div>
            }
            
            {
              balanceLoading ?
                <div className="mt-4">
                  <BalanceAccountsLoading />
                </div>
              :
                <div className="mt-4">
                  <h2 className="d-inline">{stringToMoney(blocked / 100, 2)}</h2>
                  <span className="text-muted text-small d-block">
                    <IntlMessages id="balance-cards.blocked.title" />
                  </span>
                </div>
            }

            {
              loadingAccounts ?
                <div className="mt-4">
                  <BalanceAccountsLoading />
                </div>
              :
                <div className="mt-4">
                  <h2 className="d-inline">
                    {stringToMoney(sum(chartData.chartData), 2)}
                  </h2>
                  <span className="text-muted text-small d-block">
                    <IntlMessages id="balance-cards.next.title" />
                  </span>
                </div> 
            }
          </div>

          <div className="chart">
            {
              loadingAccounts ?
                <BalanceChartsLoading />
              :
                <BarChart
                  data={{
                    labels: chartData.chartLabels,
                    datasets: [
                      {
                        label: '',
                        borderColor: '#918bc1',
                        backgroundColor: '#918bc1',
                        data: chartData.chartData,
                        borderWidth: 0,
                      },
                    ],
                  }}
                />
            }
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ auth, settings, accounts }) => {
  const { user } = auth;
  const { environment } = settings;
  const { loadingAccounts } = accounts;

  return {
    user,
    environment,
    accounts: accounts.accounts,
    loadingAccounts,
  };
};

export default connect(mapStateToProps)(injectIntl(BalanceOverview));
