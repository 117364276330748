import React, { Component } from 'react';
import { Row, Card } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Colxx } from '../../components/common/CustomBootstrap';

import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';

import RequestReset from '../../components/auth/RequestReset';
import SuccessReset from '../../components/auth/SuccessReset';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 'request',
      email: '',
    };
  }

  // Handle Steps
  changeStep = (step, email) => {
    this.setState({
      email,
      step,
    });
  };

  // Render
  render() {
    const { step } = this.state;

    return (
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side ">
              <NavLink to={`/`} className="black">
                <span className="logo-single" />
              </NavLink>

              <h2 className="text-white">
                <IntlMessages id="user.simple" />.<br />
                <IntlMessages id="user.inteligent" />.<br />
                <IntlMessages id="user.direct" />.
              </h2>
            </div>
            <div className="form-side">
              {step === 'request' && (
                <RequestReset changeStep={this.changeStep} />
              )}
              {step === 'success' && <SuccessReset />}
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}

export default injectIntl(ForgotPassword);
