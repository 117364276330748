import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { injectIntl } from 'react-intl';

import TopnavNotifications from '../../components/navs/Notifications';
import UserDropdown from '../../components/navs/UserDropdown';
import ConfigDropdown from '../../components/navs/ConfigDropdown';
import EnvironmentSwitch from '../../components/navs/EnvironmentSwitch';
import Logo from '../../components/navs/Logo';

class TopNav extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { user, environment } = this.props;

    if (!user) {
      return <nav className="navbar" />;
    }

    return (
      <nav className={`navbar ${environment}`}>
        <div className="navbar-left">
          <Logo />
        </div>

        <div className="d-flex justify-content-center navbar-center"></div>

        <div className="navbar-right">
          <div className="d-inline-block align-middle env-toggle">
            <EnvironmentSwitch />
          </div>

          <div className="header-icons d-inline-block align-middle">
            <ConfigDropdown />
          </div>
          <div className="header-icons d-inline-block align-middle">
            <TopnavNotifications />
          </div>
          <div className="user d-inline-block">
            <UserDropdown />
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { user } = auth;
  const { environment } = settings;
  return { user, environment };
};

export default connect(mapStateToProps)(injectIntl(withRouter(TopNav)));
