import * as Yup from 'yup';
import moment from 'moment';

let parseFormats = ['YYYY-MM-DD'];
let invalidDate = new Date('');

const CardAccountSchema = (messages) => {
  return Yup.object().shape({
    amount: Yup.number()
      .required(messages['forms.validation.amount.required'])
      .min(0.00, messages['forms.validation.amount.min'])
      .max(1000000.00, messages['forms.validation.amount.max']),
    activatesAt: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;
        value = moment(originalValue, parseFormats);
        return value.isValid() ? value.toDate() : invalidDate;
      })
      .min(
        moment().subtract(1, 'day').toDate(),
        messages['forms.validation.date.greaterToday']
      )
      .required(messages['forms.validation.date.required']),
    cancelsAt: Yup.date()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;
        value = moment(originalValue, parseFormats);
        return value.isValid() ? value.toDate() : invalidDate;
      })
      .when(
        'activatesAt',
        (activatesAt, schema) =>
          activatesAt &&
          schema.min(
            activatesAt,
            messages['forms.validation.date.greaterThanActivation']
          )
      )
      .required(messages['forms.validation.date.required']),
    custom_fields: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required(
          messages['forms.validation.customFields.required']
        ),
        value: Yup.string().required(
          messages['forms.validation.customFields.required']
        ),
      })
    ),
  });
}

export default CardAccountSchema;
