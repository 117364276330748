import React, { Component } from 'react';
import {
  Card,
  CardTitle,
  CardBody,
  Button
} from 'reactstrap';
import filter from 'lodash/filter';
import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';
import Pagination from '../Pagination';

import TransactionItem from './item';
import AccountDetail from '../ListCards/detail';
import ExportTransactions from '../ExportTransactions';

import { responseCodes } from '../../constants/accounts';

import { connect } from 'react-redux';

class ListTransactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsPerPage: 20,
      currentPage: 1,
      status: 'all',
      detail: null,
      exportModal: false
    };
  }

  // Status Filte
  toggleStatus = (newStatus) => {
    const { status } = this.state;

    if (newStatus === status) {
      this.setState({ status: 'all' });
    } else {
      this.setState({ status: newStatus });
    }
  };

  // Pagination
  onChangePage = (currentPage) => {
    window.scrollTo({ top: 50, behavior: 'smooth' });
    this.setState({ currentPage });
  };

  // Search
  filterResults = () => {
    const { status, currentPage, itemsPerPage } = this.state;
    const { transactions } = this.props;

    let filteredTransactions = filter(transactions, (o) => {
      if (status === 'approved' && responseCodes[o.response_code].approved !== true) return false;
      if (status === 'denied' && responseCodes[o.response_code].approved !== false) return false;
      if (status === 'refund' && o.msg_type !== 1400) return false;

      return true;
    });

    const total = filteredTransactions.length;
    filteredTransactions = filteredTransactions.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    return {total, filteredTransactions};
  };

  // Details
  openDetails = (transaction) => {
    this.setState({ detail: transaction.card_id });
  }

  closeDetails = () => {
    this.setState({
      detail: null
    })
  }

  // Export
  openExport = () => {
    this.setState({ exportModal: true });
  }

  closeExport = () => {
    this.setState({ exportModal: false });
  }


  // Render
  render() {
    const { currentPage, itemsPerPage, exportModal, detail } = this.state;
    const { transactions, loadingTransactions } = this.props;

    return (
      <Card className="mt-4">
        <CardTitle className="p-4 mb-2">
          <div className="live-holder">
            <div className="live-icon">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="float-left float-none-xs">
            <div className="d-inline-block">
              <h5 className="d-inline">
                <IntlMessages id="list-transactions.title" />
              </h5>
              <span className="text-muted text-small d-block">
                <IntlMessages id="list-transactions.subtitle" />
              </span>
            </div>
          </div>
          <div className="export-button btn-group float-right float-none-xs mt-2 ml-2">
            <Button size="xs" outline color="primary" onClick={this.openExport}>
              <i className="iconsminds-download-1"></i>
            </Button>

            {
              exportModal &&
              <ExportTransactions closeModal={this.closeExport} />
            }
          </div>
        </CardTitle>
        <CardBody className="pl-4 pr-4 pb-5 pt-0">
          {loadingTransactions ? (
            <div className="list-cards-loading">
              <div className="loading" />
            </div>
          ) : transactions.length === 0 ? (
            <div className="list-cards-empty">
              <i className="simple-icon-magnifier" />
              <IntlMessages id="list-cards.no-cards" />
            </div>
          ) : (
            <div>
              {
                this.filterResults(transactions).filteredTransactions.map((transaction) => {
                  return (
                    <TransactionItem transaction={transaction} openDetails={this.openDetails} key={`transaction-${transaction.id}`} />
                  );
                })
              }

              <Pagination
                currentPage={currentPage}
                totalPage={
                  Math.ceil(this.filterResults(transactions).total / itemsPerPage)
                }
                onChangePage={(i) => this.onChangePage(i)}
              />
            </div>
          )}
        </CardBody>

        {
          detail !== null &&
          <AccountDetail accountId={detail} closeDetails={this.closeDetails} />
        }
      </Card>
    );
  }
}

const mapStateToProps = ({ accounts }) => {
  return {
    transactions: accounts.transactions,
    accounts: accounts.accounts,
    loadingTransactions: accounts.loadingTransactions,
  };
};

export default connect(mapStateToProps)(injectIntl(ListTransactions));
