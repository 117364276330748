import React, { Component } from 'react';
import { Formik } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button, Alert, InputGroup, Label, FormGroup } from 'reactstrap';
import { Field, ErrorMessage } from 'formik';
import { CSVDownload } from "react-csv";
import moment from 'moment';
import remove from 'lodash/remove';

import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';

import { responseCodes } from '../../constants/accounts';

import appFunctions from '../../services/Functions';

class ExportTransactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      error: '',
      download: false,
    };
  }

  // Load data
  prepExport = async (values, setSubmitting) => {
    const { environment } = this.props;
    const { startDate, endDate, transactionStatus, format } = values;
    const { donwloadFromBlobUrl } = require('./../../utils/file');

    setSubmitting(true);
    this.setState({ error: '', download: false });

    try {
      // Verifica status que vamos filtrar
      let responseCodesFilter = Object.keys(responseCodes);
      if (transactionStatus === 'approved') remove(responseCodesFilter, (i) => { return !responseCodes[i].approved });
      if (transactionStatus === 'denied') remove(responseCodesFilter, (i) => { return responseCodes[i].approved });

      const startDateFilter = moment(startDate, 'YYYY-MM-DD').utc().startOf('day').format('YYYY-MM-DD[T]HH:mm:ss+00:00');
      const endDateFilter = moment(endDate, 'YYYY-MM-DD').utc().endOf('day').format('YYYY-MM-DD[T]HH:mm:ss+00:00');

      const params = {
        environment,
        startDate: startDateFilter,
        endDate: endDateFilter,
        response_codes: responseCodesFilter
      }

      if (format === 'csv') {
        const transactions = await appFunctions.listOrganizationTransactions(params);

        // Retorna erro se não encontrar transações
        if (transactions.records === 0) {
          this.setState({ error: 'Não há transações para exportar nesse período.' });
          setSubmitting(false);
          return;
        }

        let transactionItems = [];
        Object.keys(transactions.timeline).map((key, index) => {
          transactionItems = [...transactionItems, ...transactions.timeline[key].items];
          return transactionItems;
        });

        this.setState({ data: transactionItems, download: 'csv' });
      }

      if (format === 'pdf') {
        const response = await appFunctions.printOrganizationTransactions(params);

        const blob = new Blob([response], {
          type: 'application/pdf',
        });

        const url = window.URL.createObjectURL(blob);
        window.open(url);
      }

      if (format === 'ofx') {
        const response = await appFunctions.ofxOrganizationTransactions(params);

        const blob = new Blob([response], { type: 'application/x-ofx' });

        let url = window.URL.createObjectURL(blob);
        let fileName = `statement-${startDateFilter} - ${endDateFilter}.ofx`;

        donwloadFromBlobUrl(url, fileName);
      }

      setSubmitting(false);
      return;
    } catch (err) {
      this.setState({
        error: "Não foi possível solicitar as transações nesse momento. Por favor, tente novamente mais tarde.",
      });
      setSubmitting(false);

      return;
    }
  }

  // Render
  render() {
    const { closeModal } = this.props;
    const { error } = this.state;

    return (
      <Modal isOpen={true} toggle={closeModal} wrapClassName="">
        <Formik
          initialValues={{
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            transactionStatus: 'approved',
            format: 'csv'
          }}
          onSubmit={(values, { setSubmitting }) => {
            this.prepExport(values, setSubmitting);
          }}
        >
          {({ isSubmitting, handleSubmit }) => (
            <Form className="creditcard-form" onSubmit={handleSubmit}>
              <ModalHeader>
                <IntlMessages id="transactionsExport.title" />
              </ModalHeader>

              <ModalBody>
                <div>
                  {error && (
                    <Alert color="danger" className="mt-2 mb-2">
                      {error}
                    </Alert>
                  )}

                  <InputGroup>
                    <Label className="form-group has-top-label mb-4">
                      <Field type="date" name="startDate" className="form-control" />
                      <IntlMessages id="transactionsExport.startDay" />
                      <ErrorMessage
                        name="startDate"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                    <Label className="form-group has-top-label mb-4">
                      <Field type="date" name="endDate" className="form-control" />
                      <IntlMessages id="transactionsExport.endDay" />
                      <ErrorMessage
                        name="endDate"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                  </InputGroup>

                  <FormGroup>
                    <Label>
                      <IntlMessages id="transactionsExport.transactionStatus" />
                    </Label>
                    <div>
                      <Label className="d-block">
                        <Field
                          type="radio"
                          id="transactionStatusFilterApproved"
                          name="transactionStatus"
                          value="approved"
                          className="mr-1"
                        />
                        <IntlMessages id="transactionsExport.transactionStatus.approved" />
                      </Label>
                      <Label className="d-block">
                        <Field
                          type="radio"
                          id="transactionStatusFilterDenied"
                          name="transactionStatus"
                          value="denied"
                          className="mr-1"
                        />
                        <IntlMessages id="transactionsExport.transactionStatus.denied" />
                      </Label>
                      <Label className="d-block">
                        <Field
                          type="radio"
                          id="transactionStatusFilterAll"
                          name="transactionStatus"
                          value="all"
                          className="mr-1"
                        />
                        <IntlMessages id="transactionsExport.transactionStatus.all" />
                      </Label>
                    </div>
                  </FormGroup>

                  <FormGroup className="mt-3">
                    <Label>
                      <IntlMessages id="transactionsExport.format" />
                    </Label>
                    <div>
                      <Label className="d-block">
                        <Field
                          type="radio"
                          id="transactionFormatCSV"
                          name="format"
                          value="csv"
                          className="mr-1"
                        />
                        <IntlMessages id="transactionsExport.format.CSV" />
                      </Label>
                      <Label className="d-block">
                        <Field
                          type="radio"
                          id="transactionFormatPDF"
                          name="format"
                          value="pdf"
                          className="mr-1"
                        />
                        <IntlMessages id="transactionsExport.format.PDF" />
                      </Label>
                      <Label className="d-block">
                        <Field
                          type="radio"
                          id="transactionFormatOFX"
                          name="format"
                          value="ofx"
                          className="mr-1"
                        />
                        <IntlMessages id="transactionsExport.format.OFX" />
                      </Label>
                    </div>
                  </FormGroup>
                </div>

              </ModalBody>

              <ModalFooter>
                <Button
                    type="submit"
                    color="primary"
                    outline
                    size="lg"
                    disabled={isSubmitting}
                  >
                    <IntlMessages id="forms.action.download" />
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>

        {
          this.state.download === 'csv' &&
          <CSVDownload data={this.state.data} target="_blank" />
        }
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth, settings, accounts }) => {
  const { user } = auth;
  const { environment } = settings;
  return { user, environment, accounts: accounts.accounts };
};

export default connect(mapStateToProps)(injectIntl(ExportTransactions));
