import React, { Component } from 'react';
import { Badge, Button } from 'reactstrap';
import moment from 'moment';

import IntlMessages from '../../helpers/IntlMessages';

import { responseCodes } from '../../constants/accounts';
import { stringToMoney } from '../../utils/money';

export default class TransactionItem extends Component {
  // Render
  render() {
    const { transaction, openDetails } = this.props;

    return (
      <div className="account-item">
        <Badge
          color={
            responseCodes[transaction.response_code]?.approved === true
              ? 'success'
              : 'danger'
          }
          pill
          className="position-absolute badge-top-left"
        >
          { responseCodes[transaction.response_code]?.name }
        </Badge>
        <div className="account-item-content">
          <div className="account-item-desc amount">
            <IntlMessages id="account.amount" />
            {stringToMoney(transaction.amount / 100, 2)}
          </div>

          <div className="account-item-desc">
            <IntlMessages id='transaction.mwrchant' />
            {transaction.merchant_name} <br />
            {transaction.merchant_city} <br />
            {moment(transaction.date_time).format('DD/MM/YYYY HH:mm')}
          </div>

          <div className="space-filler-table" />

          <Button color="empty" onClick={() => { openDetails(transaction) }}>
            <i className="simple-icon-options-vertical" />
          </Button>
        </div>
      </div>
    );
  }
}
