import React, { Component } from 'react';
import { CardTitle, Button } from 'reactstrap';
import moment from 'moment';
import IntlMessages from '../../helpers/IntlMessages';

import CardDisplay from '../Card/display';

import { stringToMoney } from '../../utils/money';

import { connect } from 'react-redux';

class NewCardInfo extends Component {
  // Render
  render() {
    const { card } = this.props;

    return (
      <div>
        <Button
          onClick={this.props.onCardNew}
          color="empty"
          size="sm"
          className="d-block mb-4 pl-0"
        >
          <i className="iconsminds-arrow-out-left" />{' '}
          <IntlMessages id="card.createanoter" />
        </Button>

        <CardTitle className="mb-4">
          <IntlMessages id="card.created" />
        </CardTitle>

        <CardDisplay card={card} />

        <div className="mt-5">
          <p className="mb-1">
            <strong>Identificador: </strong> {card.id}
          </p>
          <p className="mb-1">
            <strong>Período Válido: </strong>{' '}
            {moment(card.activatesAt, 'YYYY-MM-DD').format('DD/MM/YYYY')} -{' '}
            {moment(card.cancelsAt, 'YYYY-MM-DD').format('DD/MM/YYYY')}
          </p>
          <p className="mb-1">
            <strong>Valor programado: </strong>{' '}
            {stringToMoney(card.amount / 100, 2)}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { user } = auth;
  const { environment } = settings;
  return { user, environment };
};

export default connect(mapStateToProps)(NewCardInfo);
