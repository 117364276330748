import {
  SET_ACCOUNTS,
  SET_ACCOUNTS_STATUS,
  SET_TRANSACTIONS,
  CLEAR_ACCOUNTS,
  CLEAR_TRANSACTIONS
} from "../actions";

export const setAccounts = (accounts) => ({
  type: SET_ACCOUNTS,
  payload: accounts,
});

export const setAccountsStatus = (accountStatus) => ({
  type: SET_ACCOUNTS_STATUS,
  payload: accountStatus,
});

export const setTransactions = (transactions) => ({
  type: SET_TRANSACTIONS,
  payload: transactions,
});

export const clearAccounts = () => ({
  type: CLEAR_ACCOUNTS,
});

export const clearTransactions = () => ({
  type: CLEAR_TRANSACTIONS
})
