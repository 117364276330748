import { defaultLocale, localeOptions } from '../../constants/defaultValues';

import {
  CHANGE_LOCALE,
  CHANGE_ENVIRONMENT,
  IS_ENVIRONMENT_FIXED,
} from '../actions';

const INIT_STATE = {
  locale:
    localStorage.getItem('currentLanguage') &&
    localeOptions.filter(
      (x) => x.id === localStorage.getItem('currentLanguage')
    ).length > 0
      ? localStorage.getItem('currentLanguage')
      : defaultLocale,
  environment: JSON.parse(localStorage.getItem('environment')) || 'test',
  fixed_environment:
    JSON.parse(localStorage.getItem('fixed_environment')) || false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      localStorage.setItem('currentLanguage', action.payload);
      return { ...state, locale: action.payload };

    case CHANGE_ENVIRONMENT:
      localStorage.setItem('environment', JSON.stringify(action.payload));
      return { ...state, environment: action.payload };

    case IS_ENVIRONMENT_FIXED:
      localStorage.setItem('fixed_environment', JSON.stringify(action.payload));
      return { ...state, fixed_environment: action.payload };

    default:
      return { ...state };
  }
};
