import React, { useState } from 'react';
import { Label, Button, Alert, Collapse, InputGroup } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Field, ErrorMessage, FieldArray } from 'formik';
import map from 'lodash/map';

import IntlMessages from '../../helpers/IntlMessages';

import { accountTypeIds } from '../../constants/accounts';

export default function CardForm ({ values, setFieldValue, error, messages }) {
  const [moreOptions, setMoreOptions] = useState(
    values.custom_fields.length > 0
  );

  function toggleMoreOptions() {
    setMoreOptions(!moreOptions);
  }

  return (
    <div>
      {error && (
        <Alert color="danger" className="mt-2 mb-2">
          {error}
        </Alert>
      )}

      <Label className="form-group has-top-label mb-4">
        <NumberFormat
          prefix={'R$ '}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale="2"
          fixedDecimalScale="2"
          className="form-control"
          defaultValue={values.amount || ''}
          onValueChange={(v) =>
            setFieldValue('amount', v.floatValue || '')
          }
        />
        <IntlMessages id="card.amount" />
        <ErrorMessage
          name="amount"
          component="div"
          className="invalid-feedback d-block"
        />
      </Label>
      
      <InputGroup>
        <Label className="form-group has-top-label mb-4">
          <Field type="date" name="activatesAt" className="form-control" />
          <IntlMessages id="card.activatesAt" />
          <ErrorMessage
            name="activatesAt"
            component="div"
            className="invalid-feedback d-block"
          />
        </Label>
        <Label className="form-group has-top-label mb-4">
          <Field type="date" name="cancelsAt" className="form-control" />
          <IntlMessages id="card.cancelsAt" />
          <ErrorMessage
            name="cancelsAt"
            component="div"
            className="invalid-feedback d-block"
          />
        </Label>
      </InputGroup>

      <div className="border mb-4">
        <Button
          color="link"
          onClick={toggleMoreOptions}
          aria-expanded={moreOptions}
        >
          <IntlMessages id="card.more-options" />
        </Button>
        <Collapse isOpen={moreOptions}>
          <div className="pl-4 pr-4 pb-4 pt-2">
            <div className="custom_field">
              <div className="custom_field-title mb-2">
                Parâmetros Customizáveis
              </div>
              <div className="custom_field-title">
                <div>Chave</div>
                <div>Identificador</div>
              </div>
              <FieldArray
                name="custom_fields"
                render={(arrayHelpers) => (
                  <div>
                    {values.custom_fields.map((custom_field, index) => (
                      <div className="custom_field-item" key={index}>
                        <Label className="form-group has-top-label">
                          <Field name={`custom_fields.${index}.key`} placeholder='ex: RESERVA, PAX, CPF' readOnly={values.custom_fields[index].required} />
                          <ErrorMessage
                            name={`custom_fields.${index}.key`}
                            component="div"
                            className="invalid-feedback d-block"
                          />
                        </Label>
                        <Label className="form-group has-top-label">
                          <Field name={`custom_fields.${index}.value`}  placeholder='ex: FERNANDO, 54891' />
                          <ErrorMessage
                            name={`custom_fields.${index}.value`}
                            component="div"
                            className="invalid-feedback d-block"
                          />
                        </Label>
                        {!values.custom_fields[index].required &&
                          <Button
                            outline
                            color="secondary"
                            size="sm"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <i className="simple-icon-minus" />
                          </Button>
                        }
                      </div>
                    ))}

                    <div className="custom_field-footer">
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={() =>
                          arrayHelpers.push({ key: '', value: '' })
                        }
                      >
                        <i className="simple-icon-plus" /> Adicionar
                      </Button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>

          <div className="pl-4 pr-4 pb-4 pt-2">
            <div className="custom_field">
              <Label className="form-group has-top-label mb-0 w-100">
                <Field
                  as="select"
                  name="type"
                  className="form-control"
                >
                  {map(Object.values(accountTypeIds), (key) => {
                    return (
                      <option
                        value={key}
                        key={`accountType-${key}`}
                      >
                        {messages[`card.type.${key}`]}
                      </option>
                    );
                  })}
                </Field>
                <IntlMessages id="card.type" />
                <ErrorMessage
                  name="type"
                  component="div"
                  className="invalid-feedback d-block"
                />
              </Label>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  )
}