export const identifySegment = async (userId, traits) => {
    window.analytics.identify(userId, traits);

    return true;
};

export const clearSegment = async () => {
    window.analytics.reset();

    return true;
};

export const trackSegment = async (eventName) => {
    window.analytics.track(eventName);

    return true;
};

export const trackPropertiesSegment = async (eventName, properties) => {
    window.analytics.track(eventName, properties);

    return true;
};

export const seenSegment = async (screenName) => {
    window.analytics.page(screenName);

    return true;
};
