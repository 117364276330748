import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Form,
  Card,
  CardBody,
  CardTitle,
  Label,
  Button,
  Badge,
} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import { Formik, Field } from "formik";
import moment from "moment";

import IntlMessages from "../../helpers/IntlMessages";
import { accountsStatus } from "../../constants/accounts";

import firebaseApp from "../../services/Firebase";
import appFunctions from "../../services/Functions";
import CardDisplay from "../../components/Card/display";
import AccountDetail from "../../components/ListCards/detail";
import { stringToMoney } from "../../utils/money";
import { getDocumentsFromCollection } from "../../utils/firebase";
import NewCard from "../../components/NewCard";
import AddBalance from "./AddBalance";

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [detail, setDetail] = useState(null);

  const { environment } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);

  // States
  const canAddAmount = useMemo(
    () => user.admin || user.permissions?.addBalance,
    [user]
  );

  // Functions
  const searchCardByCPF = async ({ cpf }, setSubmitting) => {
    try {
      setLoading(true);

      const findCardsSnap = await firebaseApp
        .getAccountsFromCPF({ organizationId: user.organizationId, cpf })
        .get();

      const promises = [];
      findCardsSnap.forEach((cardSnap) => {
        promises.push(
          new Promise(async (resolve, reject) => {
            const environment = cardSnap.data().env;
            const accountId = cardSnap.id;

            const cardInfo = await appFunctions.retrieveCard({
              environment,
              accountId,
            });

            resolve({
              ...cardSnap.data(),
              id: cardSnap.id,
              cardInfo,
            });
          })
        );
      });

      const cards = await Promise.all(promises);

      setCards(cards);
      setLoading(false);
      setSubmitting(false);
    } catch (err) {
      console.error(err);
      setSubmitting(false);
    }
  };

  const searchCardByID = async ({ id }, setSubmitting) => {
    try {
      setLoading(true);

      const cardSnap = await firebaseApp
        .getAccountsFromId({
          organizationId: user.organizationId,
          accountId: id,
        })
        .get();

      const environment = cardSnap.data().env;
      const accountId = cardSnap.id;

      const cardInfo = await appFunctions.retrieveCard({
        environment,
        accountId,
      });

      const cards = [
        {
          ...cardSnap.data(),
          id: cardSnap.id,
          cardInfo,
        },
      ];

      setCards(cards);
      setLoading(false);
      setSubmitting(false);
    } catch (err) {
      console.error(err);
      setSubmitting(false);
    }
  };

  const searchCardByBookingId = async ({ BookingId }, setSubmitting) => {
    try {
      setLoading(true);

      const cards = await Promise.all(
        ["BookingId", "Bookingld", "bookingld", "bookingid", 'bookingId', "Bookingid"].map((bookingIdName) =>
          getDocumentsFromCollection(() =>
            firebaseApp
              .getAccountsFromBookingId({
                organizationId: user.organizationId,
                BookingId,
                bookingIdName,
              })
              .get()
          )
        )
      );

      const formattedCards = await Promise.all(
        cards.flat().map(
          (card) =>
            new Promise(async (res) => {
              try {
                const cardInfo = await appFunctions.retrieveCard({
                  environment: card.env,
                  accountId: card.id,
                });

                return res({
                  ...card,
                  cardInfo,
                });
              } catch (err) {
                console.error("Unable to fetch card info", err);
                return res(null);
              }
            })
        )
      );

      setCards(formattedCards.filter((v) => v));
      setLoading(false);
      setSubmitting(false);
    } catch (err) {
      console.error(err);
      setSubmitting(false);
    }
  };

  const onAddBalance = (card) => {
    setCards((cards) =>
      cards.map((item) => (item.id === card.id ? card : item))
    );
  };

  // Render
  return (
    <Row className="pb-5">
      <Colxx md="12" lg="4">
        {/* <Card>
          <CardBody>
            <CardTitle className="mb-4">
              <IntlMessages id="card.find.cpf" />
            </CardTitle>

            <Formik
              initialValues={{
                cpf: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                searchCardByCPF(values, setSubmitting);
              }}
            >
              {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
                <Form className="creditcard-form" onSubmit={handleSubmit}>
                  <Label className="form-group has-top-label mb-4">
                    <Field name="cpf" className="form-control" />
                    <IntlMessages id="card.artviagens.cpf" />
                  </Label>

                  <div className="d-flex justify-content-between align-items-center">
                    <Button
                      type="submit"
                      color="primary"
                      outline
                      size="lg"
                      disabled={isSubmitting}
                    >
                      <IntlMessages id="forms.action.search" />
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card> */}

        <NewCard className="mb-4" />

        <Card>
          <CardBody>
            <CardTitle className="mb-4">
              <IntlMessages id="card.find.id" />
            </CardTitle>

            <Formik
              initialValues={{
                id: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                searchCardByID(values, setSubmitting);
              }}
            >
              {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
                <Form className="creditcard-form" onSubmit={handleSubmit}>
                  <Label className="form-group has-top-label mb-4">
                    <Field name="id" className="form-control" />
                    <IntlMessages id="card.artviagens.id" />
                  </Label>

                  <div className="d-flex justify-content-between align-items-center">
                    <Button
                      type="submit"
                      color="primary"
                      outline
                      size="lg"
                      disabled={isSubmitting}
                    >
                      <IntlMessages id="forms.action.search" />
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>

        <Card className="mt-4">
          <CardBody>
            <CardTitle className="mb-4">
              <IntlMessages id="card.find.bookingid" />
            </CardTitle>

            <Formik
              initialValues={{
                BookingId: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                searchCardByBookingId(values, setSubmitting);
              }}
            >
              {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
                <Form className="creditcard-form" onSubmit={handleSubmit}>
                  <Label className="form-group has-top-label mb-4">
                    <Field name="BookingId" className="form-control" />
                    <IntlMessages id="card.artviagens.id" />
                  </Label>

                  <div className="d-flex justify-content-between align-items-center">
                    <Button
                      type="submit"
                      color="primary"
                      outline
                      size="lg"
                      disabled={isSubmitting}
                    >
                      <IntlMessages id="forms.action.search" />
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Colxx>
      <Colxx md="12" lg="8" className="mb-4">
        {loading && <div className="loading" />}

        {cards.map((card) => {
          return (
            <Card key={`card-${card.id}`} className="mb-4">
              <Badge
                color={
                  card.status === accountsStatus.OK ||
                  card.status === accountsStatus.NEW
                    ? "primary"
                    : card.status === accountsStatus.ACTIVE
                    ? "primary"
                    : card.status === accountsStatus.INSUFFICIENT_FUNDS
                    ? "danger"
                    : card.status === accountsStatus.USED ||
                      card.status === accountsStatus.CANCELLED ||
                      card.status === accountsStatus.UNUSED
                    ? "success"
                    : "primary"
                }
                pill
                className="position-absolute badge-top-left"
                style={{ zIndex: 999 }}
              >
                <IntlMessages id={`account.status.${card.status}`} />
              </Badge>
              <CardBody className="account-item">
                <Row>
                  <Colxx md="6" xs="12">
                    <CardDisplay loading={false} card={card.cardInfo} />
                  </Colxx>
                  <Colxx md="6" xs="12">
                    <div className="mb-4">
                      <IntlMessages id="account.balance" />
                      <br />
                      {stringToMoney(card.balance?.amount / 100 || 0, 2)}
                    </div>

                    <div className="mb-4">
                      <IntlMessages id="account.valid-dates" />
                      <br />
                      {moment(card.activatesAt, "YYYY-MM-DD").format(
                        "DD/MM/YY"
                      )}{" "}
                      -{" "}
                      {moment(card.cancelsAt, "YYYY-MM-DD").format("DD/MM/YY")}
                    </div>

                    <table
                      className="account-item-custom mb-4"
                      style={{ width: "80%" }}
                    >
                      <thead>
                        <tr>
                          <th>Chave</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(card.custom_fields).map((chave, index) => {
                          return (
                            <tr key={`account-${card.id}-fields-${index}`}>
                              <td>{chave}</td>
                              <td>{card.custom_fields[chave]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <AddBalance
                      show={canAddAmount}
                      card={card}
                      environment={environment}
                      onAddBalance={onAddBalance}
                    />
                  </Colxx>
                </Row>
                <Button
                  color="empty"
                  onClick={() => {
                    setDetail(card);
                  }}
                >
                  <i className="simple-icon-options-vertical" />
                </Button>
              </CardBody>
            </Card>
          );
        })}
      </Colxx>

      {detail !== null && (
        <AccountDetail
          accountId={detail.id}
          closeDetails={() => {
            setDetail(null);
          }}
        />
      )}
    </Row>
  );
}
