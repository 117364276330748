import React, { Component } from 'react';
import { Formik } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Button, Alert, InputGroup, Label, FormGroup } from 'reactstrap';
import { Field, ErrorMessage } from 'formik';
import { CSVDownload } from "react-csv";
import moment from 'moment';

import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';

import firebaseApp from '../../services/Firebase';

class ExportAccounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      error: '',
      download: false,
    };
  }

  // Load data
  prepExport = async (values, setSubmitting) => {
    const { user, environment } = this.props;
    const {
      activationStartDate,
      activationEndDate,
      expirationStartDate,
      expirationEndDate,
      accountStatuses
    } = values;

    try {
      this.setState({ error: '', download: false });

      const accountsSnap = await firebaseApp
        .listAccounts({
          organizationId: user.organizationId,
          environment,
          status: accountStatuses
        })
        .get();

      const accounts = [];
      accountsSnap.forEach((accountSnap) => {
        const account = accountSnap.data();
        if (
          moment(account.activatesAt, 'YYYY-MM-DD').isSameOrAfter(moment(activationStartDate, 'YYYY-MM-DD'))
          && moment(account.activatesAt, 'YYYY-MM-DD').isSameOrBefore(moment(activationEndDate, 'YYYY-MM-DD'))
          && moment(account.cancelsAt, 'YYYY-MM-DD').isSameOrAfter(moment(expirationStartDate, 'YYYY-MM-DD'))
          && moment(account.cancelsAt, 'YYYY-MM-DD').isSameOrBefore(moment(expirationEndDate, 'YYYY-MM-DD'))
        ) {
          const custom_fields = {};
          Object.keys(account.custom_fields).forEach((customFieldKey) => {
            custom_fields[customFieldKey.toLowerCase().trim()] = account.custom_fields[customFieldKey];
          });
    
          accounts.push({
            id: accountSnap.id,
            amount: account.amount,
            balance: account.balance?.amount || 0,
            currency: account.currency,
            minPercentageApproval: account.minPercentageApproval,
            maxPercentageApproval: account.maxPercentageApproval,
            activatesAt: account.activatesAt,
            cancelsAt: account.cancelsAt,
            createdAt: moment.unix(account.createdAt).format('YYYY-MM-DD HH:mm'),
            status: account.status,
            ...custom_fields,
          });
        }
      });

      // Retorna erro se não encontrar transações
      if (accounts.length === 0) {
        this.setState({ error: 'Não há cartões para exportar com essas informações.' });
        setSubmitting(false);
        return;
      }

      this.setState({ data: accounts, download: 'csv' });
      setSubmitting(false);

      return;
    } catch (err) {
      console.error(err);

      this.setState({
        error: 'Não foi possível solicitar os cartões nesse momento. Por favor, tente novamente mais tarde.',
      });
      setSubmitting(false);

      return;
    }
  }

  // Render
  render() {
    const { closeModal } = this.props;
    const { error } = this.state;

    return (
      <Modal isOpen={true} toggle={closeModal} wrapClassName="">
        <Formik
          initialValues={{
            activationStartDate: moment().startOf('month').format('YYYY-MM-DD'),
            activationEndDate: moment().endOf('month').format('YYYY-MM-DD'),
            expirationStartDate: moment().startOf('month').format('YYYY-MM-DD'),
            expirationEndDate: moment().add(1, 'month').endOf('month').format('YYYY-MM-DD'),
            accountStatuses: ['NEW', 'OK', 'USED', 'CANCELLED', 'UNUSED', 'ACTIVE', 'INSUFFICIENT_FUNDS'],
          }}
          onSubmit={(values, { setSubmitting }) => {
            this.prepExport(values, setSubmitting);
          }}
        >
          {({ isSubmitting, handleSubmit }) => (
            <Form className="creditcard-form" onSubmit={handleSubmit}>
              <ModalHeader>
                <IntlMessages id="accountsExport.title" />
              </ModalHeader>

              <ModalBody>
                <div>
                  {error && (
                    <Alert color="danger" className="mt-2 mb-2">
                      {error}
                    </Alert>
                  )}

                  <InputGroup>
                    <Label className="form-group has-top-label mb-4">
                      <Field type="date" name="activationStartDate" className="form-control" />
                      <IntlMessages id="accountsExport.activationStart" />
                      <ErrorMessage
                        name="startDate"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                    <Label className="form-group has-top-label mb-4">
                      <Field type="date" name="activationEndDate" className="form-control" />
                      <IntlMessages id="accountsExport.activationEnd" />
                      <ErrorMessage
                        name="endDate"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                  </InputGroup>

                  <InputGroup>
                    <Label className="form-group has-top-label mb-4">
                      <Field type="date" name="expirationStartDate" className="form-control" />
                      <IntlMessages id="accountsExport.expirationStart" />
                      <ErrorMessage
                        name="startDate"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                    <Label className="form-group has-top-label mb-4">
                      <Field type="date" name="expirationEndDate" className="form-control" />
                      <IntlMessages id="accountsExport.expirationEnd" />
                      <ErrorMessage
                        name="endDate"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                  </InputGroup>

                  <FormGroup>
                    <Label>
                      <IntlMessages id="accountsExport.status" />
                    </Label>
                    <div>
                      <Label className="d-block">
                        <Field
                          type="checkbox"
                          name="accountStatuses"
                          value="NEW"
                          className="mr-1"
                        />
                        <IntlMessages id="account.status.NEW" />
                      </Label>
                      <Label className="d-block">
                        <Field
                          type="checkbox"
                          name="accountStatuses"
                          value="OK"
                          className="mr-1"
                        />
                        <IntlMessages id="account.status.OK" />
                      </Label>
                      <Label className="d-block">
                        <Field
                          type="checkbox"
                          name="accountStatuses"
                          value="USED"
                          className="mr-1"
                        />
                        <IntlMessages id="account.status.USED" />
                      </Label>
                      <Label className="d-block">
                        <Field
                          type="checkbox"
                          name="accountStatuses"
                          value="CANCELLED"
                          className="mr-1"
                        />
                        <IntlMessages id="account.status.CANCELLED" />
                      </Label>
                      <Label className="d-block">
                        <Field
                          type="checkbox"
                          name="accountStatuses"
                          value="UNUSED"
                          className="mr-1"
                        />
                        <IntlMessages id="account.status.UNUSED" />
                      </Label>
                      <Label className="d-block">
                        <Field
                          type="checkbox"
                          name="accountStatuses"
                          value="ACTIVE"
                          className="mr-1"
                        />
                        <IntlMessages id="account.status.ACTIVE" />
                      </Label>
                      <Label className="d-block">
                        <Field
                          type="checkbox"
                          name="accountStatuses"
                          value="INSUFFICIENT_FUNDS"
                          className="mr-1"
                        />
                        <IntlMessages id="account.status.INSUFFICIENT_FUNDS" />
                      </Label>
                    </div>
                  </FormGroup>
                </div>

              </ModalBody>

              <ModalFooter>
                <Button
                    type="submit"
                    color="primary"
                    outline
                    size="lg"
                    disabled={isSubmitting}
                  >
                    <IntlMessages id="forms.action.download" />
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>

        {
          this.state.download === 'csv' &&
          <CSVDownload data={this.state.data} target="_blank" />
        }
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth, settings, accounts }) => {
  const { user } = auth;
  const { environment } = settings;
  return { user, environment, accounts: accounts.accounts };
};

export default connect(mapStateToProps)(injectIntl(ExportAccounts));
