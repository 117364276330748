import { createStore } from "redux";
import reducers from "./reducers";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export function configureStore() {
    const store = createStore(reducers, sentryReduxEnhancer);

    if (module.hot) {
        module.hot.accept("./reducers", () => {
            const nextRootReducer = require("./reducers");
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
