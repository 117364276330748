export const getDocumentsFromCollection = async (query, returnSnapToo) => {
  const data = [];

  const snap = await query();

  snap.forEach((docRef) => {
    data.push({
      ...docRef.data(),
      id: docRef.id,
    });
  });

  return returnSnapToo ? { data, snap } : data;
};