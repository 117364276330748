import React, { Component } from 'react';
import { Formik } from 'formik';
import { Modal, Form, Button } from 'reactstrap';
import moment from 'moment';

import { stringToMoney } from '../../utils/money';
import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';

import CardForm from '../Card/form';
import CardReadForm from '../Card/read-form';
import CardAccountSchema from '../../schemas/account';
import TransactionItem from '../Transaction/item';
import CardDisplay from '../Card/display';
import { accountTypeIds } from '../../constants/accounts';

import { connect } from 'react-redux';

import firebaseApp from '../../services/Firebase';
import appFunctions from '../../services/Functions';
import { isStatusFinished } from '../../constants/accounts';

class AccountDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      loadingAccount: true,
      loadingInfo: true,
      loadingTransactions: true,
      transactions: [],
      cardInfo: {},
      account: {},
    };
  }

  componentDidMount() {
    this.loadAccount();
    this.loadTransactions();
    this.loadCardInfo();
  }

  // Load Account
  loadAccount = async () => {
    const { messages } = this.props.intl;
    const { user, accountId } = this.props;

    try {
      const accountSnap = await firebaseApp.getAccountsFromId({
        organizationId: user.organizationId,
        accountId
      }).get();

      let account = accountSnap.data();
      account.id = accountSnap.id;

      this.setState({
        loadingAccount: false,
        account
      })
    } catch (err) {
      console.error(err);
      this.setState({
        error: `${messages['user.GeneralException']} ${err.message}`,
      });
    }
  }

  // Get Card Info
  loadCardInfo = async () => {
    const { messages } = this.props.intl;
    const { environment, accountId } = this.props;

    try {
      const cardInfo = await appFunctions.retrieveCard({
        environment,
        accountId
      });

      this.setState({
        loadingInfo: false,
        cardInfo
      })
    } catch (err) {
      console.error(err);
      this.setState({
        error: `${messages['user.GeneralException']} ${err.message}`,
      });
    }
  }

  // Get Transactions
  loadTransactions = async () => {
    const { user, accountId } = this.props;

    const transactionsSnap = await firebaseApp.listAccountTransactions({
        organizationId: user.organizationId,
        accountId
      }).get();
    
    const transactions = [];
    transactionsSnap.forEach((transactionSnap) => {
      transactions.push({
        id: transactionSnap.id,
        ...transactionSnap.data(),
      });
    });

    this.setState({ transactions, loadingTransactions: false });
  }

  // Delete Card
  onDeleteCard = async () => {
    const { messages } = this.props.intl;
    const { environment, accountId } = this.props;

    try {
      await appFunctions.deleteCard({
        environment,
        accountId
      });

      this.props.closeDetails();
    } catch (err) {
      this.setState({
        error: `${messages['user.GeneralException']} ${err.message}`,
      });
    }
  }

  // Edit Card
  onEditCard = async (values, setSubmitting) => {
    const { messages } = this.props.intl;
    const { environment, accountId } = this.props;
    const { amount, activatesAt, cancelsAt, custom_fields, type } = values;

    try {
      await appFunctions.editCard({
        environment,
        accountId: accountId,
        amount: (amount * 100).toFixed(0),
        type,
        activatesAt,
        cancelsAt,
        custom_fields: custom_fields.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.value }),
          {}
        ),
      });

      setSubmitting(false);

      this.props.closeDetails();
    } catch (err) {
      setSubmitting(false);

      this.setState({
        error: `${messages['user.GeneralException']} ${err.message}`,
      });
    }
  }

  // Render
  render() {
    const { messages } = this.props.intl;
    const { closeDetails, error } = this.props;
    const { transactions, loadingInfo, cardInfo, account, loadingAccount } = this.state;

    if (loadingAccount) {
      return (
        <Modal isOpen={true} toggle={closeDetails} wrapClassName="modal-right account-details">
          <div className="loading" />
        </Modal>
      )
    }

    return (
      <Modal isOpen={true} toggle={closeDetails} wrapClassName="modal-right account-details">
        <h3><IntlMessages id="card.details" /></h3>

        <CardDisplay card={cardInfo} loading={loadingInfo} />

        {
          isStatusFinished(account.status) ?
            <div className="creditcard-form">
              <CardReadForm values={{
                amount: account.amount,
                activatesAt: account.activatesAt,
                cancelsAt: account.cancelsAt,
                custom_fields: Object.keys(account.custom_fields).map((key, index) => {
                  return {
                    key,
                    value: account.custom_fields[key]
                  }
                }),
              }} />
            </div>
          :
          <div>
            <Formik
              initialValues={{
                amount: (account.amount / 100),
                activatesAt: account.activatesAt,
                cancelsAt: account.cancelsAt,
                custom_fields: Object.keys(account.custom_fields).map((key, index) => {
                  return {
                    key,
                    value: account.custom_fields[key]
                  }
                }),
                type: accountTypeIds[account.balance_account_id] || 1,
              }}
              validationSchema={CardAccountSchema(messages)}
              onSubmit={(values, { setSubmitting }) => {
                this.onEditCard(values, setSubmitting);
              }}
            >
              {({ isSubmitting, values, setFieldValue, handleSubmit }) => (
                <Form className="creditcard-form" onSubmit={handleSubmit}>
                  <CardForm values={values} setFieldValue={setFieldValue} error={error} messages={messages} />
                  
                  <div className="text-right mb-4">
                    <Button
                      onClick={this.onDeleteCard}
                      color="link"
                      size="sm"
                      disabled={isSubmitting}
                    >
                      <IntlMessages id="forms.action.delete" />
                    </Button>

                    <Button
                      type="submit"
                      color="primary"
                      outline
                      size="lg"
                      disabled={isSubmitting}
                    >
                      <IntlMessages id="forms.action.edit" />
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        }

        <p className="text-small text-right">ID do cartão: {cardInfo.id}</p>
        
        <h3>
          <IntlMessages id="card.transactions" />
        </h3>

        {
          cardInfo.balance > 0 &&
          <div className="m-1 mb-2 transaction-item">
            <h3 className="float-right mr-3">{stringToMoney(cardInfo.balance / 100, 2)}</h3>
            <h3 className="mb-0 pb-1">Saldo disponível</h3>
            <p className="text-muted text-small mb-0 font-weight-light">{moment().format('DD/MM/YYYY HH:mm')}</p>
          </div>
        }

        {
          transactions.map((transaction, i) => {
            return <TransactionItem transaction={transaction} key={`transaction-${i}`} />
          })
        }
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { user } = auth;
  const { environment } = settings;
  return { user, environment };
};

export default connect(mapStateToProps)(injectIntl(AccountDetail));
