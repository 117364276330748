import React from 'react';
import { Badge } from 'reactstrap';

import { responseCodes } from '../../constants/accounts';

export default function TransactionStatus ({ transaction }) {
  return (
    <Badge
      color={responseCodes[transaction.response_code]?.approved ? "success" : "danger"}
      pill
      className="position-absolute badge-top-left">
      {responseCodes[transaction.response_code]?.name || transaction.response_code}
    </Badge>
  );
}