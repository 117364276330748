import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import './services/Firebase';
import AppLocale from './lang';
import main from './views';
import app from './views/app';
import artviagens from './views/artviagens';
import user from './views/user';
import webhook from './views/webhook';
import error from './views/error';

import {
  setAuth,
  setUser,
  setOrganization,
  logoutUser,
} from './redux/auth/actions';

import {
  changeEnvironment,
  setFixedEnvironment,
} from './redux/settings/actions';

import {
  clearAccounts,
} from './redux/accounts/actions';

import firebaseApp from './services/Firebase';
import appFunctions from './services/Functions';

// Error
import * as Sentry from '@sentry/react';
import { identifySentry, clearSentry } from './utils/sentry';
import ErrorBoundary from './ErrorBoundary';

// Analytics
import { identifySegment, clearSegment } from './utils/segment';

// Datepicker
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
require('moment/locale/pt-br.js');

registerLocale('pt', ptBR);
moment.locale('pt-br');

const AuthRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/user/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);

    this.unsub = () => {};
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.authenticateUser();
  }

  // Auth
  getUserData = async () => {
    const user = await appFunctions.getUser();
    const organization = await appFunctions.getOrganization();

    this.props.setUser(user);
    this.props.setOrganization(organization);

    if (user.fixed_environment) {
      this.props.changeEnvironment(user.environment);
      this.props.setFixedEnvironment(true);
    } else {
      this.props.setFixedEnvironment(false);
    }

    identifySegment(user.uid, {
      email: user.email,
      username: user.email,
      firstName: user.name,
      company: {
        name: organization.organization?.trading_name,
        id: organization.id,
      },
    });

    identifySentry({
      id: user.uid,
      username: user.email,
      email: user.email,
      firstName: user.name,
      company: organization.organization?.trading_name,
    });
    
    this.setState({ loading: false });
  };

  authenticateUser = async () => {
    firebaseApp.auth().onAuthStateChanged(async (user) => {
      if (user != null) {
        this.props.setAuth(user);
        this.getUserData();
      } else {
        clearSegment();
        clearSentry();

        if (this.unsub) {
          this.unsub();
        }

        this.props.logoutUser();
        this.props.clearAccounts();
        this.setState({ loading: false });
      }
    });

    return null;
  };

  // Render
  render() {
    const { loading } = this.state;
    const { locale, authUser } = this.props;
    const currentAppLocale = AppLocale[locale];

    if (loading) {
      return <div className="loading" />;
    }

    // Locale for Datepicker
    setDefaultLocale(locale);

    return (
      <div className="h-100">
        <Sentry.ErrorBoundary fallback={ErrorBoundary}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <React.Fragment>
              <Router>
                <Switch>
                  <AuthRoute
                    path="/dashboard"
                    authUser={authUser}
                    component={app}
                  />
                  <AuthRoute
                    path="/artviagens"
                    authUser={authUser}
                    component={artviagens}
                  />
                  <AuthRoute
                    path="/123milhas"
                    authUser={authUser}
                    component={artviagens}
                  />
                  <Route path="/webhook" component={webhook} />
                  <Route path="/user" component={user} />
                  <Route path="/error" exact component={error} />
                  <Route path="/" exact component={main} />
                  <Redirect to="/error" />
                </Switch>
              </Router>
            </React.Fragment>
          </IntlProvider>
        </Sentry.ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings, accounts }) => {
  const { user } = auth;
  const { environment, locale } = settings;
  const { startDate, endDate } = accounts;
  return { user, environment, locale, authUser: auth.auth, startDate, endDate };
};

export default connect(mapStateToProps, {
  setAuth,
  setUser,
  setOrganization,
  logoutUser,
  changeEnvironment,
  setFixedEnvironment,
  clearAccounts,
})(App);
