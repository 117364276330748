module.exports = {
  /* 01.General */
  'general.copyright': 'Bank 3 © 2021 Todos os direitos reservados.',

  /* 02.User Login, Logout, Register */
  'user.simple': 'Simples',
  'user.inteligent': 'Inteligente',
  'user.direct': 'Eficaz',
  'user.login': 'Faça login',
  'user.login.page': 'Login',
  'user.newPassword': 'Sua senha expirou, crie uma nova para continuar',
  'user.setupMFA': 'Adicione mais segurança a sua conta',
  'user.setupMFA_desc':
    'Autenticação em duas etapas é uma prática simples que adiciona uma camada extra de proteção ao seu usuário e senha. Leia o QR Code com um aplicativo apropriado.',
  'user.username': 'E-mail ou usuário',
  'user.password': 'Digite sua senha',
  'user.reset_code': 'Código de verificação',
  'user.mfa_code': 'Código do Token',
  'user.new_password': 'Nova senha',
  'user.new_password_confirm': 'Confirme a senha',
  'user.forgot-password-question': 'Esqueceu a senha?',
  'user.forgot-password': 'Esqueci a senha',
  'user.forgot-password_desc': 'Crie uma nova senha para a sua conta.',
  'user.authMFA': 'Código de Verificação',
  'user.authMFA_desc':
    'Insira o código de verificação configurado em seu aplicativo.',
  'user.forgot-password-request-success':
    'Um link para troca de senha foi enviado para o seu email.',
  'user.forgot-password-request-error':
    'O link utilizado não é mais válido. Por favor, tente solicitar o reset novamente.',

  'user.new-password': 'Seja bem vindo a Bank 3!',
  'user.new-password_desc':
    'Crie uma nova senha para começar a usar a plataforma',

  'user.CodeMismatchException':
    'Código de validação inválido. Por favor verifique o código que te enviamos por email e tente novamente.',
  'user.EnableSoftwareTokenMFAException':
    'Código do Token inválido. Por favor, tente novamente.',
  'user.NotAuthorizedException':
    'Senha incorreta. Tente novamente ou clique em "Esqueceu a senha?" para redefini-la.',
  'user.UserNotFoundException':
    'Não foi possível encontrar a sua conta na Bank 3.',
  'user.UserDisabledException':
    'Usuário bloqueado. Contate o suporte para mais informações.',
  'user.LimitExceededException':
    'Tentativas de reset excedidas. Por favor tente novamente mais tarde.',
  'user.InvalidParameterException':
    'Sua nova senha é muito simples. Insira uma senha mais complexa para continuar.',
  'user.WeakPasswordException': 'Senha deve ser mais complexa',
  'user.ExpiredCodeException':
    'Código de verificação expirou. Por favor solicite novamente.',
  'user.GeneralException':
    'Um erro interno ocorreu. Nosso time já foi avisado.',

  'user.name': 'Nome',
  'user.firstName': 'Nome',
  'user.lastName': 'Sobrenome',
  'user.gender': 'Gênero',
  'user.gender.MALE': 'Masculino',
  'user.gender.FEMALE': 'Feminino',
  'user.birthdate': 'Data de nascimento',
  'user.email': 'Email',
  'user.role': 'Cargo',
  'user.companyId': 'Matrícula',
  'user.costCenter': 'Centro de Custo',
  'user.project': 'Projeto',
  'user.rgNumber': 'Identidade',
  'user.rgEmitter': 'Órgão Emissor',
  'user.cpf': 'CPF',
  'user.cnhNumber': 'CNH',
  'user.chnValidUntil': 'Data de validade',
  'user.passportCountry': 'País emissor do passaporte',
  'user.passportNumber': 'Número',
  'user.passportValidUntil': 'Data de validade',

  /* 3.List Cards */
  'list-cards.title': 'Cartões criados',
  'list-cards.subtitle': 'Acompanhe todos os seus cartões criados',
  'list-cards.status-filter': 'Status selecionados',
  'list-cards.no-cards': 'Nenhum cartão encontrado',
  'balance-cards.available.title': 'Saldo disponível',
  'balance-cards.blocked.title': 'Saldo ativo em cartões',
  'balance-cards.next.title': 'Necessário para novos cartões',
  'account.period.7': 'Próximos 7 dias',
  'account.period.15': 'Próximos 15 dias',
  'account.period.30': 'Próximos 30 dias',
  'account.period.all': 'Todos os cartões',
  'account.search': 'Buscar',
  'account.startDate': 'Data de Início',
  'account.endDate': 'Data de Fim',
  'account.balance': 'SALDO DISPONÍVEL',

  /* 3.List Transactions */
  'list-transactions.title': 'Transações em tempo real',
  'list-transactions.subtitle': 'Acompanhe as suas transações em tempo real',
  'list-transactions.no-cards': 'Nenhum cartão encontrado',
  'list-transactions.status-filter': 'Status',
  'transaction.status.approved': 'Aprovado',
  'transaction.status.denied': 'Negado',
  'transaction.status.refund': 'Restituição',

  /* 4.Notifications */
  'notifications.alert.INSUFFICIENT_FUNDS':
    'Um cartão não pode ser ativo por falta de fundos',
  'notifications.alert.FUNDS_IN_SUCCESSFUL':
    'Nova transferência realizada com sucesso!',

  /* 07.10.Forms */
  'forms.action.skip': 'Pular',
  'forms.action.have_code': 'Já tenho um código',
  'forms.action.register': 'REGISTRAR',
  'forms.action.login': 'LOGIN',
  'forms.action.reset': 'RESETAR',
  'forms.action.change': 'ALTERAR',
  'forms.action.save': 'SALVAR',
  'forms.action.invite': 'CONVIDAR',
  'forms.action.create': 'CRIAR',
  'forms.action.edit': 'EDITAR',
  'forms.action.delete': 'excluir',
  'forms.action.download': 'Fazer download',
  'forms.action.search': 'Buscar',

  /* 07.10.Forms Validation */
  'forms.validation.email.valid': 'Email não é válido',
  'forms.validation.email.required': 'Email obrigatório',
  'forms.validation.password.required': 'Senha obrigatória',
  'forms.validation.password.weak': 'Senha deve conter ao menos 6 caracteres',
  'forms.validation.password.confirmation.required':
    'Confirmação não é igual a senha',
  'forms.validation.code.required': 'Código obrigatório',
  'forms.validation.firstName.required': 'Nome obrigatório',
  'forms.validation.lastName.required': 'Sobrenome obrigatório',
  'forms.validation.gender.valid': 'Gênero inválido',
  'forms.validation.gender.required': 'Gênero obrigatório',
  'forms.validation.cpf.required': 'CPF obrigatório',
  'forms.validation.cpf.valid': 'CPF inválido',
  'forms.validation.birthdate.valid': 'Data de nascimento inválida',
  'forms.validation.birthdate.required': 'Data de nascimento obrigatório',
  'forms.validation.rg.number.required': 'Identidade obrigatória',
  'forms.validation.rg.emitter.required': 'Órgão Emissão obrigatório',
  'forms.validation.cnh.validUntil.valid': 'Data inválida',
  'forms.validation.passport.validUntil.valid': 'Data inválida',
  'forms.validation.name.required': 'Nome obrigatório',
  'forms.validation.cardName.required': 'Nome obrigatório',
  'forms.validation.documentNumber.required': 'Documento obrigatório',
  'forms.validation.cardNumber.required': 'Número do Cartão obrigatório',
  'forms.validation.cardExpiry.valid': 'Data de Expiração inválida',
  'forms.validation.cardExpiry.required': 'Data de Expiração obrigatória',
  'forms.validation.cvv.valid': 'CVV inválido',
  'forms.validation.cvv.required': 'CVV obrigatório',

  'forms.validation.amount.required': 'Valor obrigatório',
  'forms.validation.amount.min': 'Valor não permitido',
  'forms.validation.amount.max': 'Valor não permitido',
  'forms.validation.date.required': 'Data obrigatória',
  'forms.validation.date.greaterToday': 'Deve ser hoje ou depois',
  'forms.validation.date.greaterThanActivation':
    'Deve ser no mesmo dia ou depois da data de ativação',
  'forms.validation.customFields.required': 'Preencha ou delete esse parâmetro',

  'forms.validation.environment.required': 'Ambiente obrigatório',

  /* 08. Cards */
  'card.create': 'Novo cartão',
  'card.amount': 'Valor',

  'card.type': 'Tipo de transação',
  'card.type.DEFAULT': 'Padrão',
  'card.type.HOTEL': 'Apenas Hotéis',
  'card.type.FLIGHT': 'Apenas Cia Aérea',
  'card.type.NATIONAL': 'Livre Nacional',
  'card.type.INTERNATIONAL': 'Livre Internacional',

  'card.activatesAt': 'Data de ativação',
  'card.cancelsAt': 'Data de expiração',
  'card.createanoter': 'Criar outro cartão',
  'card.created':
    'Seu cartão de crédito virtual Bank 3 foi gerado com sucesso!',
  'card.valid-untill': 'VALIDO ATÉ',
  'card.cvv': 'CVV',
  'card.more-options': 'Mais opções',
  'card.details': 'Detalhes do cartão',
  'card.transactions': 'Transações',
  'card.find.cpf': 'Localizar cartão pelo CPF',
  'card.find.id': 'Localizar cartão pelo ID',
  'card.find.bookingid': 'Localizar cartão pelo Booking ID',

  'account.status.NEW': 'Novo',
  'account.status.OK': 'Pronto',
  'account.status.USED': 'Usado',
  'account.status.CANCELLED': 'Cancelado',
  'account.status.UNUSED': 'Expirado',
  'account.status.ACTIVE': 'Ativo',
  'account.status.INSUFFICIENT_FUNDS': 'Saldo Insuficiente',
  'account.amount': 'Valor',
  'account.valid-dates': 'PERÍODO VÁLIDO',
  'transaction.mwrchant': 'DADOS DA TRANSAÇÃO',
  'transactionsExport.title': 'Exportar transações',
  'transactionsExport.startDay': 'Período inicial',
  'transactionsExport.endDay': 'Período final',
  'transactionsExport.transactionStatus': 'Status da transação',
  'transactionsExport.transactionStatus.approved': 'Apenas transações aprovadas',
  'transactionsExport.transactionStatus.denied': 'Apenas transações negadas',
  'transactionsExport.transactionStatus.all': 'Todas as transações',
  'transactionsExport.format': 'Formato do arquivo',
  'transactionsExport.format.CSV': 'CSV (Excel)',
  'transactionsExport.format.PDF': 'PDF',
  'transactionsExport.format.OFX': 'OFX',

  'accountsExport.title': 'Exportar cartões',
  'accountsExport.status': 'Status dos cartões',
  'accountsExport.activationStart': 'Ativação desde',
  'accountsExport.activationEnd': 'Ativação até',
  'accountsExport.expirationStart': 'Expiração desde',
  'accountsExport.expirationEnd': 'Expiração até',

  /* 08. Acessos */
  'users.list': 'Usuários',
  'users.create': 'Convidar novo usuário',
  'apikey.list': 'API Keys',
  'apikey.create': 'Criar API Key',
  'apikey.name': 'Nome',
  'apikey.environment': 'Ambiente',

  /* 09. Art Viagens */
  'card.artviagens.cpf': 'CPF',
  'card.artviagens.id': 'ID',
  'card.artviagens.addAmount': 'Adicionar Saldo',
  'forms.action.activateAndAddAmount': 'Ativar',
};
