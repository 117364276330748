import React, { Component } from "react";
import ContentLoader from "react-content-loader";

import { injectIntl } from "react-intl";

class AccountsLoading extends Component {
    render() {
        return (
          <ContentLoader 
            speed={2}
            width={600}
            height={100}
            viewBox="0 0 600 100"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="9" y="10" rx="3" ry="3" width="50" height="6" /> 
            <rect x="10" y="20" rx="3" ry="3" width="88" height="12" /> 
            <rect x="10" y="50" rx="3" ry="3" width="50" height="6" /> 
            <rect x="10" y="60" rx="3" ry="3" width="88" height="12" /> 
            <rect x="170" y="10" rx="3" ry="3" width="50" height="6" /> 
            <rect x="169" y="20" rx="3" ry="3" width="88" height="12" /> 
            <rect x="329" y="10" rx="3" ry="3" width="250" height="22" /> 
            <rect x="329" y="36" rx="3" ry="3" width="88" height="12" /> 
            <rect x="329" y="52" rx="3" ry="3" width="88" height="12" /> 
            <rect x="329" y="68" rx="3" ry="3" width="88" height="12" /> 
            <rect x="460" y="36" rx="3" ry="3" width="88" height="12" /> 
            <rect x="460" y="52" rx="3" ry="3" width="88" height="12" /> 
            <rect x="460" y="68" rx="3" ry="3" width="88" height="12" />
          </ContentLoader>
        );
    }
}

export default injectIntl(AccountsLoading);
