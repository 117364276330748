import React from 'react';
import { Label, Input, InputGroup } from 'reactstrap';
import NumberFormat from 'react-number-format';

import IntlMessages from '../../helpers/IntlMessages';

export default function CardReadForm ({ values }) {
  return (
    <div>
      <Label className="form-group has-top-label mb-4">
        <NumberFormat
          prefix={'R$ '}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale="2"
          fixedDecimalScale="2"
          className="form-control"
          disabled={true}
          value={values.amount / 100 || ''}
        />
        <IntlMessages id="card.amount" />
      </Label>
      
      <InputGroup>
        <Label className="form-group has-top-label mb-4">
          <Input disabled={true} type="date" value={values.activatesAt} />
          <IntlMessages id="card.activatesAt" />
        </Label>
        <Label className="form-group has-top-label mb-4">
          <Input disabled={true} type="date" value={values.cancelsAt} />
          <IntlMessages id="card.cancelsAt" />
        </Label>
      </InputGroup>

      <div className="border mb-4 pl-4 pr-4 pb-4 pt-2">
        <div className="custom_field">
          <div className="custom_field-title mb-2">
            Parâmetros Customizáveis
          </div>
          <div className="custom_field-title">
            <div>Chave</div>
            <div>Identificador</div>
          </div>
            {values.custom_fields.map((custom_field, index) => (
              <div className="custom_field-item" key={index}>
                <Label className="form-group has-top-label">
                  <Input disabled={true} value={custom_field.key} />
                </Label>
                <Label className="form-group has-top-label">
                  <Input disabled={true} value={custom_field.value} />
                </Label>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}