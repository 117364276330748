import React from 'react';

const Logo = () => {
  return (
    <div className="position-relative d-inline-block logo">
      <img src="/assets/img/logo-black.svg" alt="Bank 3" />
    </div>
  );
};

export default Logo;
