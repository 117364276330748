import React, { Component } from 'react';
import { CardTitle } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import IntlMessages from '../../helpers/IntlMessages';
import { injectIntl } from 'react-intl';

class ErrorReset extends Component {
  render() {
    return (
      <div>
        <NavLink to="/user/login" className="d-block mb-4">
          <i className="iconsminds-arrow-out-left" />
          <IntlMessages id="user.login.page" />
        </NavLink>

        <CardTitle className="mb-4">
          <IntlMessages id="user.forgot-password" />
        </CardTitle>

        <p>{this.props.error}</p>
      </div>
    );
  }
}

export default injectIntl(ErrorReset);
