const accountsStatus = {
  NEW: 'NEW',
  OK: 'OK',
  USED: 'USED',
  CANCELLED: 'CANCELLED',
  UNUSED: 'UNUSED',
  ACTIVE: 'ACTIVE',
  INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
};

exports.accountsStatus = accountsStatus;

/**
 * Verifica se é um status é de cartão permanentemente finalizado.
 * 
 * @param {string} status Status do cartão
 * @returns {boolean} Se a transação é de sucesso.
 */
exports.isStatusFinished = (status) => {
  return status === accountsStatus.CANCELLED || status === accountsStatus.USED || status === accountsStatus.UNUSED;
}

exports.accountsStatus = {
  'NEW': 'NEW',
  'OK': 'OK',
  'USED': 'USED',
  'CANCELLED': 'CANCELLED',
  'UNUSED': 'UNUSED',
  'ACTIVE': 'ACTIVE',
  'INSUFFICIENT_FUNDS': 'INSUFFICIENT_FUNDS'
};

exports.accountTypeIds = {
  1: 'DEFAULT',
  2: 'HOTEL',
  3: 'FLIGHT',
  4: 'NATIONAL',
  5: 'INTERNATIONAL',
  6: 'ADS',
  7: 'SAAS',
  8: 'MOBILITY',
}

const transactionTypes = {
  0: "Goods Purchase",
  1: "Cash Withdrawal",
  2: "Balance Enquiry",
  3: "PIN Change",
  4: "PIN Activation",
  5: "Financial Transfer",
  6: "Reconciliation Advice",
  7: "Sign On",
  8: "Sign Off",
  9: "Cutover",
  10: "Key Exchange",
  11: "Echo test",
  12: "Credit",
  13: "Debit",
  14: "Return",
  15: "Reservation",
  16: "Cash Advance",
  17: "Online Authorization",
  18: "Purchase Forced Acceptance",
  19: "Purchase Phone Ordered",
  20: "Authorisation Purchase",
  21: "Purchase Mail Ordered",
  22: "Recurring",
  23: "Purchase with Cashback",
  24: "Card Value Load",
  25: "Pre-Authorisation",
  26: "Online Advice",
  27: "Reversal",
  28: "Reservation Cancel",
  29: "Reservation Adjustment",
  30: "Confirm Phone Reservation",
  31: "Preauthorisation Extension",
  32: "Preauthorisation Adjustment",
  33: "Preauthorisation Capture",
  34: "Data Exchange",
  35: "Offline Advice",
  36: "Account Funding",
  37: "Quasi-Cash",
  38: "Eligibility Inquiry",
  39: "Account Transfer",
  40: "PIN Unblock",
  41: "Cheque Guarantee",
  42: "AFT",
  43: "Payment Request",
  44: "Account Verification",
  45: "Token Authorisation Request",
  46: "Token Eligibility Request",
  47: "Token Activation Code Notification",
  48: "Token Completion Notification",
  49: "Token Event Notification",
  50: "ATM",
  51: "Script Issue",
  52: "Deposit",
  53: "Cheque Deposit",
  54: "Original Credit",
  55: "E-Commerce",
  56: "ATC Update",
  57: "Session Up",
  58: "Session Down",
  63: "Balance Enquiry with Fees",
};

exports.transactionTypes = transactionTypes;

const merchantCodes = {
  "3412": { name: "A-1 RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3374": { name: "ACCENT RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "8931": { name: "ACCOUNTING, AUDITING, AND BOOKKEEPING SERVICES", group: "Serviços Profissionais" },
  "3354": { name: "ACTION AUTO RENTAL", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3681": { name: "ADAMS MARK HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3064": { name: "ADRIA AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "7311": { name: "ADVERTISING SERVICES", group: "Serviços Profissionais" },
  "3043": { name: "AER LINGUS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3287": { name: "AERO COACH AVIATION", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3263": { name: "AERO SERVICO CARABOBO", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3284": { name: "AERO VIRGIN ISLANDS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3167": { name: "AEROCONTINENTE", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3011": { name: "AEROFLOT", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3030": { name: "AEROLINEAS ARGENTINAS", group: "T&E Companhia aérea", icon: "logos/aerolineas.svg" },
  "3286": { name: "AEROLINEAS NICARAGUENSIS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3076": { name: "AEROMEXICO", group: "T&E Companhia aérea", icon: "logos/aeromexico.svg" },
  "3285": { name: "AEROPERU", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3351": { name: "AFFILIATED AUTO RENTAL", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3364": { name: "AGENCT RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "763": { name: "AGRICULTURAL CO-OPERATIVES", group: "Serviços Profissionais" },
  "3083": { name: "AIR AFRIQUE", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3021": { name: "AIR ALGERIE", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3236": { name: "AIR ARABIA AIRLINE", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3068": { name: "AIR ASTANA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3296": { name: "AIR BERLIN", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3071": { name: "AIR BRITISH COLUMBIA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3009": { name: "AIR CANADA", group: "T&E Companhia aérea", icon: "logos/aircanada.svg" },
  "3261": { name: "AIR CHINA", group: "T&E Companhia aérea", icon: "logos/airchina.svg" },
  "7623": { name: "AIR CONDITIONING REPAIR SHOPS, REFRIGERATION REPAIR SHOPS", group: "Consertos em Geral" },
  "3282": { name: "AIR DJIBOUTI", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3007": { name: "AIR FRANCE", group: "T&E Companhia aérea", icon: "logos/airfrance.svg" },
  "3280": { name: "AIR JAMAICA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3044": { name: "AIR LANKA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3148": { name: "AIR LITTORAL S.A.", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3028": { name: "AIR MALTA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3298": { name: "AIR MAURITIUS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3025": { name: "AIR NEW ZEALAND", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3267": { name: "AIR PANAMA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3266": { name: "AIR SEYCHELLES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3151": { name: "AIR ZAIRE", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3096": { name: "AIR ZIMBABWE", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3020": { name: "AIR-INDIA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3065": { name: "AIRINTER", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "4511": { name: "AIRLINES AND AIR CARRIERS", group: "T&E", icon: "icons/airline.svg" },
  "4582": { name: "AIRPORTS, FLYING FIELDS, AND AIRPORT TERMINALS", group: "Transporte" },
  "3177": { name: "AIRTRAN AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3361": { name: "AIRWAYS RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3376": { name: "AJAX RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3788": { name: "ALA MOANA HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3560": { name: "ALADDIN RESORT AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3387": { name: "ALAMO RENT-A-CAR", group: "T&E Aluguel de carro", icon: "logos/alamo.svg" },
  "3256": { name: "ALASKA AIRLINES", group: "T&E Companhia aérea", icon: "logos/alaska.svg" },
  "3013": { name: "ALITALIA", group: "T&E Companhia aérea", icon: "logos/alitalia.svg" },
  "3161": { name: "ALL NIPPON AIRWAYS", group: "T&E Companhia aérea", icon: "logos/ana.svg" },
  "3421": { name: "ALLSTATE RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3252": { name: "ALM", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3619": { name: "ALOFT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3251": { name: "ALOHA AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3362": { name: "ALTRA AUTO RENTAL", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "4119": { name: "AMBULANCE SERVICES", group: "Assistência médica e odontológica" },
  "3754": { name: "AMELIA ISLAND PLANTATION", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3253": { name: "AMERICA WEST", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3001": { name: "AMERICAN AIRLINES", group: "T&E Companhia aérea", icon: "logos/american.svg" },
  "3352": { name: "AMERICAN INTL RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3259": { name: "AMERICAN TRANS AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3517": { name: "AMERICANA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3617": { name: "AMERICA'S BEST VALUE INN", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3614": { name: "AMERICINN", group: "T&E Hotel", icon: "logos/americinn.svg" },
  "3514": { name: "AMERISUITES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3536": { name: "AMFAC HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7996": { name: "AMUSEMENT PARKS, CIRCUSES, CARNIVALS, AND FORTUNE TELLERS", group: "Entretenimento" },
  "3537": { name: "ANA HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3420": { name: "ANSA INTL RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3033": { name: "ANSETT AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5932": { name: "ANTIQUE SHOPS - SALES, REPAIRS, AND RESTORATION SERVICES", group: "Antigos / Peão Lojas" },
  "5937": { name: "ANTIQUES REPRODUCTIONS", group: "Antigos / Peão Lojas" },
  "7629": { name: "APPLIANCE REPAIR SHOPS, ELECTRONIC REPAIR SHOPS, REPAIR SHOPS - ELECTRICAL AND SMALL APPLIANCE", group: "Consertos em Geral" },
  "5722": { name: "APPLIANCE STORES, NOT ELSEWHERE CLASSIFIED, HOUSEHOLD APPLIANCE STORES", group: "Móveis" },
  "7998": { name: "AQUARIUMS, SEAQUARIUMS, DOLPHINARIUMS", group: "Entretenimento" },
  "3511": { name: "ARABELLA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3670": { name: "ARCADE HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "8911": { name: "ARCHITECTURAL, ENGINEERING, AND SURVEYING SERVICES", group: "Serviços Profissionais" },
  "3671": { name: "ARCTIA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3824": { name: "ARIA", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3594": { name: "ARIZONA BILTMORE", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5971": { name: "ART DEALERS AND GALLERIES", group: "Representantes e Galerias de Arte" },
  "5970": { name: "ARTIST S SUPPLY AND CRAFTS SHOPS", group: "Arte / Artesanato / Passatempo" },
  "3098": { name: "ASIANA AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3797": { name: "ATLANTIC CITY HILTON RESORTS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3549": { name: "AUBERGE DES GOVERNEURS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3243": { name: "AUSTRIAN AIR SERVICE", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3051": { name: "AUSTRIAN AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5531": { name: "Auto and Home Supply Stores", group: "Automotivo" },
  "3400": { name: "AUTO HOST CAR RENTALS", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3826": { name: "Autograph", group: "T&E Hotel", icon: "logos/autograph.svg" },
  "3436": { name: "AUTOHANSA RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3425": { name: "AUTOMATE RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "5542": { name: "AUTOMATED FUEL DISPENSERS", group: "Relacionados a Automotivo" },
  "8675": { name: "AUTOMOBILE ASSOCIATIONS", group: "Associação" },
  "7512": { name: "AUTOMOBILE RENTAL AGENCY", group: "T&E", icon: "icons/rental.svg" },
  "7531": { name: "AUTOMOTIVE BODY REPAIR SERVICES", group: "Consertos em Geral" },
  "7535": { name: "AUTOMOTIVE PAINT SHOPS, PAINT SHOPS - AUTOMOTIVE", group: "Relacionados a Automotivo" },
  "5533": { name: "AUTOMOTIVE PARTS AND ACCESSORIES STORES", group: "Relacionados a Automotivo" },
  "7538": { name: "AUTOMOTIVE SERVICE SHOPS (NON-DEALER)", group: "Relacionados a Automotivo" },
  "5532": { name: "AUTOMOTIVE TIRE STORES", group: "Relacionados a Automotivo" },
  "3423": { name: "AVCAR RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3242": { name: "AVENSA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3053": { name: "AVIACO (SPAIN)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3039": { name: "AVIANCA", group: "T&E Companhia aérea", icon: "logos/avianca.svg" },
  "3241": { name: "AVIATECA (GUATEMALA)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3389": { name: "AVIS RENT-A-CAR", group: "T&E Aluguel de carro", icon: "logos/avis.svg" },
  "3427": { name: "AVON RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3240": { name: "BAHAMASAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "9223": { name: "BAIL AND BOND PAYMENTS", group: "Serviços Jurídicos" },
  "5462": { name: "BAKERIES", group: "Alimentos / Bebidas", icon: "icons/food.svg" },
  "3041": { name: "BALKAN-BULGARIAN AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3728": { name: "BALLY'S HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7929": { name: "BANDS, ORCHESTRAS, AND MISCELLANEOUS ENTERTAINERS, NOT ELSEWHERE CLASSIFIED", group: "Entretenimento" },
  "3239": { name: "BAR HARBOR AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3556": { name: "BARTON CREEK RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3834": { name: "Baymont Inn & Suites", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3764": { name: "BEAU RIVAGE HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7230": { name: "BEAUTY AND BARBER SHOPS", group: "Serviços pessoais" },
  "3765": { name: "BELLAGIO", group: "T&E Hotel", icon: "icons/bellagio.svg" },
  "3238": { name: "BEMIDJI AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3502": { name: "BEST WESTERN HOTELS", group: "T&E Hotel", icon: "icons/bestwestern.svg" },
  "7995": { name: "BETTING, INCLUDING LOTTERY TICKETS, CASINO GAMING CHIPS, OFF-TRACK BETTING, AND WAGERS AT RACE TRACKS", group: "Recreativo" },
  "5940": { name: "BICYCLE SHOPS - SALES AND SERVICE", group: "Recreativo" },
  "7932": { name: "BILLIARD AND POOL ESTABLISHMENTS", group: "Recreativo" },
  "3743": { name: "BILTMORE HOTELS & SUITES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3620": { name: "BINION'S HORSESHOE CLUB", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5551": { name: "BOAT DEALERS", group: "Lojas Especializadas" },
  "4457": { name: "BOAT RENTALS AND LEASING, RENTALS - BOATS", group: "Aluguel" },
  "5942": { name: "BOOK STORES", group: "Lojas Especializadas" },
  "5192": { name: "BOOKS, PERIODICALS, AND NEWSPAPERS", group: "Empresa para empresa" },
  "7933": { name: "BOWLING ALLEYS", group: "Recreativo" },
  "3213": { name: "BRAATHENS REGIONAL AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3106": { name: "BRAATHENS S.A.F.E. (NORWAY)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3683": { name: "BRADBURY SUITES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3005": { name: "BRITISH AIRWAYS", group: "T&E Companhia aérea", icon: "logos/british.svg" },
  "3111": { name: "BRITISH MIDLAND", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3727": { name: "BROADMOOR HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3235": { name: "BROCKWAY AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3353": { name: "BROOKS RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3366": { name: "BUDGET RENT-A-CAR", group: "T&E Aluguel de carro", icon: "logos/budget.svg" },
  "3684": { name: "BUDGET HOST INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3685": { name: "BUDGETEL INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3712": { name: "BUFFALO BILL'S HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "4131": { name: "BUS LINES", group: "Transporte" },
  "8244": { name: "BUSINESS AND SECRETARIAL SCHOOLS", group: "Educacional" },
  "7399": { name: "BUSINESS SERVICES, NOT ELSEWHERE CLASSIFIED", group: "Empresa serviços" },
  "7278": { name: "BUYING AND SHOPPING SERVICES AND CLUBS", group: "Associação" },
  "3234": { name: "BWIA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "4899": { name: "CABLE, SATELLITE, AND OTHER PAY TELEVISION AND RADIO SERVICES", group: "TV por assinatura / Serviços de rádio" },
  "3771": { name: "CAESAR'S HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3582": { name: "CALIFORNIA HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5946": { name: "CAMERA AND PHOTOGRAPHY SUPPLY STORES", group: "Foto / Fotocópia" },
  "3672": { name: "CAMPANILE HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5561": { name: "CAMPER, RECREATIONAL, AND UTILITY TRAILER DEALERS", group: "Recreativo" },
  "3171": { name: "CANADIAN AIRLINES INTERNATIONAL", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3559": { name: "CANDLEWOOD SUITES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5441": { name: "CANDY, NUT, AND CONFECTIONERY STORES", group: "Alimentos / Bebidas", icon: "icons/food.svg" },
  "3757": { name: "CANYON RANCH", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3233": { name: "CAPITOL AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5521": { name: "CAR AND GOODS VEHICLE DEALERS (USED ONLY) SALES, SERVICE, REPAIRS, PARTS, AND LEASING", group: "Relacionados a Automotivo" },
  "5511": { name: "CAR AND TRUCK DEALERS (NEW AND USED) - SALES, SERVICE, REPAIRS, AND LEASING", group: "Automotivo" },
  "5512": { name: "CAR AND TRUCK DEALERS (USED ONLY) - SALES, SERVICE, REPAIRS, AND LEASING", group: "Relacionados a Automotivo" },
  "7542": { name: "CAR WASHES", group: "Limpeza / Lavanderia" },
  "3744": { name: "CAREFREE RESORTS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3428": { name: "CAREY RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3787": { name: "CARIBE ROYAL RESORTS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3716": { name: "CARLTON HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3086": { name: "CARNIVAL AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "1750": { name: "CARPENTRY CONTRACTORS", group: "Construção" },
  "7217": { name: "CARPET AND UPHOLSTERY CLEANING", group: "Limpeza / Lavanderia" },
  "5811": { name: "CATERERS", group: "Restaurantes / Lanchonetes", icon: "icons/food.svg" },
  "3099": { name: "CATHAY PACIFIC", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3228": { name: "CAYMAN AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3072": { name: "CEBU Pacific Airlines", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "8398": { name: "CHARITABLE AND SOCIAL SERVICE ORGANIZATIONS", group: "Associação" },
  "3763": { name: "CHATEAU ELAN WINERY AND RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5169": { name: "CHEMICALS AND ALLIED PRODUCTS, NOT ELSEWHERE CLASSIFIED", group: "Empresa para empresa" },
  "8351": { name: "CHILD CARE SERVICES", group: "Serviços pessoais" },
  "5641": { name: "CHILDREN S AND INFANT S WEAR", group: "Vestuário / Roupas" },
  "3078": { name: "CHINA AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3206": { name: "CHINA EASTERN AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "8041": { name: "CHIROPRACTORS", group: "Assistência médica e odontológica" },
  "3544": { name: "CIGA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5993": { name: "CIGAR STORES AND STANDS", group: "Lojas Especializadas" },
  "3662": { name: "CIRCUS CIRCUS HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3437": { name: "CITE RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3717": { name: "CITY LODGE HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "8641": { name: "CIVIC, SOCIAL, AND FRATERNAL ASSOCIATIONS", group: "Associação" },
  "3792": { name: "CLARIDGECASINO HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3687": { name: "CLARION HOTELS", group: "T&E Hotel", icon: "logos/clarion.svg" },
  "7349": { name: "CLEANING AND MAINTENANCE JANITORIAL SERVICES", group: "Limpeza / Lavanderia" },
  "3677": { name: "CLIMAT DE FRANCE HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5651": { name: "CLOTHING - FAMILY, FAMILY CLOTHING STORES", group: "Vestuário / Roupas" },
  "7296": { name: "CLOTHING RENTAL - COSTUMES, UNIFORMS, AND FORMAL WEAR, FORMAL WEAR", group: "Aluguel" },
  "3742": { name: "CLUB MED", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3747": { name: "CLUBCORP/CLUBRESORTS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3552": { name: "COAST HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "8220": { name: "COLLEGES, UNIVERSITIES, PROFESSIONAL SCHOOLS, AND JUNIOR COLLEGES", group: "Educacional" },
  "3736": { name: "COLORADO BELLE/EDGEWATER RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3223": { name: "COMAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3562": { name: "COMFORT INNS", group: "T&E Hotel", icon: "logos/comfort.svg" },
  "3222": { name: "COMMAND AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5046": { name: "COMMERCIAL EQUIPMENT, NOT ELSEWHERE CLASSIFIED", group: "Empresa para empresa" },
  "5139": { name: "COMMERCIAL FOOTWEAR", group: "Vestuário / Roupas" },
  "7333": { name: "COMMERCIAL PHOTOGRAPHY, ART, AND GRAPHICS", group: "Foto / Fotocópia" },
  "7941": { name: "COMMERCIAL SPORTS, PROFESSIONAL SPORTS CLUBS, ATHLETIC FIELDS, AND SPORTS PROMOTERS", group: "Entretenimento" },
  "3220": { name: "COMPANIA FAUCETT", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3688": { name: "COMPRI HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7379": { name: "COMPUTER MAINTENANCE AND REPAIR SERVICES, NOT ELSEWHERE CLASSIFIED", group: "Empresa para empresa" },
  "4816": { name: "COMPUTER NETWORK/INFORMATION SERVICES", group: "Serviços de telecomunicações" },
  "7372": { name: "COMPUTER PROGRAMMING, DATA PROCESSING, AND INTEGRATED SYSTEMS DESIGN SERVICES", group: "Serviços Profissionais" },
  "5734": { name: "COMPUTER SOFTWARE STORES", group: "Elétrico" },
  "5045": { name: "COMPUTERS, COMPUTER PERIPHERAL EQUIPMENT, SOFTWARE", group: "Empresa para empresa" },
  "3538": { name: "CONCORDE HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "1771": { name: "CONCRETE WORK CONTRACTORS", group: "Construção" },
  "3689": { name: "CONSORT HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5039": { name: "CONSTRUCTION MATERIALS, NOT ELSEWHERE CLASSIFIED", group: "Empresa para empresa" },
  "7321": { name: "CONSUMER CREDIT REPORTING AGENCIES", group: "Serviços pessoais" },
  "3061": { name: "CONTINENTAL", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "8050": { name: "CONVALESCENT HOMES, NURSING AND PERSONAL CARE FACILITIES", group: "Casa de repouso / Enfermagem" },
  "3219": { name: "COPA", group: "T&E Companhia aérea", icon: "logos/copa.svg" },
  "8241": { name: "CORRESPONDENCE SCHOOLS", group: "Educacional" },
  "5977": { name: "COSMETIC STORES", group: "Serviços pessoais" },
  "2838": { name: "COSMOPOLITAN OF LAS VEGAS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7277": { name: "COUNSELING SERVICES - DEBT, MARRIAGE, PERSONAL", group: "Serviços pessoais" },
  "4215": { name: "COURIER SERVICES - AIR OR GROUND, AND FREIGHT FORWARDERS", group: "Transporte" },
  "9211": { name: "COURT COSTS, INCLUDING ALIMONY AND CHILD SUPPORT", group: "Serviços Jurídicos" },
  "3690": { name: "COURTYARD BY MARRIOT", group: "T&E Hotel", icon: "logos/courtyard.svg" },
  "3529": { name: "CP HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3088": { name: "CROATIA AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3822": { name: "CROSSLAND", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3218": { name: "CROWN AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3750": { name: "CROWNE PLAZA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3046": { name: "CRUZEIRO DO SUL (BRAZIL)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3217": { name: "CSA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3216": { name: "CUMBERLAND AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3678": { name: "CUMULUS HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3593": { name: "CUNARD HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3292": { name: "CYPRUS AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5451": { name: "DAIRY PRODUCTS STORES", group: "Alimentos / Bebidas", icon: "icons/food.svg" },
  "3215": { name: "DAN AIR SERVICES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3629": { name: "DAN HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7911": { name: "DANCE HALLS, STUDIOS, AND SCHOOLS", group: "Recreativo" },
  "7273": { name: "DATING AND ESCORT SERVICES", group: "Serviços pessoais" },
  "3510": { name: "DAYS INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3059": { name: "DBA AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3648": { name: "DE VERE HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3058": { name: "DELTA", group: "T&E Companhia aérea", icon: "logos/delta.svg" },
  "3581": { name: "DELTA HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5047": { name: "DENTAL/LABORATORY/MEDICAL/OPHTHALMIC HOSPITAL EQUIPMENT AND SUPPLIES", group: "Empresa para empresa" },
  "8021": { name: "DENTISTS AND ORTHODONTISTS", group: "Assistência médica e odontológica" },
  "5311": { name: "DEPARTMENT STORES", group: "Departamento / Desconto" },
  "7393": { name: "DETECTIVE AGENCIES, PROTECTIVE AGENCIES, AND SECURITY SERVICES, INCLUDING ARMORED CARS AND GUARD DOGS", group: "Segurança" },
  "5815": { name: "Digital Goods—Audiovisual Media Including Books, Movies, and Music", group: "Empresa para empresa" },
  "5816": { name: "Digital Goods—Games", group: "Entretenimento" },
  "5818": { name: "Digital Goods—Multi-Category", group: "Entretenimento" },
  "5817": { name: "Digital Goods—Software Applications(Excluding Games)", group: "Entretenimento" },
  "3691": { name: "DILLION INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5964": { name: "DIRECT MARKETING - CATALOG MERCHANTS", group: "Marketing Direto" },
  "5965": { name: "DIRECT MARKETING - COMBINATION CATALOG AND RETAIL MERCHANTS", group: "Marketing Direto" },
  "5968": { name: "DIRECT MARKETING - CONTINUITY/SUBSCRIPTION MERCHANTS", group: "Marketing Direto" },
  "5967": { name: "DIRECT MARKETING - INBOUND TELEMARKETING MERCHANTS, INBOUND TELESERVICES MERCHANTS", group: "Marketing Direto" },
  "5960": { name: "DIRECT MARKETING - INSURANCE SERVICES", group: "Marketing Direto" },
  "5966": { name: "DIRECT MARKETING - OUTBOUND TELEMARKETING MERCHANTS", group: "Marketing Direto" },
  "5962": { name: "DIRECT MARKETING - TRAVEL RELATED ARRANGEMENT SERVICES", group: "Marketing Direto" },
  "5969": { name: "DIRECT MARKETING/DIRECT MARKETERS, NOT ELSEWHERE CLASSIFIED", group: "Marketing Direto" },
  "5310": { name: "DISCOUNT STORES", group: "Departamento / Desconto" },
  "3780": { name: "DISNEY RESORTS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "8011": { name: "DOCTORS AND PHYSICIANS, NOT ELSEWHERE CLASSIFIED", group: "Assistência médica e odontológica" },
  "3835": { name: "Dolce Hotels and Resorts", group: "T&E Hotel", icon: "logos/dolce.svg" },
  "3390": { name: "DOLLAR RENT-A-CAR", group: "T&E Aluguel de carro", icon: "logos/dollar.svg" },
  "3212": { name: "DOMINICANA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5963": { name: "DOOR-TO-DOOR SALES", group: "Marketing Direto" },
  "3589": { name: "DORAL GOLF RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3587": { name: "DORAL HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3623": { name: "DORINT HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3692": { name: "DOUBLETREE HOTELS (INCLUDES GUEST QUARTERS)", group: "T&E Hotel", icon: "logos/doubletree.svg" },
  "3527": { name: "DOWNTOWNWR-PASSPORT HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3004": { name: "DRAGON AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5714": { name: "DRAPERY, WINDOW COVERING, AND UPHOLSTERY STORES", group: "Móveis" },
  "5813": { name: "DRINKING PLACES (ALCOHOLIC BEVERAGES) - BARS, TAVERNS, NIGHTCLUBS, COCKTAIL LOUNGES, AND DISCOTHEQUES", group: "Restaurantes / Lanchonetes", icon: "icons/food.svg" },
  "5912": { name: "DRUG STORES AND PHARMACIES", group: "Assistência médica e odontológica" },
  "5122": { name: "DRUGS, DRUG PROPRIETARIES, AND DRUGGIST S SUNDRIES", group: "Empresa para empresa" },
  "3693": { name: "DRURY INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7216": { name: "DRY CLEANERS", group: "Limpeza / Lavanderia" },
  "3525": { name: "DUNFEY HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5099": { name: "DURABLE GOODS, NOT ELSEWHERE CLASSIFIED", group: "Empresa para empresa" },
  "5309": { name: "DUTY FREE STORES", group: "Departamento / Desconto" },
  "3245": { name: "EASYJET", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5812": { name: "EATING PLACES AND RESTAURANTS", group: "Restaurantes / Lanchonetes", icon: "icons/food.svg" },
  "3644": { name: "ECONO LODGES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3398": { name: "ECONO-CAR RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3694": { name: "ECONOMY INNS OF AMERICA", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3293": { name: "ECUATORIANA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3037": { name: "EGYPTAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3032": { name: "EL AL", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3669": { name: "ELDORADO HOTELS AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5997": { name: "ELECTRIC RAZOR STORES - SALES AND SERVICE", group: "Lojas Especializadas" },
  "5552": { name: "Electric Vehicle Charging", group: "Relacionados a Automotivo" },
  "1731": { name: "ELECTRICAL CONTRACTORS", group: "Construção" },
  "5065": { name: "ELECTRICAL PARTS AND EQUIPMENT", group: "Empresa para empresa" },
  "7622": { name: "ELECTRONIC REPAIR SHOPS", group: "Consertos em Geral" },
  "5732": { name: "ELECTRONIC SALES", group: "Elétrico" },
  "3807": { name: "ELEMENT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "8211": { name: "ELEMENTARY AND SECONDARY SCHOOLS", group: "Educacional" },
  "3652": { name: "EMBASSY HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3695": { name: "EMBASSY SUITES", group: "T&E Hotel", icon: "logos/embassy.svg" },
  "3798": { name: "EMBASSY VACATION RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3026": { name: "EMIRATES AIRLINES", group: "T&E Companhia aérea", icon: "logos/emirates.svg" },
  "7361": { name: "EMPLOYMENT AGENCIES AND TEMPORARY HELP SERVICES", group: "Serviços pessoais" },
  "3405": { name: "ENTERPRISE RENT-A-CAR", group: "T&E Aluguel de carro", icon: "logos/enterprise.svg" },
  "7394": { name: "EQUIPMENT, TOOLS, FURNITURE, AND APPLIANCE RENTAL AND LEASING", group: "Aluguel" },
  "3294": { name: "ETHIOPIAN AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3034": { name: "ETIHAD AIRWAYS", group: "T&E Companhia aérea", icon: "logos/etihad.svg" },
  "3003": { name: "EUROFLY AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3381": { name: "EUROP CAR", group: "T&E Aluguel de carro", icon: "logos/europcar.svg" },
  "3391": { name: "EUROPE BY CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3084": { name: "EVA AIRWAYS CORPORATION", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3628": { name: "EXCALIBUR HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3696": { name: "EXEL INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3621": { name: "EXTENDED STAY", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3627": { name: "EXTENDED STAY AMERICA", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3630": { name: "EXTENDED STAY DELUXE", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7342": { name: "EXTERMINATING AND DISINFECTING SERVICES", group: "Limpeza / Lavanderia" },
  "3697": { name: "FAIRFIELD HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3715": { name: "FAIRFIELD INN", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3590": { name: "FAIRMONT HOTELS", group: "T&E Hotel", icon: "logos/fairmont.svg" },
  "5814": { name: "FAST FOOD RESTAURANTS", group: "Restaurantes / Lanchonetes", icon: "icons/food.svg" },
  "6011": { name: "FINANCIAL INSTITUTIONS - AUTOMATED CASH DISBURSEMENTS", group: "Serviços financeiros" },
  "6010": { name: "FINANCIAL INSTITUTIONS - MANUAL CASH DISBURSEMENTS", group: "Serviços financeiros" },
  "6012": { name: "FINANCIAL INSTITUTIONS - MERCHANDISE AND SERVICES", group: "Serviços financeiros" },
  "9222": { name: "FINES", group: "Governo" },
  "3042": { name: "FINNAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5718": { name: "FIREPLACES, FIREPLACE SCREENS, AND FIREPLACE ACCESSORIES STORES", group: "Móveis" },
  "3784": { name: "FIRST HOSPITALITY HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3664": { name: "FLAG INN", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3711": { name: "FLAG INNS (AUSTRALIA)", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3793": { name: "FLAMINGO HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5713": { name: "FLOOR COVERING STORES", group: "Móveis" },
  "5992": { name: "FLORISTS", group: "Lojas Especializadas" },
  "5193": { name: "FLORISTS  SUPPLIES, NURSERY STOCK, AND FLOWERS", group: "Empresa para empresa" },
  "3300": { name: "AZUL AIR", group: "T&E Companhia aérea", icon: "logos/azul.svg" },
  "3302": { name: "FLYBE LTD", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3607": { name: "FONTAINEBLEAU RESORTS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3505": { name: "FORTE HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3570": { name: "FORUM HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3778": { name: "FOUR POINTS HOTELS", group: "T&E Hotel", icon: "logos/fourpoints.svg" },
  "3714": { name: "FOUR SEASONS HOTEL (AUSTRALIA)", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3543": { name: "FOUR SEASONS HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3578": { name: "FRANKENMUTH BAVARIAN", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3204": { name: "FREEDOM AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5422": { name: "FREEZER AND LOCKER MEAT PROVISIONERS, MEAT PROVISIONERS - FREEZER AND LOCKER", group: "Alimentos / Bebidas", icon: "icons/food.svg" },
  "3766": { name: "FREMONT HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3507": { name: "FRIENDSHIP INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3132": { name: "FRONTIER AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5983": { name: "FUEL DEALERS - FUEL OIL, WOOD, COAL, AND LIQUEFIED PETROLEUM", group: "Mat. Construção" },
  "7261": { name: "FUNERAL SERVICES AND CREMATORIES", group: "Serviços Profissionais" },
  "7641": { name: "FURNITURE - REUPHOLSTERY, REPAIR SHOPS", group: "Consertos em Geral" },
  "5712": { name: "FURNITURE, HOME FURNISHINGS AND EQUIPMENT STORES, EXCEPT APPLIANCES", group: "Móveis" },
  "5681": { name: "FURRIERS AND FUR SHOPS", group: "Vestuário / Roupas" },
  "3827": { name: "Galt House", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3566": { name: "GARDEN PLACE HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3103": { name: "GARUDA (INDONESIA)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3608": { name: "GAYLORD OPRYLAND", group: "T&E Hotel", icon: "logos/gaylord.svg" },
  "3609": { name: "GAYLORD PALMS", group: "T&E Hotel", icon: "logos/gaylord.svg" },
  "9702": { name: "GCAS EMERGENCY SERVICES (FOR VISA USE ONLY)", group: "Visa" },
  "1520": { name: "GENERAL CONTRACTORS - RESIDENTIAL AND COMMERCIAL", group: "Construção" },
  "3409": { name: "GENRAL RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3019": { name: "GERMANWINGS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5947": { name: "GIFT, CARD, NOVELTY, AND SOUVENIR STORES", group: "Lojas Especializadas" },
  "5231": { name: "GLASS, PAINT, AND WALLPAPER STORES", group: "Mat. Construção" },
  "5950": { name: "GLASSWARE AND CRYSTAL STORES", group: "Lojas Especializadas" },
  "3156": { name: "GO FLY LTD", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3414": { name: "GODFREY NATL RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3247": { name: "GOL AIRLINES", group: "T&E Companhia aérea", icon: "logos/gol.svg" },
  "3561": { name: "GOLDEN NUGGET", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3203": { name: "GOLDEN PACIFIC AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3506": { name: "GOLDEN TULIP HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7802": { name: "Government Licensed Horse/Dog Racing", group: "Serviços Profissionais" },
  "7800": { name: "Government Owned Lottery", group: "Recreativo" },
  "9399": { name: "GOVERNMENT SERVICES, NOT ELSEWHERE CLASSIFIED", group: "Governo" },
  "9406": { name: "Government-Owned Lotteries (Non-U.S. region)", group: "Governo" },
  "3794": { name: "GRAND CASINO HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3823": { name: "GRAND SIERRA RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3571": { name: "GRAND WAILEA RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3618": { name: "GREAT WOLF LODGES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3753": { name: "GREENBRIAR RESORTS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5411": { name: "GROCERY STORES AND SUPERMARKETS", group: "Alimentos / Bebidas", icon: "icons/food.svg" },
  "3040": { name: "GULF AIR (BAHRAIN)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3200": { name: "GUYANA AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3799": { name: "HALE KOA HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3760": { name: "HALEKULANI HOTEL/WAIKIKI PARC", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3665": { name: "HAMPTON INNS", group: "T&E Hotel", icon: "logos/hampton.svg" },
  "3062": { name: "HAPAG-LLOYD EXPRESS AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5072": { name: "HARDWARE EQUIPMENT AND SUPPLIES", group: "Empresa para empresa" },
  "5251": { name: "HARDWARE STORES", group: "Mat. Construção" },
  "3698": { name: "HARLEY HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3731": { name: "HARRAH'S HOTELS AND CASINOS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3734": { name: "HARVEY/BRISTOL RESORTS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7251": { name: "HAT CLEANING SHOPS, SHOE REPAIR SHOPS, SHOE SHINE PARLORS, AND HAT CLEANING SHOPS", group: "Serviços pessoais" },
  "3197": { name: "HAVASU AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3196": { name: "HAWAIIAN AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3836": { name: "Hawthorn by Wyndham", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7298": { name: "HEALTH AND BEAUTY SPAS", group: "Serviços pessoais" },
  "5975": { name: "HEARING AIDS - SALES, SERVICE, AND SUPPLIES", group: "Aparelhos auditivos" },
  "1711": { name: "HEATING, PLUMBING, AND AIR CONDITIONING CONTRACTORS", group: "Construção" },
  "3588": { name: "HELMSLEY HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3357": { name: "HERTZ", group: "T&E Aluguel de carro", icon: "logos/hertz.svg" },
  "3721": { name: "HILTON CONRAD", group: "T&E Hotel", icon: "logos/conrad.svg" },
  "3604": { name: "HILTON GARDEN INN", group: "T&E Hotel", icon: "logos/gardeninn.svg" },
  "3504": { name: "HILTON HOTELS", group: "T&E Hotel", icon: "logos/hilton.svg" },
  "3535": { name: "HILTON INTERNATIONALS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5945": { name: "HOBBY, TOY, AND GAME SHOPS", group: "Arte / Artesanato / Passatempo" },
  "3501": { name: "HOLIDAY INN EXPRESS", group: "T&E Hotel", icon: "logos/holiday.svg" },
  "3368": { name: "HOLIDAY RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "5200": { name: "HOME SUPPLY WAREHOUSE STORES", group: "Móveis" },
  "3800": { name: "HOMESTEAD SUITES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3751": { name: "HOMEWOOD SUITES", group: "T&E Hotel", icon: "logos/homewood.svg" },
  "3837": { name: "HOSHINO RESORTS", group: "Acomodação" },
  "3595": { name: "HOSPITALITY INTERNATIONAL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "8062": { name: "HOSPITALS", group: "Assistência médica e odontológica" },
  "3680": { name: "HOTEIS OTHAN", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3580": { name: "HOTEL DEL CORONADO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3533": { name: "HOTEL IBIS", group: "T&E Hotel", icon: "logos/ibis.svg" },
  "3813": { name: "HOTEL INDIGO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3579": { name: "HOTEL MERCURE", group: "T&E Hotel", icon: "logos/mercure.svg" },
  "3541": { name: "HOTEL OKURA", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3546": { name: "HOTEL SIERRA", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3625": { name: "HOTEL UNIVERSALE", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3663": { name: "HOTELES EL PRESIDENTE", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3548": { name: "HOTELES MELIA", group: "T&E Hotel", icon: "logos/melia.svg" },
  "3638": { name: "HOWARD JOHNSON", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3602": { name: "HUDSON HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3647": { name: "HUSA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3640": { name: "HYATT HOTELS", group: "T&E Hotel", icon: "logos/hyatt.svg" },
  "3812": { name: "HYATT PLACE", group: "T&E Hotel", icon: "logos/hyatt.svg" },
  "3102": { name: "IBERIA", group: "T&E Companhia aérea", icon: "logos/iberia.svg" },
  "3540": { name: "IBEROTEL HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3673": { name: "IBUSZ HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3050": { name: "ICELANDAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3651": { name: "IMPERIAL LONDON HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3193": { name: "INDIAN AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5085": { name: "INDUSTRIAL SUPPLIES, NOT ELSEWHERE CLASSIFIED", group: "Empresa para empresa" },
  "7375": { name: "INFORMATION RETRIEVAL SERVICES", group: "Empresa para empresa" },
  "3429": { name: "INSURANCE RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "6300": { name: "INSURANCE SALES, UNDERWRITING, AND PREMIUMS", group: "Seguro" },
  "3724": { name: "INTER NOR HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3512": { name: "INTER-CONTINENTAL HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3438": { name: "INTERENT RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3675": { name: "INTERHOTEL CEDOK", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "9700": { name: "INTERNATIONAL AUTOMATED REFERRAL SERVICE (FOR VISA USE ONLY)", group: "Visa" },
  "7801": { name: "Internet Gambling", group: "Serviços Profissionais" },
  "9950": { name: "INTRA-COMPANY PURCHASES", group: "Empresa para empresa" },
  "9405": { name: "INTRA-GOVERNMENT PURCHASES, GOVERNMENT ONLY", group: "Governo" },
  "3191": { name: "ISLAND AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3006": { name: "JAPAN AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3056": { name: "JET AIR", group: "Transporte" },
  "3174": { name: "JETBLUE AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3079": { name: "JETSTAR AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5944": { name: "JEWELRY, WATCH, CLOCK,, AND SILVERWARE STORES", group: "Lojas Especializadas" },
  "3729": { name: "JOHN ASCUAGA'S NUGGET", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3558": { name: "JOLLY HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3563": { name: "JOURNEY'S END MOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3190": { name: "JUGOSLAV AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3758": { name: "KAHALA MANDARIN ORIENTAL HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3718": { name: "KAROS HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3531": { name: "KAUAI COCONUT BEACH RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3394": { name: "KEMWELL GROUP RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3295": { name: "KENYA AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3838": { name: "KIMPTON HOTELS", group: "Acomodação" },
  "3010": { name: "KLM (ROYAL DUTCH AIRLINES)", group: "T&E Companhia aérea", icon: "logos/klm.svg" },
  "3660": { name: "KNIGHTS INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3082": { name: "KOREAN AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3038": { name: "KUWAIT AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3701": { name: "LA MANSION DEL RIO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3516": { name: "LA QUINTA MOTOR INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3576": { name: "LA QUITA RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3055": { name: "LAB (BOLIVIA)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3187": { name: "LACSA (COSTA RICA)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3568": { name: "LADBROKE HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3054": { name: "LADECO (CHILE)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5631": { name: "LADIES ACCESSORY AND SPECIALTY SHOPS", group: "Vestuário / Roupas" },
  "3624": { name: "LADY LUCK HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3052": { name: "LAN AIR", group: "T&E Companhia aérea", icon: "logos/latam.svg" },
  "780": { name: "LANDSCAPING AND HORTICULTURAL SERVICES", group: "Construção" },
  "3186": { name: "LAP (PARAGUAY)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "7211": { name: "LAUNDRY SERVICES - FAMILY AND COMMERCIAL", group: "Limpeza / Lavanderia" },
  "7210": { name: "LAUNDRY, CLEANING, AND GARMENT SERVICES", group: "Limpeza / Lavanderia" },
  "3185": { name: "LAV (VENEZUELA)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "8111": { name: "LEGAL SERVICES AND ATTORNEYS", group: "Serviços Jurídicos" },
  "3184": { name: "LIAT", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "4111": { name: "LOCAL AND SUBURBAN COMMUTER PASSENGER TRANSPORTATION, INCLUDING FERRIES", group: "Transporte" },
  "7011": { name: "LODGING - HOTELS, MOTELS, AND RESORTS", group: "T&E", icon: "icons/hotel.svg" },
  "3654": { name: "LOEWS HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3182": { name: "LOT (POLAND)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3008": { name: "LUFTHANSA", group: "T&E Companhia aérea", icon: "logos/lufthansa.svg" },
  "5948": { name: "LUGGAGE AND LEATHER GOODS STORES", group: "Lojas Especializadas" },
  "5211": { name: "LUMBER AND BUILDING MATERIALS STORES", group: "Mat. Construção" },
  "3146": { name: "LUXAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3667": { name: "LUXOR HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3808": { name: "LXR", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3767": { name: "MAIN STREET STATION HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3818": { name: "MainStay Suites", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3430": { name: "MAJOR RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3100": { name: "MALASIAN AIRLINE SYSTEM", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3181": { name: "MALEV", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "7392": { name: "MANAGEMENT CONSULTING AND PUBLIC RELATIONS SERVICES", group: "Serviços pessoais" },
  "3777": { name: "MANDALAY BAY RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3577": { name: "MANDARIN ORIENTAL HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3557": { name: "MANHATTAN EAST SUITE HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "4468": { name: "MARINAS, MARINE SERVICE, AND SUPPLIES", group: "Serviços Marina" },
  "3668": { name: "MARITIM HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5262": { name: "MarketPlace", group: "Serviços financeiros" },
  "5272": { name: "Marketplaces", group: "Empresa para empresa" },
  "3509": { name: "MARRIOT", group: "T&E Hotel", icon: "logos/marriott.svg" },
  "1740": { name: "MASONRY, STONEWORK, TILE SETTING, PLASTERING, AND INSULATION CONTRACTORS", group: "Construção" },
  "7297": { name: "MASSAGE PARLORS", group: "Serviços pessoais" },
  "6537": { name: "MasterCard MoneySend Entre Paises", group: "Empresa para empresa" },
  "6536": { name: "MasterCard MoneySend Nacional", group: "Empresa para empresa" },
  "3735": { name: "MASTERS ECONOMY INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "8071": { name: "MEDICAL AND DENTAL LABORATORIES", group: "Assistência médica e odontológica" },
  "8099": { name: "MEDICAL SERVICES AND HEALTH PRACTITIONERS, NOT ELSEWHERE CLASSIFIED", group: "Assistência médica e odontológica" },
  "7997": { name: "MEMBERSHIP CLUBS (SPORTS, RECREATION, ATHLETIC), COUNTRY CLUBS, AND PRIVATE GOLF COURSES", group: "Associação" },
  "8699": { name: "MEMBERSHIPS ORGANIZATIONS, NOT ELSEWHERE CLASSIFIED, PROFESSIONAL ORGANIZATIONS", group: "Associação" },
  "5611": { name: "MEN S AND BOYS  CLOTHING AND ACCESSORIES STORES", group: "Vestuário / Roupas" },
  "5691": { name: "MEN S AND WOMEN S CLOTHING STORES", group: "Vestuário / Roupas" },
  "5137": { name: "MEN S, WOMEN S, AND CHILDREN S UNIFORMS AND COMMERCIAL CLOTHING", group: "Vestuário / Roupas" },
  "3388": { name: "MERCHANTS RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3520": { name: "MERIDIEN HOTELS", group: "T&E Hotel", icon: "logos/lemeridien.svg" },
  "3622": { name: "MERLIN HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3178": { name: "MESA AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5051": { name: "METAL SERVICE CENTERS AND OFFICES", group: "Empresa para empresa" },
  "3087": { name: "METRO AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3176": { name: "METROFLIGHT AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3661": { name: "METROPOLE HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3023": { name: "MEXICANA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3730": { name: "MGM GRAND HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3613": { name: "MICROTEL INNS & SUITES", group: "T&E Hotel", icon: "logos/microtel.svg" },
  "3175": { name: "MIDDLE EAST AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3699": { name: "MIDWAY MOTOR LODGE", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3085": { name: "MIDWEST EXPRESS AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3572": { name: "MIKAKO HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3741": { name: "MILLENNIUM HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3439": { name: "MILLEVILLE RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3551": { name: "MIRAGE HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5999": { name: "MISCELLANEOUS AND SPECIALTY RETAIL STORES", group: "Lojas Especializadas" },
  "5699": { name: "MISCELLANEOUS APPAREL AND ACCESSORY STORES", group: "Vestuário / Roupas" },
  "5599": { name: "MISCELLANEOUS AUTOMOTIVE, AIRCRAFT, AND FARM EQUIPMENT DEALERS, NOT ELSEWHERE CLASSIFIED", group: "Relacionados a Automotivo" },
  "5499": { name: "MISCELLANEOUS FOOD STORES - CONVENIENCE STORES AND SPECIALTY MARKETS", group: "Alimentos / Bebidas", icon: "icons/food.svg" },
  "5399": { name: "MISCELLANEOUS GENERAL MERCHANDISE", group: "Departamento / Desconto" },
  "5719": { name: "MISCELLANEOUS HOME FURNISHING SPECIALTY STORES", group: "Móveis" },
  "7299": { name: "MISCELLANEOUS PERSONAL SERVICES, NOT ELSEWHERE CLASSIFIED", group: "Serviços pessoais" },
  "2741": { name: "MISCELLANEOUS PUBLISHING AND PRINTING", group: "Empresa para empresa" },
  "7699": { name: "MISCELLANEOUS REPAIR SHOPS AND RELATED SERVICES", group: "Consertos em Geral" },
  "5271": { name: "MOBILE HOME DEALERS", group: "Recreativo" },
  "3676": { name: "MONTE CARLO HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3700": { name: "MOTEL 6", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7832": { name: "MOTION PICTURE THEATERS", group: "Entretenimento" },
  "7829": { name: "MOTION PICTURES AND VIDEO TAPE PRODUCTION AND DISTRIBUTION", group: "Empresa para empresa" },
  "4214": { name: "MOTOR FREIGHT CARRIERS AND TRUCKING - LOCAL AND LONG DISTANCE, MOVING AND STORAGE COMPANIES, AND LOCAL DELIVERY", group: "Transporte" },
  "7519": { name: "MOTOR HOME AND RECREATIONAL VEHICLE RENTALS", group: "Aluguel" },
  "5592": { name: "MOTOR HOME DEALERS", group: "Recreativo" },
  "5013": { name: "MOTOR VEHICLE SUPPLIES AND NEW PARTS", group: "Empresa para empresa" },
  "5571": { name: "MOTORCYCLE SHOPS AND DEALERS", group: "Lojas Especializadas" },
  "3639": { name: "MOUNT CHARLOTTE THISTLE", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3170": { name: "MOUNT COOK", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3612": { name: "MOVENPICK HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5733": { name: "MUSIC STORES - MUSICAL INSTRUMENTS, PIANOS, AND SHEET MUSIC", group: "Lojas Especializadas" },
  "3172": { name: "NATIONAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3393": { name: "NATIONAL CAR RENTAL", group: "T&E Aluguel de carro", icon: "logos/national.svg" },
  "3772": { name: "NEMACOLIN WOODLANDS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3776": { name: "NEVELE GRAND RESORT AND COUNTRY CLUB", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3658": { name: "NEW OTANI HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3165": { name: "NEW YORK HELICOPTER", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3774": { name: "NEW YORK-NEW YORK HOTEL + CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5994": { name: "NEWS DEALERS AND NEWSTANDS", group: "Lojas Especializadas" },
  "3815": { name: "NICKELODEON SUITES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3045": { name: "NIGERIA AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3603": { name: "NOAH'S HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5199": { name: "NONDURABLE GOODS, NOT ELSEWHERE CLASSIFIED", group: "Empresa para empresa" },
  "6051": { name: "NON-FINANCIAL INSTITUTIONS - FOREIGN CURRENCY, MONEY ORDERS (NOT WIRE TRANSFER), AND TRAVELERS  CHEQUES", group: "Serviços financeiros" },
  "3164": { name: "NORONTAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3060": { name: "NORTHWEST", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3211": { name: "NORWEGIAN AIR SHUTTLE", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3642": { name: "NOVOTEL HOTELS", group: "T&E Hotel", icon: "logos/novotel.svg" },
  "5261": { name: "NURSERIES AND LAWN AND GARDEN SUPPLY STORES", group: "Mat. Construção" },
  "3657": { name: "OBEROI HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5021": { name: "OFFICE AND COMMERCIAL FURNITURE", group: "Empresa para empresa" },
  "5044": { name: "OFFICE, PHOTOGRAPHIC, PHOTOCOPY, AND MICROFILM EQUIPMENT", group: "Empresa para empresa" },
  "3786": { name: "OHANA HOTELS OF HAWAII", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3031": { name: "OLYMPIC AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3183": { name: "OMAN AVIATION SERVICES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3592": { name: "OMNI HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3732": { name: "OPRYLAND HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "8043": { name: "OPTICIANS, OPTICAL GOODS, AND EYEGLASSES", group: "Assistência médica e odontológica" },
  "8042": { name: "OPTOMETRISTS AND OPHTHALMOLOGISTS", group: "Assistência médica e odontológica" },
  "5976": { name: "ORTHOPEDIC GOODS AND PROSTHETIC DEVICES", group: "Assistência médica e odontológica" },
  "8031": { name: "OSTEOPATHS", group: "Assistência médica e odontológica" },
  "3785": { name: "OUTRIGGER HOTELS AND RESORTS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5921": { name: "PACKAGE STORES - BEER, WINE, AND LIQUOR", group: "Alimentos / Bebidas", icon: "icons/food.svg" },
  "4723": { name: "PACKAGE TOUR OPERATORS (GERMANY ONLY)", group: "Uso Apenas Regional" },
  "5198": { name: "PAINTS, VARNISHES, AND SUPPLIES", group: "Mat. Construção" },
  "3024": { name: "PAKISTAN INTERNATIONAL", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3022": { name: "PAL AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3002": { name: "PAN AMERICAN", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3599": { name: "PANNONIA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3795": { name: "PARIS LAS VEGAS HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3553": { name: "PARK INNS INTERNATIONAL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7523": { name: "PARKING LOTS AND GARAGES", group: "Relacionados a Automotivo" },
  "4112": { name: "PASSENGER RAILWAYS", group: "Transporte" },
  "3781": { name: "PATRICIA GRAND RESORTS HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5933": { name: "PAWN SHOPS", group: "Antigos / Peão Lojas" },
  "3359": { name: "PAYLESS CAR RENTAL", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3159": { name: "PBA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3752": { name: "PEABODY HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3523": { name: "PENINSULA HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3653": { name: "PENTA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3796": { name: "PEPPERMILL HOTEL CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5995": { name: "PET SHOPS, PET FOODS, AND SUPPLIES", group: "Lojas Especializadas" },
  "5172": { name: "PETROLEUM AND PETROLEUM PRODUCTS", group: "Empresa para empresa" },
  "7395": { name: "PHOTOFINISHING LABORATORIES AND PHOTO DEVELOPING", group: "Foto / Fotocópia" },
  "7221": { name: "PHOTOGRAPHIC STUDIOS", group: "Foto / Fotocópia" },
  "5131": { name: "PIECE GOODS, NOTIONS, AND OTHER DRY GOODS", group: "Empresa para empresa" },
  "3554": { name: "PINEHURST RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5074": { name: "PLUMBING AND HEATING EQUIPMENT AND SUPPLIES", group: "Empresa para empresa" },
  "8049": { name: "PODIATRISTS AND CHIROPODISTS", group: "Assistência médica e odontológica" },
  "6540": { name: "POI-Funding transactions", group: "Empresa serviços" },
  "8651": { name: "POLITICAL ORGANIZATIONS", group: "Associação" },
  "9402": { name: "POSTAL SERVICES, GOVERNMENT ONLY", group: "Governo" },
  "5094": { name: "PRECIOUS STONES AND METALS, WATCHES, AND JEWELRY", group: "Lojas Especializadas" },
  "3811": { name: "PREMIER TRAVEL INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3761": { name: "PRIMADONNA HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3526": { name: "PRINCE HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3584": { name: "PRINCESS HOTELS INTERNATIONAL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3154": { name: "PRINCEVILLE", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "8999": { name: "PROFESSIONAL SERVICES, NOT ELSEWHERE CLASSIFIED", group: "Serviços Profissionais" },
  "3719": { name: "PROTEA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7992": { name: "PUBLIC GOLF COURSES", group: "Recreativo" },
  "4225": { name: "PUBLIC WAREHOUSING - FARM PRODUCTS, REFRIGERATED GOODS, HOUSEHOLD GOODS, AND STORAGE", group: "Lojas Especializadas" },
  "3519": { name: "PULLMAN INTERNATIONAL HOTELS", group: "T&E Hotel", icon: "logos/pullman.svg" },
  "3012": { name: "QANTAS", group: "T&E Companhia aérea", icon: "logos/qantas.svg" },
  "3136": { name: "QATAR AIRWAYS", group: "T&E Companhia aérea", icon: "logos/qatar.svg" },
  "3713": { name: "QUALITY PACIFIC HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3508": { name: "QUALITY SUITES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3645": { name: "QUEENS MOAT HOUSES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7338": { name: "QUICK COPY, REPRODUCTION AND BLUEPRINTING SERVICES", group: "Foto / Fotocópia" },
  "3790": { name: "RAFFLES HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "4011": { name: "RAILROADS", group: "Transporte" },
  "3637": { name: "RAMADA INNS", group: "T&E Hotel", icon: "logos/ramada.svg" },
  "3633": { name: "RANK HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3674": { name: "RANTASIPI HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3550": { name: "REAL 8 INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "6513": { name: "REAL ESTATE AGENTS AND MANAGERS - RENTALS", group: "Aluguel" },
  "5735": { name: "RECORD STORES", group: "Entretenimento" },
  "7999": { name: "RECREATIONAL FACILITIES, NOT ELSEWHERE CLASSIFIED", group: "Recreativo" },
  "3528": { name: "RED LION HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3650": { name: "RED ROOF INN", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3649": { name: "RADISSON HOTELS", group: "T&E Hotel", icon: "logos/radisson.svg" },
  "3598": { name: "REGENT INTERNATIONAL HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3565": { name: "RELAX INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5973": { name: "RELIGIOUS GOODS STORES", group: "Lojas Especializadas" },
  "8661": { name: "RELIGIOUS ORGANIZATIONS", group: "Associação" },
  "3530": { name: "RENAISSANCE HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3262": { name: "RENO AIR INC", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3370": { name: "RENT-A-WRECK", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3431": { name: "REPLACEMENT RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3432": { name: "RESERVE RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3703": { name: "RESIDENCE INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3635": { name: "RESO HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3723": { name: "RICA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3726": { name: "RIO SUITES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3597": { name: "RIVERSIDE RESORT HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3737": { name: "RIVIERA HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3515": { name: "RODEWAY INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "1761": { name: "ROOFING, SIDING, AND SHEET METAL WORK CONTRACTORS", group: "Construção" },
  "3782": { name: "ROSEN HOTELS AND RESORTS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3048": { name: "ROYAL AIR MAROC", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3542": { name: "ROYAL HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3532": { name: "ROYAL KONA RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3521": { name: "ROYAL LAHAINA RESORTS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3704": { name: "ROYCE HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3246": { name: "RYANAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3029": { name: "SABENA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3229": { name: "SAETA SOCIAEDAD ECUATORIANOS DE TRANSPORTES AEREOS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3231": { name: "SAHSA SERVICIO AEREO DE HONDURAS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3682": { name: "SAJARA HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3564": { name: "SAM'S TOWN HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3145": { name: "SAN JUAN AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3573": { name: "SANDMAN HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3705": { name: "SANDMAN INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3775": { name: "SAND'S HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3656": { name: "SARA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3636": { name: "SAROVA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3016": { name: "SAS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3583": { name: "SAS HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3014": { name: "SAUDI ARABIAN AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3655": { name: "SCANDIC HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3143": { name: "SCENIC AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5943": { name: "SCHOOL, STATIONARY, AND OFFICE SUPPLY STORES", group: "Lojas Especializadas" },
  "8299": { name: "SCHOOLS AND EDUCATIONAL SERVICES, NOT ELSEWHERE CLASSIFIED", group: "Educacional" },
  "3725": { name: "SEA PINES RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "6211": { name: "SECURITY BROKERS AND DEALERS", group: "Serviços financeiros" },
  "5541": { name: "SERVICE STATIONS (WITH OR WITHOUT ANCILLARY SERVICES)", group: "Relacionados a Automotivo" },
  "5949": { name: "SEWING, NEEDLEWORK, FABRIC, AND PIECE GOODS STORES", group: "Arte / Artesanato / Passatempo" },
  "3545": { name: "SHANGRI-LA INTERNATIONAL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3503": { name: "SHERATON HOTELS", group: "T&E Hotel", icon: "logos/sheraton.svg" },
  "3706": { name: "SHILO INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5661": { name: "SHOE STORES", group: "Vestuário / Roupas" },
  "3707": { name: "SHONEY'S INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3386": { name: "SHOWCASE RENTAL CARS", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3679": { name: "SILVER LEGACY HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3768": { name: "SILVER STAR HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3138": { name: "SIMMONS AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3075": { name: "SINGAPORE AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3137": { name: "SINGLETON", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3355": { name: "SIXT CAR RENTAL", group: "T&E Aluguel de carro", icon: "logos/sixt.svg" },
  "3226": { name: "SKYWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3631": { name: "SLEEP INN", group: "T&E Hotel", icon: "logos/sleepinn.svg" },
  "3789": { name: "SMUGGLER'S NOTCH RESORT", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3360": { name: "SNAPPY CAR RENTAL", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "5598": { name: "SNOWMOBILE DEALERS", group: "Recreativo" },
  "3641": { name: "SOFITEL HOTELS", group: "T&E Hotel", icon: "logos/sofitel.svg" },
  "3567": { name: "SOHO GRAND HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3586": { name: "SOKOS HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3518": { name: "SOL HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3591": { name: "SONESTA HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3017": { name: "SOUTH AFRICAN AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3534": { name: "SOUTHERN PACIFIC HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3720": { name: "SOUTHERN SUN HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3066": { name: "SOUTHWEST", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3097": { name: "SPANAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "1799": { name: "SPECIAL TRADE CONTRACTORS - NOT ELSEWHERE CLASSIFIED", group: "Construção" },
  "2842": { name: "SPECIALTY CLEANING, POLISHING, AND SANITATION PREPARATIONS", group: "Empresa para empresa" },
  "3260": { name: "SPIRIT AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "7032": { name: "SPORTING AND RECREATIONAL CAMPS", group: "Recreativo" },
  "5941": { name: "SPORTING GOODS STORES", group: "Lojas Especializadas" },
  "5655": { name: "SPORTS AND RIDING APPAREL STORES", group: "Vestuário / Roupas" },
  "3770": { name: "SPRINGHILL SUITES", group: "T&E Hotel", icon: "logos/springhill.svg" },
  "3745": { name: "ST. REGIS HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3666": { name: "STAKIS HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5972": { name: "STAMP AND COIN STORES", group: "Representantes e Galerias de Arte" },
  "5111": { name: "STATIONARY, OFFICE SUPPLIES, AND PRINTING AND WRITING PAPER", group: "Empresa para empresa" },
  "3791": { name: "STAYBRIDGE SUITES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "4411": { name: "STEAMSHIPS LINES AND CRUISE LINES", group: "T&E" },
  "3643": { name: "STEIGENBERGER HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7339": { name: "STENOGRAPHIC AND SECRETARIAL SUPPORT SERVICES", group: "Serviços Profissionais" },
  "3769": { name: "STRATOSPHERE HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3626": { name: "STUDIO PLUS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3135": { name: "SUDAN AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3539": { name: "SUMMERFIELDS SUITES HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3069": { name: "Sun Country Airlines—SUNCNTRY", group: "T&E", icon: "icons/airline.svg" },
  "3130": { name: "SUN WORLD INTERNATIONAL", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3133": { name: "SUNBELT AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3709": { name: "SUPER 8 MOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3129": { name: "SURINAM AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3686": { name: "SUSSE CHALET", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3646": { name: "SWALLOW HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5996": { name: "SWIMMING POOLS - SALES, SUPPLIES, AND SERVICE", group: "Lojas Especializadas" },
  "3015": { name: "SWISS INTERNATIONAL AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3634": { name: "SWISSOTEL", group: "T&E Hotel", icon: "logos/swissotel.svg" },
  "3127": { name: "TACA INTERNATIONAL", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5697": { name: "TAILORS AND SEAMSTRESSES, MENDING AND ALTERATIONS", group: "Consertos em Geral" },
  "3659": { name: "TAJ HOTELS INTERNATIONAL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3126": { name: "TALAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3248": { name: "TAM AIRLINES", group: "T&E Companhia aérea", icon: "logos/latam.svg" },
  "3125": { name: "TAN", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3035": { name: "TAP (PORTUGAL)", group: "T&E Companhia aérea", icon: "logos/tap.svg" },
  "3297": { name: "TAROM ROMANIAN AIR TRANSPORT", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "9311": { name: "TAX PAYMENTS", group: "Governo" },
  "7276": { name: "TAX PREPARATION SERVICES", group: "Serviços pessoais" },
  "4121": { name: "TAXICABS AND LIMOUSINES", group: "Transporte" },
  "4812": { name: "TELECOMMUNICATIONS EQUIPMENT, AND TELEPHONE SALES", group: "Serviços de telecomunicações" },
  "4814": { name: "TELECOMMUNICATIONS SERVICES, INCLUDING LOCAL AND LONG DISTANCE CALLS, CREDIT CALL CALLS, CALLS THROUGH USE OF MAGNETIC-STRIPE READING TELEPHONES, AND FAX SERVICES", group: "Serviços de telecomunicações" },
  "4821": { name: "TELEGRAPH SERVICES", group: "Serviços de telecomunicações" },
  "5998": { name: "TENT AND AWNING SHOPS", group: "Recreativo" },
  "8734": { name: "TESTING LABORATORIES (NON-MEDICAL)", group: "Empresa para empresa" },
  "3077": { name: "THAI AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3749": { name: "THE BEVERLY HILLS HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3746": { name: "THE ELIOT HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3755": { name: "THE HOMESTEAD", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3759": { name: "THE ORCHID AT MAUNA LANI", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3802": { name: "THE PALACE HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3632": { name: "THE PHOENCIAN", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3702": { name: "THE REGISTRY HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3710": { name: "THE RITZ-CARLTON", group: "T&E Hotel", icon: "logos/ritz.svg" },
  "3814": { name: "THE ROOSEVELT HOTEL NY", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3773": { name: "THE VENETIAN RESORT HOTEL CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7922": { name: "THEATRICAL PRODUCERS (EXCEPT MOTION PICTURES), AND TICKET AGENCIES", group: "Entretenimento" },
  "3395": { name: "THRIFTY CAR RENTAL", group: "T&E Aluguel de carro", icon: "logos/thrifty.svg" },
  "3396": { name: "TILDEN RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "7012": { name: "TIMESHARES", group: "Acomodação" },
  "7534": { name: "TIRE RETREADING AND REPAIR SHOPS", group: "Automotivo" },
  "3522": { name: "TOKYO HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "4784": { name: "TOLL AND BRIDGE FEES", group: "Transporte" },
  "7991": { name: "TOURIST ATTRACTIONS AND EXHIBITS", group: "Recreativo" },
  "3115": { name: "TOWER AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "7549": { name: "TOWING SERVICES", group: "Relacionados a Automotivo" },
  "3783": { name: "TOWN AND COUNTRY RESORT AND CONVENTION CENTER", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3740": { name: "TOWNEPLACE SUITES", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "8249": { name: "TRADE AND VOCATIONAL SCHOOLS", group: "Educacional" },
  "7033": { name: "TRAILER PARKS AND CAMPGROUNDS", group: "Recreativo" },
  "3089": { name: "TRANSAERO", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "4789": { name: "TRANSPORTATION SERVICES, NOT ELSEWHERE CLASSIFIED", group: "Transporte" },
  "3221": { name: "TRANSPORTES AEROS MILITARES ECUATORIANOS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "4722": { name: "TRAVEL AGENCIES AND TOUR OPERATORS", group: "Transporte" },
  "3615": { name: "TRAVELODGE", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3555": { name: "TREASURE ISLAND HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3380": { name: "TRIANGLE RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3569": { name: "TRIBECA GRAND HOTEL", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3385": { name: "TROPICAL RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3738": { name: "TROPICANA RESORT & CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7513": { name: "TRUCK AND UTILITY TRAILER RENTAL", group: "Aluguel" },
  "3049": { name: "TUNIS AIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3047": { name: "TURKISH AIRLINES", group: "T&E Companhia aérea", icon: "logos/turkish.svg" },
  "2791": { name: "TYPESETTING, PLATEMAKING, AND RELATED SERVICES", group: "Empresa para empresa" },
  "5978": { name: "TYPEWRITER STORES - SALES, SERVICE, AND RENTALS", group: "Lojas Especializadas" },
  "3254": { name: "U.S. AIR SHUTTLE", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3063": { name: "U.S. AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3433": { name: "UGLY DUCKLING RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "9752": { name: "UK PETROL STATIONS - ELECTRONIC HOT FILE (FOR REGIONAL USE ONLY)", group: "Uso Apenas Regional" },
  "9751": { name: "UK SUPERMARKETS - ELECTRONIC HOT FILE (FOR REGIONAL USE ONLY)", group: "Uso Apenas Regional" },
  "3090": { name: "UNIAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3000": { name: "UNITED AIRLINES", group: "T&E Companhia aérea", icon: "logos/united.svg" },
  "3434": { name: "USA RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "5931": { name: "USED MERCHANDISE AND SECONDHAND STORES", group: "Antigos / Peão Lojas" },
  "3027": { name: "UTA/INTERAIR", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "4900": { name: "UTILITIES - ELECTRIC, GAS, WATER, AND SANITARY", group: "Lojas Especializadas" },
  "3575": { name: "VAGABOND HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3118": { name: "VALLEY AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3435": { name: "VALUE RENT-A-CAR", group: "T&E Aluguel de carro", icon: "icons/rental.svg" },
  "3067": { name: "VANGUARD AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5331": { name: "VARIETY STORES", group: "Departamento / Desconto" },
  "3018": { name: "VARIG (BRAZIL)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3036": { name: "VASP (BRAZIL)", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3825": { name: "VDARA", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3574": { name: "VENTURE INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "742": { name: "VETERINARY SERVICES", group: "Serviços Profissionais" },
  "3117": { name: "VIASA", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "7993": { name: "VIDEO AMUSEMENT GAME SUPPLIES", group: "Recreativo" },
  "7994": { name: "VIDEO GAME ARCADES AND ESTABLISHMENTS", group: "Recreativo" },
  "7841": { name: "VIDEO TAPE RENTALS STORES", group: "Aluguel" },
  "3057": { name: "Virgin America", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3144": { name: "VIRGIN ATLANTIC", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3188": { name: "VIRGIN EXPRESS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3708": { name: "VIRGIN RIVER HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "9701": { name: "VISA CREDENTIAL SERVER (FOR VISA USE ONLY)", group: "Visa" },
  "3131": { name: "VLM AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3779": { name: "W HOTELS", group: "T&E Hotel", icon: "logos/whotels.svg" },
  "7631": { name: "WATCH, CLOCK, AND JEWELRY REPAIR SHOPS", group: "Consertos em Geral" },
  "3524": { name: "WELCOMGROUP HOTELS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "7692": { name: "WELDING SERVICES", group: "Consertos em Geral" },
  "3748": { name: "WELLESLEY INNS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3513": { name: "WESTIN HOTELS", group: "T&E Hotel", icon: "logos/westin.svg" },
  "3180": { name: "WESTJET AIRLINES", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3762": { name: "WHISKEY PETE'S HOTEL AND CASINO", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5300": { name: "WHOLESALE CLUBS", group: "Associação" },
  "3299": { name: "WIDEROE'S FLYVESELSKAP", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5698": { name: "WIG AND TOUPEE STORES", group: "Vestuário / Roupas" },
  "3112": { name: "WINDWARD ISLAND", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "3110": { name: "WINGS AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "4829": { name: "WIRE TRANSFER MONEY ORDERS", group: "Serviços financeiros" },
  "3301": { name: "Wizz Air", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
  "5621": { name: "WOMEN S READY-TO-WEAR STORES", group: "Vestuário / Roupas" },
  "3739": { name: "WOODSIDE HOTELS & RESORTS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "5935": { name: "WRECKING AND SALVAGE YARDS", group: "Automotivo" },
  "3722": { name: "WYNDHAM", group: "T&E Hotel", icon: "logos/wyndham.svg" },
  "3596": { name: "WYNN LAS VEGAS", group: "T&E Hotel", icon: "icons/hotel.svg" },
  "3094": { name: "ZAMBIA AIRWAYS", group: "T&E Companhia aérea", icon: "icons/airline.svg" },
}

exports.merchantCodes = merchantCodes;

const responseCodes = {
  0: { name: "Aprovado", approved: true },
  1: { name: "Refer to Issuer", approved: false },
  2: { name: "Refer to Issuer (Special Condition)", approved: false },
  3: { name: "Invalid Merchant", approved: false },
  4: { name: "Pickup Card", approved: false },
  5: { name: "Declined", approved: false },
  6: { name: "Error", approved: false },
  7: { name: "Pickup Card Special Condition", approved: false },
  8: { name: "Approved Honour with ID", approved: true },
  9: { name: "Request in Progress", approved: false },
  10: { name: "Partially approved", approved: true },
  11: { name: "Approved VIP Approval", approved: false },
  12: { name: "Invalid transaction", approved: false },
  13: { name: "Invalid Amount", approved: false },
  14: { name: "Invalid account number", approved: false },
  15: { name: "Unknown issuer", approved: false },
  16: { name: "Approved Update Track 3", approved: false },
  17: { name: "Customer Cancellation", approved: false },
  18: { name: "Customer Dispute", approved: false },
  19: { name: "Re-enter Transaction", approved: false },
  20: { name: "Invalid Response", approved: false },
  21: { name: "No Action Taken", approved: false },
  22: { name: "Suspected Malfunction", approved: false },
  23: { name: "Unacceptable Transaction Fee", approved: false },
  24: { name: "File Update Unsupported", approved: false },
  25: { name: "Account Number Missing", approved: false },
  26: { name: "Duplicate File Update Replaced", approved: false },
  27: { name: "File Update Edit Error", approved: false },
  28: { name: "File Temporarily Unavailable", approved: false },
  29: { name: "File Update Failed", approved: false },
  30: { name: "Format error", approved: false },
  31: { name: "Bank Unsupported", approved: false },
  32: { name: "Completed Partially", approved: false },
  33: { name: "Expired Card Pickup", approved: false },
  34: { name: "Suspected Fraud Pickup", approved: false },
  35: { name: "Contact Acquirer Pickup", approved: false },
  36: { name: "Restricted Card Pickup", approved: false },
  37: { name: "Call Acquirer Security Pickup", approved: false },
  38: { name: "PIN Retries Exceeded Capture", approved: false },
  39: { name: "No Credit Account", approved: false },
  40: { name: "Function Unsupported", approved: false },
  41: { name: "Lost card", approved: false },
  42: { name: "No Unsupported Account", approved: false },
  43: { name: "Stolen card", approved: false },
  44: { name: "No Investment Account", approved: false },
  51: { name: "Insufficient funds", approved: false },
  52: { name: "No account of type requested", approved: false },
  53: { name: "No Savings Account", approved: false },
  54: { name: "Expired card", approved: false },
  55: { name: "Incorrect PIN", approved: false },
  56: { name: "Card Record Not Found", approved: false },
  57: { name: "Transaction not permitted to cardholder", approved: false },
  58: { name: "Transaction not permitted at terminal", approved: false },
  59: { name: "Suspected Fraud", approved: false },
  60: { name: "Contact Acquirer", approved: false },
  61: { name: "Limit Exceeded", approved: false },
  62: { name: "Restricted card", approved: false },
  63: { name: "Security Violation", approved: false },
  64: { name: "Original Amount Incorrect", approved: false },
  65: { name: "Frequency Exceeded", approved: false },
  66: { name: "Call Acquirer", approved: false },
  67: { name: "ATM Card Capture", approved: false },
  68: { name: "Response Too Late", approved: false },
  70: { name: "Contact Card Issuer", approved: false },
  75: { name: "PIN tries exceeded", approved: false },
  76: { name: "Unable to locate previous message", approved: false },
  77: { name: "Previous message inconsistent", approved: false },
  78: { name: "Card not unblocked", approved: false },
  79: { name: "Key ex. validation failed", approved: false },
  80: { name: "Credit Issuer Unavailable", approved: false },
  81: { name: "PIN Crypto Error", approved: false },
  82: { name: "CVV Check Failed", approved: false },
  83: { name: "PIN Not Verified", approved: false },
  85: { name: "Data Check Approved", approved: true },
  86: { name: "PIN Validation Not Possible", approved: false },
  87: { name: "Approved Purchase Only (No Cashback)", approved: true },
  88: { name: "Cryptography Error", approved: false },
  90: { name: "Cutoff in progress", approved: false },
  91: { name: "Issuer not available", approved: false },
  92: { name: "No Route Available", approved: false },
  93: { name: "Txn Cannot Complete - Violation of Law", approved: false },
  94: { name: "Duplicate Txn", approved: false },
  95: { name: "Reconciliation Error", approved: false },
  96: { name: "System Error", approved: false },
  97: { name: "Invalid MAC", approved: false },
  98: { name: "Issuer Timed Out", approved: false },
  100: { name: "Inline Device Failure", approved: false },
  101: { name: "Invalid PIN Block", approved: false },
  104: { name: "Configuration Error", approved: false },
  105: { name: "Transaction Content Invalid", approved: false },
  106: { name: "Undefined Transaction Purpose", approved: false },
  107: { name: "Undefined Transaction Purpose", approved: false },
  108: { name: "Invalid Key Version", approved: false },
  109: { name: "Invalid Key Generation", approved: false },
  110: { name: "Database Error", approved: false },
  111: { name: "Invalid Track 1", approved: false },
  112: { name: "Invalid Track 2", approved: false },
  113: { name: "Invalid Track 3", approved: false },
  114: { name: "Invalid Terminal", approved: false },
  115: { name: "Transaction Already Matched", approved: false },
  116: { name: "No Account Hierarchy", approved: false },
  117: { name: "Cannot Convert Currency", approved: false },
  118: { name: "Card Track Database Mismatch", approved: false },
  119: { name: "Resource Busy", approved: false },
  120: { name: "At PIN Retry Limit", approved: false },
  121: { name: "Product Restriction", approved: false },
  122: { name: "PIN Data Missing", approved: false },
  123: { name: "Security hsware error", approved: false },
  124: { name: "International Restriction", approved: false },
  125: { name: "Blocked Card", approved: false },
  126: { name: "Approved account by Issuer", approved: false },
  127: { name: "Partial approved account by Issuer", approved: false },
  128: { name: "Approved Update ICC", approved: false },
  129: { name: "Invalid Time", approved: false },
  130: { name: "Invalid Sequence Number", approved: false },
  131: { name: "PIN Not Active", approved: false },
  132: { name: "ATM Exception Without Reversal", approved: false },
  133: { name: "Service Unavailable", approved: false },
  134: { name: "Cashback Limit Exceeded", approved: false },
  135: { name: "Invalid CVV2", approved: false },
  136: { name: "Invalid Billing Info", approved: false },
  137: { name: "PIN Change Declined", approved: false },
  138: { name: "Inline Device Failure", approved: false },
  139: { name: "Forward to Issuer", approved: false },
  140: { name: "Cannot Authenticate Card", approved: false },
  141: { name: "Incorrect Routing", approved: false },
  142: { name: "PIN Length Error", approved: false },
  143: { name: "PIN Key Sync Error", approved: false },
  144: { name: "Redemption Denied Loyalty", approved: false },
  145: { name: "Blocked Account", approved: false },
  146: { name: "RFID Transponder Blocked", approved: false },
  147: { name: "RFID Transponder Unknown", approved: false },
  148: { name: "RFID Illegal Response", approved: false },
  149: { name: "Reconciliation Error No Reattempts", approved: false },
  150: { name: "Issuer Inoperative", approved: false },
  151: { name: "Issuer Malfunction", approved: false },
  152: { name: "MAC Key Sync Error", approved: false },
  153: { name: "Crypto Decline No Capture", approved: false },
  154: { name: "Product Ceiling Exceeded", approved: false },
  155: { name: "Safeframe Error", approved: false },
  156: { name: "Unable to Store", approved: false },
  157: { name: "Invalid 'To' Account", approved: false },
  158: { name: "Invalid 'From' Account", approved: false },
  159: { name: "Invalid Account General", approved: false },
  160: { name: "Domestic Debit Transaction Not Allowed", approved: false },
  161: { name: "Invalid Auth Lifecycle", approved: false },
  162: { name: "Valid for Zero Amount Transactions", approved: false },
  163: { name: "CVV Validation Unavailable", approved: false },
  164: { name: "Invalid Surcharge", approved: false },
  165: { name: "Cannot authorise force STIP", approved: false },
  166: { name: "Cash service not available", approved: false },
  167: { name: "Unsafe PIN", approved: false },
  168: { name: "Stop Payment Order", approved: false },
  169: { name: "Revocation of authorisation", approved: false },
  170: { name: "Revocation of all authorisations", approved: false },
  171: { name: "Invalid CVV3", approved: false },
  172: { name: "Invalid ATC", approved: false },
  173: { name: "Invalid ARQC", approved: false },
  174: { name: "CVV generation Failed", approved: false },
  175: { name: "Invalid CVV", approved: false },
  176: { name: "Invalid PIN Format", approved: false },
  177: { name: "Product price out of range", approved: false },
  178: { name: "Inconsistent PIN fields", approved: false },
  179: { name: "PIN translate failed", approved: false },
  180: { name: "PRV generation failed", approved: false },
  181: { name: "HSM unavailable", approved: false },
  182: { name: "Fraud decline", approved: false },
  183: { name: "Fraud referral", approved: false },
  184: { name: "Fraud blocked 24h", approved: false },
  185: { name: "Invalid product", approved: false },
  186: { name: "Approved amount changed", approved: false },
  187: { name: "Fraud blocked", approved: false },
  188: { name: "Invalid currency", approved: false },
  189: { name: "Invalid luhn", approved: false },
  190: { name: "Card not found - expiry date mismatch", approved: false },
  191: { name: "Card not found - track expiry mismatch", approved: false },
  192: { name: "Reversal expiry mismatch", approved: false },
  193: { name: "Parked transaction timed out", approved: false },
  194: { name: "Invalid fallback", approved: false },
  195: { name: "Contactless not allowed", approved: false },
  196: { name: "Original preauth not found", approved: false },
  197: { name: "Original preauth balance not found", approved: false },
  198: { name: "Original preauth expired", approved: false },
  199: { name: "EMV offline PIN data mismatch", approved: false },
  200: { name: "Hidden instrument type", approved: false },
  201: { name: "Key not available", approved: false },
  202: { name: "TVR CVM Failed", approved: false },
  203: { name: "TVR CVM Unknown", approved: false },
  204: { name: "TVR cannot provide PIN", approved: false },
  205: { name: "TVR PIN required but not given", approved: false },
  206: { name: "Token wallet profile configuration error", approved: false },
  207: { name: "Token to PAN failure", approved: false },
  219: { name: "Card digitization invalid", approved: false },
  220: { name: "Mobile not allowed", approved: false },
  221: { name: "Token invalid reference ID", approved: false },
  222: { name: "Token invalid res method", approved: false },
  223: { name: "Token invalid channel", approved: false },
  224: { name: "Token data unavailable", approved: false },
  225: { name: "Invalid TC", approved: false },
  226: { name: "Invalid AAC", approved: false },
  229: { name: "Unknown CID AC type", approved: false },
  230: { name: "AC type mismatch", approved: false },
  231: { name: "Exceeded ECommerce limit", approved: false },
  232: { name: "Unable to build keyholder", approved: false },
  233: { name: "Token expired", approved: false },
  234: { name: "Token device score", approved: false },
  235: { name: "Token account score", approved: false },
  236: { name: "Original preauth reversed", approved: false },
  237: { name: "Delivery blocked", approved: false },
  238: { name: "Token invalid value", approved: false },
  239: { name: "Token data conflict", approved: false },
  240: { name: "Invalid original credit", approved: false },
  241: { name: "Invalid account funding", approved: false },
  243: { name: "Fraud full block 24h", approved: false },
  244: { name: "Fraud full block", approved: false },
  245: { name: "Invalid service code", approved: false },
  246: { name: "No service code present", approved: false },
  247: { name: "Duplicate ATC", approved: false },
  248: { name: "ATC exceeds lower", approved: false },
  249: { name: "ATC exceeds higher", approved: false },
  250: { name: "Not signed on", approved: false },
  251: { name: "Incorrect CVV length", approved: false },
  252: { name: "Approved unspecified", approved: false },
  253: { name: "Invalid PIN block content", approved: false },
  254: { name: "Cash Limit Exceeded", approved: false },
  255: { name: "Wrongly Keyed Expiry", approved: false },
  256: { name: "PIN Change Not Allowed", approved: false },
  257: { name: "Peripheral Down Req. STIP", approved: false },
  258: { name: "Invalid Token State", approved: false },
  259: { name: "Customer not found", approved: false },
  260: { name: "Invalid MCC", approved: false },
  261: { name: "Unsupported Issuer Service", approved: false },
  262: { name: "Sanction OI Merchant Block", approved: false },
  263: { name: "Sanction OI Country Block", approved: false },
  264: { name: "Sanction Score Threshold Exceeded", approved: false },
  265: { name: "PIN Data Required", approved: false },
  266: { name: "OCT Daily Activity Limit Exceeded", approved: false },
  267: { name: "OCT Weekly Activity Limit Exceeded", approved: false },
  268: { name: "OCT 30 Day Activity Limit Exceeded", approved: false },
  269: { name: "No Matching Balance Rule", approved: false },
  270: { name: "Inactive Card", approved: false },
  271: { name: "Inactive Account", approved: false },
  272: { name: "Delinquent (30 Days)", approved: false },
  273: { name: "Delinquent (90 Days)", approved: false },
  274: { name: "Blocked Customer", approved: false },
  275: { name: "Inactive Customer", approved: false },
  276: { name: "Closed Customer", approved: false },
  277: { name: "Invalid AAV", approved: false },
  278: { name: "Service Timed Out", approved: false },
  286: { name: "Activity Status Decline", approved: false },
  290: { name: "Origin (Mastercard) not available", approved: false },
  297: { name: "Original authorization is not a pre auth", approved: false },
  294: { name: "Original pre-authorization already cleared", approved: false },
  296: { name: "Original authorization already cleared", approved: false },
  298: { name: "Installment profile configuration missing. When a installment transaction happens, but the feature is disable on the product", approved: false },
  380: { name: "Approved: At an unspecified time and date within the PSD Guidelines.", approved: true },
  381: { name: "Approved: On the same day", approved: true },
  382: { name: "Approved: On the next calendar day", approved: true },
  383: { name: "Approved: On the next Working Day", approved: true },
  384: { name: "Approved: After the next Working Day within the PSD Guidelines", approved: true },
  400: { name: "Approved Offline", approved: true },
  9553: { name: "Mastercard declined", approved: false },
}

exports.responseCodes = responseCodes;

exports.isAccountActive = (account) => {
  return account.status === accountsStatus.ACTIVE;
}
