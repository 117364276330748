import React from 'react';
import IntlMessages from '../../helpers/IntlMessages';

import { stringToCard } from '../../utils/cardDisplay';

export default function CardDisplay ({ card, loading }) {
  return (
    <div className="creditcard-display">
      <div className="creditcard-logo" />
      {
        loading === true
        ?
          <div className="loading" />
        :
          <div>
            <div className="creditcard-number">{stringToCard(card.number)}</div>
            <div className="creditcard-details">
              <div className="creditcard-exp">
                <IntlMessages id="card.valid-untill" />
                {card.expiration}
              </div>
              <div className="creditcard-cvv">
                <IntlMessages id="card.cvv" />
                {card.cvv || "***"}
              </div>
            </div>
          </div>
      }
    </div>
  )
}