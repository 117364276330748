import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  Alert,
  Button,
  Label,
  Input,
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import NumberFormat from "react-number-format";
import * as Yup from "yup";

import IntlMessages from "../../helpers/IntlMessages";
import { injectIntl } from "react-intl";

import { connect } from "react-redux";

import appFunctions from "../../services/Functions";

class UsersModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      loading: true,
      users: [],
    };
  }

  componentDidMount() {
    this.loadUsers();
  }

  // Get User Info
  loadUsers = async () => {
    const { messages } = this.props.intl;

    try {
      const users = await appFunctions.listUsers();

      this.setState({
        loading: false,
        users: users.data.sort((a, b) => a.name.localeCompare(b.name)),
      });
    } catch (err) {
      this.setState({
        error: `${messages["user.GeneralException"]} ${err.message}`,
      });
    }
  };

  // Create User
  onCreateUser = async ({ name, email, cpf }, setSubmitting, resetForm) => {
    const { messages } = this.props.intl;

    try {
      await appFunctions.createUser({ name, email, cpf });

      this.loadUsers();
      setSubmitting(false);
      resetForm();
    } catch (err) {
      if (err.response?.data?.message) {
        this.setState({
          error: `${err.response?.data?.message}: ${err.response?.data?.params}`,
        });
      } else {
        this.setState({
          error: `${messages["user.GeneralException"]}`,
        });
      }
    }
  };

  // Delete User
  onDeleteUser = async (userId) => {
    const { messages } = this.props.intl;

    try {
      await appFunctions.deleteUser(userId);

      this.loadUsers();
    } catch (err) {
      this.setState({
        error: `${messages["user.GeneralException"]}`,
      });
    }
  };

  // Atualiza as permissões do usuário
  onUpdateUserPermissions = async (userId, permissions) => {
    const { messages } = this.props.intl;

    try {
      await appFunctions.updateUserPermissions(userId, permissions);

      this.loadUsers();
    } catch (err) {
      this.setState({
        error: `${messages["user.GeneralException"]}`,
      });
    }
  };

  // Render
  render() {
    const { messages } = this.props.intl;
    const { close, user } = this.props;
    const { users, error, loading } = this.state;
    const { isCpfValid } = require("../../utils/document");

    return (
      <Modal isOpen={true} size="lg" toggle={close}>
        <ModalHeader toggle={close} />
        <ModalBody>
          <h3>
            <IntlMessages id="users.list" />
          </h3>

          {error && (
            <Alert color="danger" className="mt-2 mb-2">
              {error}
            </Alert>
          )}

          {loading && <div className="loading" />}

          {users.map((userItem) => {
            return (
              <Card key={`user-${userItem.uid}`} className="mb-3 p-3">
                <div className="d-flex flex-grow-1 min-width-zero">
                  <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center p-0">
                    <p className="list-item-heading w-30 truncate p-0 m-0">
                      {userItem.name}
                    </p>
                    <p className="text-muted text-small w-40 w-sm-100 p-0 m-0">
                      {userItem.email}
                    </p>
                    <p className="text-muted text-small w-15 w-sm-100 p-0 m-0">
                      {userItem.cpf}
                    </p>
                    {user.uid !== userItem.uid && (
                      <div className="w-5 w-sm-100">
                        <Button
                          onClick={() => {
                            this.onDeleteUser(userItem.uid);
                          }}
                          color="danger"
                          size="xs"
                        >
                          Revogar
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {
                  user.admin ? (
                  <Label className="d-flex align-items-center mt-1">
                    <Input type="checkbox" className="position-relative m-0" onChange={e => this.onUpdateUserPermissions(userItem.uid, { addBalance: e.target.checked })} />
                    <span className="ml-1 text-muted text-smaller">Pode adicionar saldo em cartões</span>
                  </Label>
                  ) : null
                }
              </Card>
            );
          })}
        </ModalBody>
        <ModalBody>
          <Formik
            initialValues={{ name: "", email: "", cpf: "" }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(
                messages["forms.validation.name.required"]
              ),
              email: Yup.string()
                .email(messages["forms.validation.email.valid"])
                .required(messages["forms.validation.email.required"]),
              cpf: Yup.string()
                .required(messages["forms.validation.cpf.required"])
                .test(
                  "valid-cpf",
                  messages["forms.validation.cpf.valid"],
                  isCpfValid
                ),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              this.onCreateUser(values, setSubmitting, resetForm);
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <h3>
                  <IntlMessages id="users.create" />
                </h3>

                <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                  <div className="mb-1 mr-1 w-40">
                    <Label className="form-group has-top-label mb-4">
                      <Field type="text" name="name" className="form-control" />
                      <IntlMessages id="user.name" />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                  </div>
                  <div className="mb-1 mr-1 w-40">
                    <Label className="form-group has-top-label mb-4">
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                      />
                      <IntlMessages id="user.email" />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                  </div>
                  <div className="mb-1 w-20">
                    <Label className="form-group has-top-label mb-4">
                      <NumberFormat
                        className="form-control"
                        defaultValue={values.cpf}
                        format="###.###.###-##"
                        onValueChange={(v) =>
                          setFieldValue("cpf", v.formattedValue || "")
                        }
                      />
                      <IntlMessages id="user.cpf" />
                      <ErrorMessage
                        name="cpf"
                        component="div"
                        className="invalid-feedback d-block"
                      />
                    </Label>
                  </div>
                </div>

                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    type="submit"
                    color="primary"
                    outline
                    size="lg"
                    disabled={isSubmitting}
                  >
                    <IntlMessages id="forms.action.create" />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { user } = auth;
  const { environment } = settings;
  return { user, environment };
};

export default connect(mapStateToProps)(injectIntl(UsersModal));
