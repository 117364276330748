import React, { Component } from 'react';
import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { BrowserRouter as Redirect } from 'react-router-dom';

import User from '../../services/User';

class UserDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };
  }

  // Logout
  handleLogout = () => {
    User.logOut();
    this.setState({
      redirect: '/',
    });
  };

  render() {
    const { redirect } = this.state;
    const { user, organization } = this.props;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <UncontrolledDropdown className="dropdown-menu-right">
        <DropdownToggle className="header-icon userButton" color="empty">
          <i className="simple-icon-user" />
        </DropdownToggle>
        <DropdownMenu className="mt-3" right>
          <DropdownItem>
            <p className="mb-1">{user?.name}</p>
            <p className="mb-1 text-muted text-small">
              {organization?.organization?.trading_name}
            </p>
            <p className="mb-1 text-muted text-small">
              {organization?.organization?.document}
            </p>
          </DropdownItem>
          {/* <DropdownItem>Features</DropdownItem>
                    <DropdownItem>History</DropdownItem>
                    <DropdownItem>Support</DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem onClick={() => this.handleLogout()}>
            Logout
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user, organization } = auth;
  return { user, organization };
};

export default connect(mapStateToProps)(injectIntl(UserDropdown));
